import React from 'react';
import firebaseApp from '../../constants/firebase';
import { getFirestore } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getStorage } from "firebase/storage";

import { IAction } from "../../types";

interface IState {
  user: object | undefined
  errors: object
}

type Props = {
  children: React.ReactNode
};

const initialState: IState = {
  user: undefined,
  errors: {}
}

// Context
export const AuthContext = React.createContext(initialState);

// State
const AuthState: React.FC<Props> = (props) => { 
  const [state, dispatch] = React.useReducer(Reducer, initialState);

  React.useEffect(() => {
    if (!initialState.user) {
      const auth = getAuth(firebaseApp);
      signInAnonymously(auth)
        .then(user => {
          // onAuthStateChanged will sign in an anonymous user
          dispatch({
              type: "SET_USER",
              payload: user.user
            });
        })
        .catch((err: any) => dispatch({ type: "SET_ERRORS", payload: err }));
    }
  }, []);

  return <AuthContext.Provider value={{ ...state}}>
    {props.children}
  </AuthContext.Provider>
;

}

// Reducer
const Reducer = (state: IState, action: IAction):IState => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload
      }
    case "SET_ERRORS":
      return {
        ...state,
        errors: action.payload
      }
    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: {}
      }
    default:
      return state;
  }
}

export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);

export default AuthState;