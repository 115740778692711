import React, {Suspense} from 'react';
import { Routes, Route } from "react-router-dom";
// eslint-disable-next-line
import GA_Tracking from './utils/gtag';
import ClarityTracking from './utils/ClarityTracking';
import ZoomInfoTracking from './utils/ZoomInfoTracking';

// States
import AuthState from "./context/AuthState";
import PageState from './context/PageState';

// Routes
import Home from "./pages/Home";
import Test from "./pages/Test";
import DevicesSearch from "./pages/DeviceSearch";
import ShortCodeRouter from "./components/ShortCodeRouter";

const QuantumPaymentSolution = React.lazy(() => import("./pages/Products/QuantumPay"));
const ProductsScanning = React.lazy(() => import("./pages/Products/Scanning"));
const ProductsPrinting = React.lazy(() => import("./pages/Products/Printing"));
const ProductsResources = React.lazy(() => import("./pages/Products/Resources"));
const IndusriesTemplatePage = React.lazy(() => import("./templates/Industries/IndustriesTemplatePage"));
const DeviceTemplateRouter = React.lazy(() => import("./pages/DeviceTemplateRouter"));
const DeviceSpecsTemplateRouter = React.lazy(() => import("./pages/DeviceSpecsTemplateRouter"));
const DeviceDevelopmentTemplateRouter = React.lazy(() => import("./pages/DeviceDevelopmentTemplateRouter"));
const AppOverview = React.lazy(() => import("./templates/AppOverview"));
const DeviceBrief = React.lazy(() => import("./pages/DeviceBrief"));
const ProductSupport = React.lazy(() => import("./pages/Support/ProductSupport"));
const Products = React.lazy(() => import("./pages/Products"));
const Software = React.lazy(() => import("./pages/Software"));
const QIQ = React.lazy(() => import("./pages/Software/QIQ"));
const QBrowser = React.lazy(() => import("./pages/Software/QBrowser"));
const QHybrid = React.lazy(() => import("./pages/Software/QHybrid"));
const QLabs = React.lazy(() => import("./pages/Software/QLabs"));
const QNative = React.lazy(() => import("./pages/Software/QNative"));
const QServices = React.lazy(() => import("./pages/Software/QServices"));
const QVision = React.lazy(() => import("./pages/Software/QVision"));
const QWedge = React.lazy(() => import("./pages/Software/QWedge"));
const InfineaRetail = React.lazy(() => import("./pages/Software/InfineaRetail"));
// const AccessorySearch = React.lazy(() => import("./pages/AccessorySearch"));
// const Accessory = React.lazy(() => import("./pages/Accessory"));
const ApplicationSearch = React.lazy(() => import("./pages/ApplicationSearch"));
const TechnicalServices = React.lazy(() => import("./pages/TechnicalServices"));
const WarrantyServices = React.lazy(() => import("./pages/WarrantyServices"));
const Support = React.lazy(() => import("./pages/Support"));
const SupportTicket = React.lazy(() => import("./pages/Support/SupportTicket"));
const WarrantyTerms = React.lazy(() => import("./pages/Support/WarrantyTerms"));
const FAQs = React.lazy(() => import("./pages/FAQs"));
const Blog = React.lazy(() => import("./pages/Blog"));
const BlogPost = React.lazy(() => import("./templates/BlogPost"));
const About = React.lazy(() => import("./pages/About"));
const OfficeLocations = React.lazy(() => import("./pages/OfficeLocations"));
const News = React.lazy(() => import("./pages/News"));
const NewsPost = React.lazy(() => import("./templates/NewsPost"));
const Partners = React.lazy(() => import("./pages/Partners"));
const CaseStudies = React.lazy(() => import("./pages/CaseStudies"));
const DigitalCard = React.lazy(() => import("./pages/DigitalCard"));
const Legal = React.lazy(() => import("./pages/Legal"));
const Sitemap = React.lazy(() => import("./pages/Sitemap"));
const HeroLandingPage = React.lazy(() => import("./templates/HeroLandingPage"));
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));

const App = () => {
  GA_Tracking();
  if (process.env.REACT_APP_IS_PUSHING_LIVE === "true") {
    ClarityTracking();
    ZoomInfoTracking();
  }

  return <AuthState>
    <PageState>
      <Suspense fallback="">
        <Routes>
          {process.env.REACT_APP_IS_PUSHING_LIVE === "false" && <Route path="/test" element={<Test />} />}
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/directory/:contact" element={<DigitalCard />} />
          <Route path="/devices/:devicePath/brief" element={<DeviceBrief />} />
          <Route path="/devices/:devicePath/specifications" element={<DeviceSpecsTemplateRouter />} />
          <Route path="/devices/:devicePath/development" element={<DeviceDevelopmentTemplateRouter />} />
          <Route path="/devices/:devicePath" element={<DeviceTemplateRouter />} />
          <Route path="/devices" element={<DevicesSearch />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/quantumpay-solution" element={<QuantumPaymentSolution />} />
          <Route path="/products/scanning" element={<ProductsScanning />} />
          <Route path="/products/printing" element={<ProductsPrinting />} />
          <Route path="/products/resources" element={<ProductsResources />} />
          <Route path="/products/:path" element={<HeroLandingPage />} />
          <Route path="/software" element={<Software />} />
          <Route path="/software/quantum-iq" element={<QIQ />} />
          <Route path="/software/quantum-browser" element={<QBrowser />} />
          <Route path="/software/quantum-hybrid" element={<QHybrid />} />
          <Route path="/software/quantum-labs" element={<QLabs />} />
          <Route path="/software/quantum-native" element={<QNative />} />
          <Route path="/software/quantum-services" element={<QServices />} />
          <Route path="/software/quantum-vision" element={<QVision />} />
          <Route path="/software/quantum-wedge" element={<QWedge />} />
          <Route path="/software/infinea-retail" element={<InfineaRetail />} />
          <Route path="/software/:path" element={<HeroLandingPage />} />
          <Route path="/industry/:path" element={<IndusriesTemplatePage />} />
          {/* <Route path="/accessories" element={<AccessorySearch />} /> */}
          {/* <Route path="/accessories/:slug" element={<Accessory />} /> */}
          <Route path="/applications" element={<ApplicationSearch />} />
          <Route path="/applications/:appPath" element={<AppOverview/>} />
          <Route path="/frameworks/:appPath" element={<AppOverview />} />
          <Route path="/demos/:appPath" element={<AppOverview />} />
          <Route path="/technical-services" element={<TechnicalServices />} />
          <Route path="/warranty-services" element={<WarrantyServices />} />
          <Route path="/support/product/:devicePath" element={<ProductSupport />} />
          <Route path="/support/support-form" element={<SupportTicket />} />
          <Route path="/support/warranty-terms" element={<WarrantyTerms />} />
          <Route path="/support" element={<Support />} />
          <Route path="/frequently-asked-questions" element={<FAQs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/contact/offices" element={<OfficeLocations />} />
          <Route path="/news" element={<News />} />
          <Route path='/news/:slug' element={<NewsPost />} />
          <Route path="/r/:shortCode" element={<ShortCodeRouter />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/terms-of-service" element={<Legal />} />
          <Route path="/privacy-policy" element={<Legal />} />
          <Route path="/cookie-policy" element={<Legal />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </PageState>
  </AuthState>;
}

export default App;