import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import cld from '../../../../constants/cloudinary';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { scale } from "@cloudinary/url-gen/actions/resize";

import "./index.scss";

/**
 * Scene 2
 * Enterprise Mobility Hardware
*/

export const scene2Timeline = gsap.timeline();

const Scene2 = () => {
  const devicesAnimationDuration = .6;
  const devicesTransitionTime = "<0.25";
  const devicesEase = "power2.out";

  useGSAP(() => scene2Timeline
    .fromTo(".scene-2__device-1", {
      autoAlpha: 0,
      transform: "translateY(100%)"
    }, {
      autoAlpha: 1,
      transform: "translateY(0)",
      duration: devicesAnimationDuration,
      ease: devicesEase
    })
    .fromTo(".scene-2__device-2", {
      autoAlpha: 0,
      transform: "translateY(100%)"
    }, {
      autoAlpha: 1,
      transform: "translateY(0)",
      duration: devicesAnimationDuration,
      ease: devicesEase
    }, devicesTransitionTime)
    .fromTo(".scene-2__device-3", {
      autoAlpha: 0,
      transform: "translateY(100%) rotate(140deg)"
    }, {
      autoAlpha: 1,
      transform: "translateY(0) rotate(150deg)",
      duration: devicesAnimationDuration,
      ease: devicesEase
    }, devicesTransitionTime)
    .fromTo(".scene-2__device-4", {
      autoAlpha: 0,
      transform: "translateY(100%)"
    }, {
      autoAlpha: 1,
      transform: "translateY(0)",
      duration: devicesAnimationDuration,
      ease: devicesEase
    }, devicesTransitionTime)
    .fromTo(".scene-2__device-5", {
      autoAlpha: 0,
      transform: "translateY(100%) rotate(8deg)"
    }, {
      autoAlpha: 1,
      transform: "translateY(0) rotate(8deg)",
      duration: devicesAnimationDuration,
      ease: devicesEase
    }, devicesTransitionTime)
    .fromTo(".scene-2__device-6", {
      autoAlpha: 0,
      transform: "translateY(100%)"
    }, {
      autoAlpha: 1,
      transform: "translateY(0) rotate(20deg)",
      duration: devicesAnimationDuration,
      ease: devicesEase
    }, devicesTransitionTime)
    .fromTo(".scene-2__device-7", {
      autoAlpha: 0,
      transform: "translateY(100%) rotate(0deg)"
    }, {
      autoAlpha: 1,
      transform: "translateY(0) rotate(-5deg)",
      duration: devicesAnimationDuration,
      ease: devicesEase
    }, devicesTransitionTime)
    .fromTo(".scene-2__device-8", {
      autoAlpha: 0,
      transform: "translateY(100%) rotate(-11deg)"
    }, {
      autoAlpha: 1,
      transform: "translateY(0) rotate(11deg)",
      duration: devicesAnimationDuration,
      ease: devicesEase
    }, devicesTransitionTime)
    .to([
      ".scene-2__device-1",
      ".scene-2__device-2",
      ".scene-2__device-3",
      ".scene-2__device-4",
      ".scene-2__device-5",
      ".scene-2__device-6",
      ".scene-2__device-7",
      ".scene-2__device-8"
    ], {
      autoAlpha: 0,
      duration: 0.5
    }, ">+1")
  );

  return <div className="scenes scenes__scene-2">
    <AdvancedImage
      className="scene-2__device-1"
      cldImg={cld.image("components/we-are-animation/lpr-13-14-flex-case-pistol-grip").resize(scale().width(400)).format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Linea Pro 13/14 Flex Case Pistol Grip"
    />
    <AdvancedImage
      className="scene-2__device-2"
      cldImg={cld.image("components/we-are-animation/mobile-printer-ipc-green").resize(scale().width(400)).format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Mobile Printer hardware MP22 in IPCMobile Green"
    />
    <AdvancedImage
      className="scene-2__device-3"
      cldImg={cld.image("components/we-are-animation/qppro-plus-tilted").resize(scale().width(400)).format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="QuantumPay Plus payment hardware"
    />
    <AdvancedImage
      className="scene-2__device-4"
      cldImg={cld.image("components/we-are-animation/apple-lpr-13-14").resize(scale().width(400)).format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Linea Pro Rugged for iPhone 13 and iPhobe 14"
    />
    <AdvancedImage
      className="scene-2__device-5"
      cldImg={cld.image("components/we-are-animation/linea-pro-google-pixel").resize(scale().width(400)).format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Linea Pro for Google Pixel"
    />
    <AdvancedImage
      className="scene-2__device-6"
      cldImg={cld.image("devices/haloring-scanner-45deg-front-left-guard-on").resize(scale().width(400)).format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="HaloRing at a 45 degree view with bumper guard on"
    />
    <AdvancedImage
      className="scene-2__device-7"
      cldImg={cld.image("components/we-are-animation/android-kiosk").resize(scale().width(400)).format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Kiosk with Android operating system"
    />
    <AdvancedImage
      className="scene-2__device-8"
      cldImg={cld.image("components/we-are-animation/apple-kiosk").resize(scale().width(400)).format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Kiosk with iOS operating system"
    />
  </div>
}

export default Scene2;