import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import cld from '../../../../constants/cloudinary';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { scale } from "@cloudinary/url-gen/actions/resize";

import "./index.scss";

/**
 * Scene 1
 * Intro scene
*/

export const scene1Timeline = gsap.timeline();

const Scene1 = () => {

  useGSAP(() => scene1Timeline
    .fromTo(".scene-1__intro-text", {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, 0%)",
      autoAlpha: 0
    }, {
      transform: "translate(-50%, -50%)",
      autoAlpha: 1,
      duration: .5,
      delay: .1,
    })
    .to(".scene-1__intro-text", {
      display: "none",
      transform: "translate(-50%, -120%)",
      autoAlpha: 0,
      duration: .5,
    }, ">.6")
    .addLabel("collageStart")
    .fromTo(".scene-1__collage", {
      autoAlpha: 0
    },
      {
        autoAlpha: 1,
        duration: 1
      }, "<.1")
    .fromTo([
      ".collage__col-1",
      ".collage__col-3"
    ], {
      transform: "translateY(90%)",
    }, {
      transform: "translateY(-5%)",
      ease: "power1.out",
      duration: 1.8
    }, "<")
    .fromTo(".collage__col-2", {
      transform: "translateY(-90%)",
    }, {
      transform: "translateY(5%)",
      ease: "power1.out",
      duration: 1.8
    }, "<")
    .to([
      ".collage__col-1",
      ".collage__col-2",
      ".collage__col-3"
    ], {
      autoAlpha: 0.1
    }, ">-.5")
    .fromTo(".exp__text-1", {
      autoAlpha: 0,
      transform: "translateY(50%)"
    },
      {
        autoAlpha: 1,
        transform: "translateY(0)",
        color: "#FFFFFF"
      })
    .fromTo(".exp__text-2", {
      autoAlpha: 0,
      transform: "translateY(50%)"
    },
      {
        autoAlpha: 1,
        transform: "translateY(0)",
        color: "#CCCCCC"
      })
    .fromTo(".exp__text-3", {
      autoAlpha: 0,
      transform: "translateY(50%)"
    },
      {
        autoAlpha: 1,
        transform: "translateY(0)",
        color: "#84D007"
      })
    .to(".scene-1__experiences-text", {
      display: "none",
      transform: "translate(-50%, -120%)",
      autoAlpha: 0,
      duration: .5,
    }, ">1")
    .to(".scene-1__collage", {
      display: "none",
      autoAlpha: 0
    }, "<")
  );

  return <div className="scenes scenes__scene-1">
    <div className="scene-1__intro-text">
      <div>We Are</div>
      <AdvancedImage
        cldImg={cld.image("logos/ipcmobile-logo-inverted")}
        plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
        className="logo"
        alt="IPCMobile Logo"
      />
    </div>
    <div className="scene-1__collage">
      <div className="collage__col-1">
        <AdvancedImage
          cldImg={cld.image("components/we-are-animation/airline-payments-in-air").resize(scale().width(400)).format('webp')}
          plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
          alt="Airline wtewardess taking payment from passanger in flight"
        />
        <AdvancedImage
          cldImg={cld.image("components/we-are-animation/asset-tracking-beacon").resize(scale().width(400)).format('webp')}
          plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
          alt="Tracking beacon on a pallet in a warehouse"
        />
        <AdvancedImage
          cldImg={cld.image("components/we-are-animation/linea-pro-rugged-13-14-scanning-retail-shelf").resize(scale().width(400)).format('webp')}
          plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
          alt="Linea Pro rugged 13/14 with pistol grip scanning reatil store shelf"
        />
      </div>
      <div className="collage__col-2">
        <AdvancedImage
          cldImg={cld.image("components/we-are-animation/beacon-tracking").resize(scale().width(400)).format('webp')}
          plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
          alt="Asset tracking beacon on a map showing paths and coordinates"
        />
        <AdvancedImage
          cldImg={cld.image("components/we-are-animation/ipad-payments-qpp-451").resize(scale().width(400)).format('webp')}
          plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
          alt="View over persons shoulder of them accepting payments on iPad with QuantumPay 451"
        />
        <AdvancedImage
          cldImg={cld.image("components/we-are-animation/mobile-payment-on-ipad").resize(scale().width(400)).format('webp')}
          plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
          alt="Restaurt cafe customer paying by inserting payment card into iPad using QuantumPay Connected"
        />
      </div>
      <div className="collage__col-3">
        <AdvancedImage
          cldImg={cld.image("components/we-are-animation/halocards").resize(scale().width(400)).format('webp')}
          plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
          alt="HaloRing in companion mode scanning packages on a conveyor belt amd sending barcode scanned to host laptop"
        />
        <AdvancedImage
          cldImg={cld.image("components/we-are-animation/nexusconnect-warehouse-scanning").resize(scale().width(400)).format('webp')}
          plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
          alt="Warehouse worker scanning pallets using NexusConnect"
        />
        <AdvancedImage
          cldImg={cld.image("components/we-are-animation/rfid-event-admission").resize(scale().width(400)).format('webp')}
          plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
          alt="mobile payments with RFID wristband"
        />
      </div>
    </div>
    <div className="scene-1__experiences-text">
      <div className="exp__text-1">The Magic</div>
      <div className="exp__text-2">Behind Successful</div>
      <div className="exp__text-3">Mobile Enterprise Experiences</div>
    </div>
  </div>

}

export default Scene1;