import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import cld from '../../../../constants/cloudinary';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { scale } from "@cloudinary/url-gen/actions/resize";

import "./index.scss";

/**
 * Scene 4
 * Beacons and Trackers
 */

export const scene4Timeline = gsap.timeline();

const Scene4 = () => {

  useGSAP(() => scene4Timeline
    .fromTo(".scene-4__lines--color", {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      duration: 1,
      delay: 0.1
    })
    .fromTo(".scene-4__lines--white", {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      duration: 1,
    }, "<.4")
    .fromTo(".scene-4__map-marker", {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      duration: .5,
    }, "<.6")
    .fromTo(".scene-4__beacon", {
      autoAlpha: 0,
      transform: "translate(-50%, 0%)"
    }, {
      autoAlpha: 1,
      duration: .5,
      transform: "translate(-50%, -50%)"
    })
    // Fade out
    .to(".scene-4__lines--white", {
      autoAlpha: 0,
      duration: 2.2,
      delay: 1.5,
      transform: "translate(100vw, -50%)",
      ease: "power1.out"
    })
    .to(".scene-4__map-marker", {
      autoAlpha: 0,
      duration: 1,
      ease: "power1.out",
      transform: "translateX(100vw)"
    }, "<")
    .to(".scene-4__lines--color", {
      autoAlpha: 0,
      duration: 1,
      ease: "power1.out",
      transform: "translate(-100%, -50%)"
    }, "<")
    .to(".scene-4__beacon", {
      autoAlpha: 0,
      transform: "translate(-50%, -20%)",
      ease: "power1.out"
    }, "<.5")
  );

  return <div className="scenes scenes__scene-4">
    <AdvancedImage 
      className="scene-4__lines--color"
      cldImg={cld.image("components/we-are-animation/map-lines-color")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Colored map lines"
    />
    <AdvancedImage 
      className="scene-4__lines--white"
      cldImg={cld.image("components/we-are-animation/map-lines-white")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="White map lines"
    />
    <AdvancedImage 
      className="scene-4__map-marker"
      cldImg={cld.image("components/we-are-animation/map-marker")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Red map marker"
    />
    <AdvancedImage 
      className="scene-4__beacon"
      cldImg={cld.image("components/we-are-animation/beacon").resize(scale().width(400)).format("auto")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Beacon hardware"
    />
  </div>

}

export default Scene4;

