import { Cloudinary } from "@cloudinary/url-gen";

const cld = new Cloudinary({
  cloud: {
    cloudName: 'ipcmobile'
  }
  // // Custom Cloudinary domain name
  // url: {
  //   secureDistribution: 'www.ipcmobile.com',
  //   secure: true
  // }
});

export default cld;