import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import cld from '../../../../constants/cloudinary';
import { AdvancedImage, lazyload } from '@cloudinary/react';

import "./index.scss";

/**
 * Scene 11
 * Cloud Development
 */

export const scene11Timeline = gsap.timeline();

const Scene11 = () => {

  useGSAP(() => scene11Timeline
    .fromTo(".scene-11__gradient-bg", {
      autoAlpha: 0,
    }, {
      autoAlpha: 1,
      duration: 1,
    })
    .fromTo(".scene-11__processor", {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      duration: 1,
      ease: "power2.out"
    }, "<.5")
    .fromTo(".scene-11__server-rack", {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      duration: 1,
      ease: "power2.out"
    })
    .fromTo(".scene-11__vigilant", {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      duration: 1,
      ease: "power2.out"
    }, "<.5")
    .fromTo(".scene-11__cloud-upload", {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      duration: 1,
      ease: "power2.out"
    }, "<")
    .fromTo(".scene-11__data-stripes", {
      autoAlpha: 0,
      transform: "translate(40%, 20%)"
    }, {
      autoAlpha: 1,
      duration: 1,
      transform: "translate(0%, 0%)",
      ease: "power2.out"
    }, "<0.5")
    .fromTo([
      ".scene-11__data-streams",
      ".scene-11__data-stream"
    ], {
      autoAlpha: 0,
      transform: "translate(-160%, 80%)"
    }, {
      autoAlpha: 1,
      duration: 1,
      transform: "translate(0%, 0%)",
      ease: "power2.out"
    })
    .addLabel("fadeInLoaded")
    // Cleanup
    .to([
      ".scene-11__data-streams",
      ".scene-11__data-stream"
    ], {
      autoAlpha: 0,
      duration: 1,
      delay: 2.2
    })
    .to([
      ".scene-11__vigilant",
      ".scene-11__cloud-upload",
      ".scene-11__data-stripes",
    ], { autoAlpha: 0 })
    .to(".scene-11__server-rack", { autoAlpha: 0 })
    .to(".scene-11__processor", { autoAlpha: 0 }, "<.5")
    .to(".scene-11__gradient-bg", { autoAlpha: 0 })
  )

  return <div className="scenes scenes__scene-11">
    <div className="scene-11__gradient-bg"></div>
    <AdvancedImage
      className="scene-11__processor processor-1"
      cldImg={cld.image("components/we-are-animation/processor-with-light-beam")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="A computer processor with a light beam"
    />
    <AdvancedImage
      className="scene-11__processor processor-2"
      cldImg={cld.image("components/we-are-animation/processor-with-light-beam")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="A computer processor with a light beam"
    />
    <AdvancedImage
      className="scene-11__processor processor-3"
      cldImg={cld.image("components/we-are-animation/processor-with-light-beam")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="A computer processor with a light beam"
    />
    <AdvancedImage
      className="scene-11__processor processor-4"
      cldImg={cld.image("components/we-are-animation/processor-with-light-beam")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="A computer processor with a light beam"
    />
    <AdvancedImage
      className="scene-11__vigilant"
      cldImg={cld.image("components/we-are-animation/vigilant")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="A vigilant user"
    />
    <AdvancedImage
      className="scene-11__cloud-upload"
      cldImg={cld.image("components/we-are-animation/cloud-upload-user-data")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="cloud with upload and download arrows"
    />
    <AdvancedImage
      className="scene-11__data-stripes"
      cldImg={cld.image("components/we-are-animation/data-stripes")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="stripes depicting data flow"
    />
    <AdvancedImage
      className="scene-11__server-rack server-rack-1"
      cldImg={cld.image("components/we-are-animation/server-rack")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a server rack"
    />
    <AdvancedImage
      className="scene-11__data-streams streams-1"
      cldImg={cld.image("components/we-are-animation/data-streams")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="mutiple lines depicting a stream of data"
    />
    <AdvancedImage
      className="scene-11__data-streams streams-2"
      cldImg={cld.image("components/we-are-animation/data-streams")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="mutiple lines depicting a stream of data"
    />
    <AdvancedImage
      className="scene-11__data-stream stream-1"
      cldImg={cld.image("components/we-are-animation/data-stream")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a line depicting a stream of data"
    />
    <AdvancedImage
      className="scene-11__data-stream stream-2"
      cldImg={cld.image("components/we-are-animation/data-stream")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a line depicting a stream of data"
    />
    <AdvancedImage
      className="scene-11__data-stream stream-3"
      cldImg={cld.image("components/we-are-animation/data-stream")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a line depicting a stream of data"
    />
    <AdvancedImage
      className="scene-11__server-rack server-rack-2"
      cldImg={cld.image("components/we-are-animation/server-rack")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a server rack"
    />

  </div>

}

export default Scene11;