import React from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    clarity: any;
  }
}

function loadClarityTracker() {
  window['clarity'] = window['clarity'] || function () { (window['clarity'].q = window['clarity'].q || []).push(arguments) };
  const t = document.createElement('script');
  t.async = true;
  t.src = "https://www.clarity.ms/tag/9c1z3gm7zl";
  const y = document.getElementsByTagName('script')[0];
  if (y.parentNode) y.parentNode.insertBefore(t, y);
}

const ClarityTracking = () => {
  let location = useLocation();

  React.useEffect(() => {
    loadClarityTracker();

    return () => {
      if (document.querySelectorAll('[src*="https://www.clarity.ms/tag/9c1z3gm7zl"]').length) {
        document.querySelectorAll('[src*="https://www.clarity.ms/tag/9c1z3gm7zl"]').forEach((el: any) => el.remove());
      }
    }

}, [location]);

}

export default ClarityTracking;




