import React, { Fragment, useContext } from 'react';
import { PageContext } from '../context/PageState'
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom'

const SiteBanner = ():JSX.Element => {
  const { siteBanner } = useContext(PageContext);
  
  if (siteBanner.active) {
    return <Link to={siteBanner.link} className="site-banner text-center">
      <Alert style={{ color: siteBanner.color, backgroundColor: siteBanner.backgroundColor}}>
          {siteBanner.text}
      </Alert>
    </Link>;
  }
  return <Fragment></Fragment>
}

export default SiteBanner;