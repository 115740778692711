import { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom';
import cld from '../../constants/cloudinary';
import { AdvancedImage, responsive } from '@cloudinary/react';
import { PageContext } from '../../context/PageState';

import './index.scss';

interface IJumpPadLinks {
  links: IJumpPadLink[];
  iteration?: number;
};

export interface IJumpPadLink {
  action?: "form" | undefined;
  title: string;
  className?: string;
  url: string;
  image: {
    src: string;
    alt: string;
  },
  hidden: boolean;
};

const JumpPad: React.FC<IJumpPadLinks> = ({links, iteration}) => {

  const { modalForm } = useContext(PageContext);

  return <div className="cp_jumpad" key={iteration}>
    <div className="container mx-auto text-center">
      {links.map((link, i) => {

        if (link.hidden) return <Fragment key={i} />;

        const LinkItem = () => <div className={[
          "flex-grow-1",
          "flex-shrink-1",
          link.className ? link.className : ''
        ].join(' ')}>
          <div className="cp_jumpad__img-container">
            <AdvancedImage
              cldImg={cld.image(link.image.src).format('webp').quality('auto')}
              plugins={[responsive({ steps: [400] })]}
              className="img-fluid"
              alt={link.image.alt.length > 0 ? link.image.alt : undefined}
            />
          </div>
          <h3 className='cp_jumpad__text'>{link.title.length > 0 ? link.title : undefined}</h3>
        </div>

        if (link.action !== undefined && link.action === "form") return <div
          className="cp_jumpad__link"
          key={i}
          onClick={() => modalForm.toggle()}
        >
          <LinkItem />
        </div>;

        return <Link className="cp_jumpad__link" to={link.url} key={i}>
          <LinkItem />
        </Link>
      })}
    </div>
  </div>;
};

export default JumpPad