
import React from 'react';
import { useLocation } from 'react-router-dom';

declare global {
    interface Window {
        ZIProjectKey: string;
    }
}

function loadZoomInfoTracker() {
    window["ZIProjectKey"] = '5babf613ff1680632839';
    var zi = document.createElement('script');
    zi.async = true;
    zi.src = "https://js.zi-scripts.com/zi-tag.js";

    const y = document.getElementsByTagName('script')[0];
    if (y.parentNode) y.parentNode.insertBefore(zi, y);
}

const ZoomInfoTracking = () => {
    let location = useLocation();

    React.useEffect(() => {
        loadZoomInfoTracker();

        return () => {
            if (document.querySelectorAll('[src*="https://js.zi-scripts.com/zi-tag.js"]').length) {
                document.querySelectorAll('[src*="https://js.zi-scripts.com/zi-tag.js"]').forEach((el: any) => el.remove());
            }
        }
    }, [location]);

}

export default ZoomInfoTracking;