import { useRef } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import cld from '../../../../constants/cloudinary';
import { AdvancedImage, lazyload } from '@cloudinary/react';

import "./index.scss";

/**
 * Scene 5
 * Wearables
 */

export const scene5Timeline = gsap.timeline();

const Scene5 = () => {

  const scene5video = useRef<any>(null);
  let playPromise: Promise<void>;

  useGSAP(() => scene5Timeline
    .fromTo(".scene-5__android-logo", {
      autoAlpha: 0,
      transform: "translate(-50%, 180%)",
    }, {
      autoAlpha: 1,
      transform: "translate(-50%, 0%)",
    })
    .fromTo(scene5video.current, {
      autoAlpha: 0
    }, {
      autoAlpha: 1
    })
    .to(scene5video.current, {
      duration: 7,
      ease: "none",
      onStart: () => {
        if (scene5video.current) {
          playPromise = scene5video.current.play();
        };
      }
    })
    .to(scene5video.current, {
      autoAlpha: 0,
      duration: 1,
      onComplete: () => {
        if (playPromise !== undefined) {
          playPromise.then(_ => {
            scene5video.current.pause();
            scene5video.current.currentTime = 0;
          })
        }
      }
    })
    .to(".scene-5__android-logo", {
      autoAlpha: 0,
      transform: "translate(-50%, 180%)"
    }, "<")
  );

  return <div className="scenes scenes__scene-5">
    <video className="scene-5__video" ref={scene5video} muted playsInline preload="none">
      <source src="https://res.cloudinary.com/ipcmobile/video/upload/v1/components/we-are-animation/haloring-hero.webm" type="video/webm" />
      <source src="https://res.cloudinary.com/ipcmobile/video/upload/v1/components/we-are-animation/haloring-hero.mp4" type="video/mp4" />
      <source src="https://res.cloudinary.com/ipcmobile/video/upload/v1/components/we-are-animation/haloring-hero.ogv" type="video/ogg" />
    </video>
    <AdvancedImage 
      className="scene-5__android-logo" 
      cldImg={cld.image("components/we-are-animation/android-wearables")}
      alt="Android logo"
      plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 })]}
    />
  </div>

}

export default Scene5;

