export function set(cname:string, cvalue: boolean | number | string, exdays:number) {
  let d:Date = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires:string = `expires=${d.toUTCString()}`;
  document.cookie = cname + "=" + cvalue + ";" + expires + ";SameSite=Strict;Secure;path=/";
}

export function get(cname:string):string | null {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function remove(cname:string) {
  if (get(cname)) {
    const cvalue = get(cname);
    const expires = "expires=Thu, 01 Jan 1970 00:00:01 GMT";
    document.cookie = cname + "=" + cvalue + ";" + expires + ";SameSite=Strict;Secure;path=/";
  }
}