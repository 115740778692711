import React, { useContext, useEffect, Fragment } from 'react';
import { db } from '../context/AuthState';
import { PageContext } from '../context/PageState';
import { doc, getDoc } from "firebase/firestore";
import { useParams } from 'react-router-dom';


const ShortCodeRouter: React.FC = () => {
  const { errors } = useContext(PageContext);
  const { shortCode } = useParams<{[key: string]: string}>();
  
  useEffect(() => {

   const getPageData = async () => {

    if (!shortCode) return window.location.href = "/";

     const docRef = doc(db, "short_urls", shortCode.toString());
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) return window.location.href = "/";

    const { longUrl, note } = docSnap.data();

    window.gtag('event', 'short_url', {
      page_title: note !== "undefined" && note.length > 0 ? note : shortCode,
      page_location: window.location.pathname
    });

    return window.location.href = longUrl;


   }
   getPageData();
    

    // const ref = db.doc(`/short_urls/${shortCode}`);
    
    //   ref.get()
    //   .then((doc: any) => {
    //     if (!doc.exists) return window.location.href = "/";
    //     const { longUrl, note } = doc.data();
      
    //     window.gtag('event', 'short_url', {
    //       page_title: note !== "undefined" && note.length > 0 ? note : shortCode,
    //       page_location: window.location.pathname
    //     });

    //     return window.location.href = longUrl;
        
    //   }).catch((err: any) => errors.report(err));

  },[errors, shortCode])

  document.title = "Redirect..."

  return <Fragment><p>Redirect...</p></Fragment>;

}

export default ShortCodeRouter