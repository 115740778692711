import React, { useContext } from 'react';
import { PageContext } from '../../../context/PageState';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';
import "./index.scss";

const Menu = () => {
    const { isDesktop } = useContext(PageContext);
    return isDesktop ? <DesktopNav /> : <MobileNav />
}

export default Menu;
