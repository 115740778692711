import React, {useState} from 'react'
import Iframe from 'react-iframe';

import "./index.scss";

const GetInTouchForm = () => {
  const [iframeSrc, setIframeSrc] = useState<string>("");
  const [iframeId, setIframeId] = useState<string>("");
  const [iframeHeight, setIframeHeight] = useState<string>("0");

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();

    let sandboxSrc;
    let id;

    switch (e.target.value) {
      case "moreInfo":
        sandboxSrc = "zIdFwW1ifjD638s7Q1t-8B_34DKb1izrYLpIhQiK7xE/58840d66-803e-ed11-9db0-000d3a1d375b?ad=";
        id = "4c36879d-9ce0-4165-9368-7330d59a07c5";
        break;
      case "bookDemo":
        sandboxSrc = "IArlfGv9ue4-fD16MlI5D3rL2E_cKcnS_bGyA8uowVA/2c11fc3e-1d40-ed11-9db0-000d3a1d375b?ad=";
        id = "b3726a6f-2ca5-4e21-b38b-ad48abb99a7e";
        break;
      case "purchase":
        sandboxSrc = "6alUqS2m6ipwAdC7S-Qk2vShyffqhRLve7tr37ZIUf4/e97ad768-1d40-ed11-9db0-000d3a1d375b?ad=";
        id = "a1a626dd-8682-4798-9802-ab9aa720bc9b";
        break;
      default:
        break;
    }


    if (sandboxSrc) setIframeSrc("https://eaa4a5fcdb674bfca7a438ebfe6add3e.svc.dynamics.com/t/formsandbox/" + sandboxSrc + encodeURIComponent(document.location.toString()));
    if (id) setIframeId(id)
    setIframeHeight("600px");
  }

  return <div id="get-in-touch-form">
    <div className="text-center">
      <h4>Get in touch</h4>
      <p className="subheading-text">
        <span style={{ fontSize: "1rem" }}>
          For RMAs or support related questions please visit our
          &nbsp;<a href="https://ipcmobile.com/support/support-form">Support Form</a>.
        </span>
      </p>
    </div>
    <select className="form-selector" onChange={(e) => handleChange(e)} value="">
      <option value="" disabled={true}>I want to...</option>
      <option value="moreInfo">Receive Information</option>
      <option value="bookDemo">Book Demo</option>
      <option value="purchase">Purchase</option>
    </select>

    <Iframe
      url={iframeSrc}
      width="100%"
      id={iframeId}
      height={iframeHeight}
      frameBorder={0}
    />
  </div>
}

export default GetInTouchForm