import IState from "../types";

const INITIAL_STATE: IState = {
  metadata: {},
  subfooter: {},
  dbLoaded: false
}

// Goolge Analytics for
export const GA_Events = {
  category: {},
  events: {
    addPaymentInfo: 'add_payment_info',
    addToCart: 'add_to_cart',
    addToWishlist: 'add_to_wishlist',
    beginCheckout: 'begin_checkout',
    checkoutProgress: 'checkout_progress',
    exception: 'exception',
    generate_lead: 'generate_lead',
    login: 'login',
    pageView: 'page_view',
    purchase: 'purchase',
    refund: 'refund',
    removeFromCart: 'remove_from_cart',
    screenView: 'screen_view',
    search: 'search',
    searchContent: 'select_content',
    searchCheckoutOption: 'set_checkout_option',
    share: 'share',
    sign_up: 'sign_up',
    timingComplete: 'timing_complete',
    viewItem: 'view_item',
    viewItemList: 'view_item_list',
    viewPromotion: 'view_promotion',
    viewSearchResults: 'view_search_results'
  },
  label: {},
  trackType: {
  },
  value: {},
}

export default INITIAL_STATE;