import React from 'react';
import { Link } from 'react-router-dom';


interface IState {
  link: string;
  src: {
    mobile: string;
    desktop: string;
  }
  alt: string;
}

const initialState: IState = {
  link: '',
  src: {
    mobile: '',
    desktop: ''
  },
  alt: ''
}

const LinkType = (props: any) => {
  var pattern = new RegExp('^(https?:\\/\\/)?');
  if (props.link !== undefined && pattern.test(props.link.url)) {
    // Anchor Tag
    return <a
      href={props.link}
      className='d-block my-3 mt-lg-0 mb-lg-5 banner-alt'
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  } else {
    // DIV Tag
    return <Link
      to={props.link}
      className='d-block my-3 mt-lg-0 mb-lg-5 banner-alt'
    >
      {props.children}
    </Link>
  }
};


const PromoBanner: React.FC = (props:any) => {
  const [state, setState] = React.useState<any>(initialState);

  const setDefaultBanner = () => setState({
    link: 'https://warranty.ipcmobile.com',
    src: {
      mobile:
        'partials/infinite-peripherals-warranty-registration-banner-mobile.webp',
      desktop:
        'partials/infinite-peripherals-warranty-registration-banner-desktop.webp'
    },
    alt:
      'IPCMobile barcode scanner barcode scanning banner'
  });

  React.useEffect(() => {

    if (props.functionality !== undefined) {
      switch (true) {
        case props.functionality.indexOf('Scanning') > -1:
          setState({
            link: '/products/scanning',
            src: {
              mobile:
                'partials/infinite-peripherals-barcode-scanner-barcode-scanning',
              desktop:
                'partials/infinite-peripherals-barcode-scanner-barcode-scanning-wide'
            },
            alt:
              'IPCMobile barcode scanner barcode scanning banner'
          });
          break;
        case props.functionality.indexOf('Payment') > -1:
          setState({
            link: '/products/payment',
            src: {
              mobile:
                'partials/infinite-peripherals-mobile-payment-pos',
              desktop:
                'partials/infinite-peripherals-mobile-payment-pos-wide'
            },
            alt: 'IPCMobile mobile payment pos banner'
          });
          break;
        case props.functionality.indexOf('Printing') > -1:
          setState({
            link: '/products/printing',
            src: {
              mobile:
                'partials/infinite-peripherals-bluetooth-printer-wireless-printer-mobile-printer',
              desktop:
                'partials/infinite-peripherals-bluetooth-printer-wireless-printer-mobile-printer-wide'
            },
            alt:
              'IPCMobile bluetooth printer wireless printer mobile printer banner'
          });
          break;
        default:
          setDefaultBanner();
      }
    } else {
      setDefaultBanner();
    }
  }, [props]);

  return <LinkType {...state}>
    <img
      src={`https://res.cloudinary.com/ipcmobile/image/upload/c_fill,g_auto,f_auto,fl_lossy,w_300/v1/${state.src.mobile}`}
      srcSet={`https://res.cloudinary.com/ipcmobile/image/upload/c_fill,g_auto,w_600/v1/${state.src.mobile}, 2x`}
      className='img-fluid d-block d-md-none'
      alt={state.alt}
    />
    <img
      src={`https://res.cloudinary.com/ipcmobile/image/upload/c_scale,f_auto,fl_lossy,w_800/v1/${state.src.desktop}`}
      srcSet={`https://res.cloudinary.com/ipcmobile/image/upload/c_scale,f_auto,fl_lossy,w_1600/v1/${state.src.desktop}, 2x`}
      className='img-fluid d-none d-md-block'
      alt={state.alt}
    />
  </LinkType>;
}

export default PromoBanner;




