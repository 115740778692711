import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import cld from '../../../../constants/cloudinary';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { scale } from "@cloudinary/url-gen/actions/resize";

import "./index.scss";

/**
 * Scene 6
 * Express Experiences
 */

export const scene6Timeline = gsap.timeline();

const Scene6 = () => {

  const wristbandAnimationTime = .6;
  const wristbandAnimationEase = "power2.out";

  useGSAP(() => scene6Timeline
    .fromTo(".scene-6__rfid-event-admission-wristbands", {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      duration: .5,
    })
    .to(".scene-6__wristband--green", {
      transform: "translate(40%, -200%) rotate(15deg)",
      duration: wristbandAnimationTime,
      ease: wristbandAnimationEase
    })
    .to(".scene-6__wristband--yellow", {
      transform: "translate(20%, -125%) rotate(10deg)",
      duration: wristbandAnimationTime,
      ease: wristbandAnimationEase
    }, "<")
    .to(".scene-6__wristband--red", {
      transform: "translate(20%, 40%) rotate(-10deg)",
      duration: wristbandAnimationTime,
      ease: wristbandAnimationEase
    }, "<")
    .to(".scene-6__wristband--purple", {
      transform: "translate(40%, 100%) rotate(-15deg)",
      duration: wristbandAnimationTime,
      ease: wristbandAnimationEase
    }, "<")
    .fromTo(".scene-6__rfid-wristband--payment", {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      duration: 1,
      ease: "power1.out"
    }, ">")
    .fromTo(".scene-6__google-pay", {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      duration: 1,
    })
    .fromTo(".scene-6__apple-pay", {
      autoAlpha: 0 
    }, {
      autoAlpha: 1,
      duration: 1
    }, "<")
    .fromTo(".scene-6__lpr-ticket-admission", {
      autoAlpha: 0       
    }, {
      autoAlpha: 1,
      transform: "translateY(0) rotate(10deg)",
      duration: 1,
      ease: "power2.out",
    }, "<.2")
    .fromTo(".scene-6__lpr-accept-mobile-payments", {
      autoAlpha: 0      
    }, {
      autoAlpha: 1,
      transform: "translateY(0) rotate(-10deg)",
      duration: 1,
      ease: "power2.out"
    }, "<.2")
    .to([
      ".scene-6__rfid-event-admission-wristbands",
      ".scene-6__rfid-wristband--payment",
      ".scene-6__google-pay",
      ".scene-6__apple-pay",
      ".scene-6__lpr-ticket-admission",
      ".scene-6__lpr-accept-mobile-payments"
    ], {
      autoAlpha: 0,
      duration: 1
    }, ">1")
  );

  return <div className="scenes scenes__scene-6">
    <div className="scene-6__rfid-event-admission-wristbands">
      <AdvancedImage
        className="scene-6__wristband--purple img-fluid"
        cldImg={cld.image("components/we-are-animation/rfid-wristbandsrfid-wristband-purple").resize(scale().width(400)).format("auto")}
        plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
        alt="Purple RFID wristband"
      />
      <AdvancedImage
        className="scene-6__wristband--red img-fluid"
        cldImg={cld.image("components/we-are-animation/rfid-wristbandsrfid-wristband-red").resize(scale().width(400)).format("auto")}
        plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
        alt="Red RFID wristband"
      />
      <AdvancedImage
        className="scene-6__wristband--orange img-fluid"
        cldImg={cld.image("components/we-are-animation/rfid-wristbandsrfid-wristband-orange").resize(scale().width(400)).format("auto")}
        plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
        alt="Orange RFID wristband"
      />
      <AdvancedImage
        className="scene-6__wristband--yellow img-fluid"
        cldImg={cld.image("components/we-are-animation/rfid-wristbandsrfid-wristband-yellow").resize(scale().width(400)).format("auto")}
        plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
        alt="Yellow RFID wristband"
      />
      <AdvancedImage
        className="scene-6__wristband--green img-fluid"
        cldImg={cld.image("components/we-are-animation/rfid-wristbandsrfid-wristband-green").resize(scale().width(400)).format("auto")}
        plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
        alt="Green RFID wristband"
      />
    </div>
    <AdvancedImage
      className="scene-6__rfid-wristband--payment"
      cldImg={cld.image("components/we-are-animation/rfid-payment-wristband").resize(scale().width(400)).format("auto")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Payment RFID wristband"
    />
    <AdvancedImage
      className="scene-6__google-pay"
      cldImg={cld.image("components/we-are-animation/google-pay")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Google Pay logo"
    />
    <AdvancedImage
      className="scene-6__apple-pay"
      cldImg={cld.image("components/we-are-animation/apple-pay")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Apple pay logo"
    />
    <AdvancedImage
      className="scene-6__lpr-ticket-admission"
      cldImg={cld.image("components/we-are-animation/lpr-nfc-ticket-admission").resize(scale().width(400)).format("auto")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Linea Pro Rugged NFC ticket admission"
    />
    <AdvancedImage
      className="scene-6__lpr-accept-mobile-payments"
      cldImg={cld.image("components/we-are-animation/lpr-accept-mobile-payments").resize(scale().width(400)).format("auto")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Linea Pro Rugged NFC accept mobile payments"
    />
  </div>;
}


export default Scene6;