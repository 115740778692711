import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PageContext } from '../../context/PageState';

type Props = {
  subfooter: {
    type: string | undefined;
    heading?: string | undefined;
    subheading?: string | undefined;
    ctaBtn?: string | undefined;
    formUrl?: string | undefined;
  } | undefined;
}

const SubFooter: React.FC<Props> = (props) => {

  const [state, setState] = useState<{[key: string]: any}>({});
  const { modalForm } = React.useContext(PageContext);
  let content;

  useEffect(() => {
    if (props.subfooter) {
      setState({
        subfooter: props.subfooter
      });
    }
  }, [props.subfooter]);

  const defaultSubfooter = () => <div className="row">
    <div className="col-12 have-questions text-center">
      <div className="content">
        <h3>
          Speak with our team.
        </h3>
        <p>
          Let's find the right solution
          <span className='d-block d-lg-inline-block'>
            <span className='d-none d-lg-inline-block'>
              &nbsp;
            </span>
          </span>
          for your enterprise business.
        </p>
        <button
          id="subfooter-contact-us-button"
          data-toggle="modal"
          data-target="#iframeModal"
          className="btn btn-primary"
          onClick={() => {
            modalForm.toggle();
          }}
        >
          Contact Sales
        </button>
      </div>
    </div>
  </div>;

  if (state.subfooter) {
    switch (state.subfooter.type) {
      case 'support':
        content = <div className="row">
          <div className="col-12 text-center have-questions single">
            <div className="content">
              <h3>
                Need help with existing hardware or software?
              </h3>
              <p>
                Submit a ticket and an IPCMobile representative will contact you
                shortly.
              </p>
              <Link
                to="/support/support-form"
                id="subfooter-contact-us-button"
                className="btn btn-primary">
                Contact Support
              </Link>
            </div>
          </div>
        </div>
        break;
      case 'custom':
        content = <div className="row">
          <div className="col-12 text-center have-questions single">
            <div className="content">
              <h3>
                {state.subfooter.heading && state.subfooter.heading}
              </h3>
              <p>
                {state.subfooter.subheading && state.subfooter.subheading}
              </p>
              {state.subfooter.ctaBtn && <button
                data-toggle="modal"
                data-target="#iframeModal"
                className="btn btn-primary"
                id="subfooter-contact-us-button"
                onClick={() => {
                  if (state.subfooter.formUrl !== undefined) {
                    modalForm.toggle();
                  }
                }}>
                {state.subfooter.ctaBtn}
              </button>}
            </div>
          </div>
        </div>
        break;
      case 'none':
        return <Fragment/>;
      default:
        content = defaultSubfooter();
    }

  } else {
    content = defaultSubfooter();
  }
  return <Fragment>
    <section className="subfooter">
      <div className="container-fluid">
        {content}
      </div>
    </section>
  </Fragment>;

};

export default SubFooter;
