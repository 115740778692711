import "./index.scss";

const PatentProtectedDisclaimer = () => <section id="patent-protected-disclaimer">
    <div className="container">
        <div className="row">
            <div className="col">
                <hr />
                <p>
                    The content, features, products, and services provided on this website
                    are the subject of a patent, patent applications, and other intellectual
                    property rights owned by or licensed to Infinite Peripherals, Inc. (“IPCMobile”).
                    Intellectual property rights are patent-protected.
                </p>
            </div>
        </div>
    </div>
</section>;

export default PatentProtectedDisclaimer