import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import cld from '../../../../constants/cloudinary';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { scale } from "@cloudinary/url-gen/actions/resize";

import "./index.scss";

/**
 * Scene 9
 * Enterprise Software Experts
 */

export const scene9Timeline = gsap.timeline();

const Scene9 = () => {

  useGSAP(() => scene9Timeline
    .fromTo(".scene-9__gradient-bg", {
      autoAlpha: 0,
    }, {
      autoAlpha: 1,
      duration: 1
    })
    .fromTo(".scene-9__usa-map", {
      autoAlpha: 0,
      transform: "translateY(50%)"
    }, {
      autoAlpha: 1,
      transform: "translateY(0)",
      ease: "power2.out",
      duration: 1
    }, "<.25")
    .fromTo([
      ".scene-9__server-stack-1",
      ".scene-9__server-stack-2",
    ], {
      autoAlpha: 0,
    }, {
      autoAlpha: 1,
      duration: 1
    })
    .fromTo([
      ".scene-9__server-rack-1",
      ".scene-9__server-rack-2",
      ".scene-9__mobile-phone",
      ".scene-9__laptop",
      ".scene-9__user",
      ".scene-9__cloud-data-transfer"
    ], {
      autoAlpha: 0,
    }, {
      autoAlpha: 1,
      duration: 1
    }, "<.25")
    .fromTo([
      ".scene9__light-cone",
      ".scene9__data-line"
    ], {
      autoAlpha: 0,
    }, {
      autoAlpha: 1,
      ease: "power2.out",
      duration: 1
    })
    .fromTo([
      ".scene-9__data-stream-1",
      ".scene-9__data-stream-2",
      ".scene-9__data-stream-3",
      ".scene-9__data-stream-4",
    ], {
      autoAlpha: 0,
      transform: "translate(-50%, 50%)"
    }, {
      autoAlpha: 1,
      transform: "translate(0, 0)",
      ease: "power2.out",
      duration: 1
    }, "<.5")
    // Cleanup
    .to([
      ".scene-9__gradient-bg",
      ".scene-9__usa-map",
      ".scene-9__data-stream-1",
      ".scene-9__data-stream-2",
      ".scene-9__data-stream-3",
      ".scene-9__data-stream-4",
      ".scene-9__server-stack-1",
      ".scene-9__server-stack-2",
      ".scene-9__server-rack-1",
      ".scene-9__server-rack-2",
      ".scene-9__mobile-phone",
      ".scene-9__laptop",
      ".scene-9__user",
      ".scene-9__cloud-data-transfer"
    ], {
      autoAlpha: 0,
      duration: 1,
      delay: 1.2
    })
  );

  return <div className="scenes scenes__scene-9">
    <div className="scene-9__gradient-bg"></div>
    <svg className="scene-9__usa-map" viewBox="0 0 621 309" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="paint0_linear_949_4949" x1="159.176" y1="42.1721" x2="153.592" y2="90.8608" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint1_linear_949_4949" x1="219.839" y1="132.399" x2="214.255" y2="181.088" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint2_linear_949_4949" x1="248.775" y1="57.1569" x2="243.192" y2="105.838" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint3_linear_949_4949" x1="311.31" y1="111.904" x2="305.727" y2="160.585" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint4_linear_949_4949" x1="288.3" y1="165.893" x2="282.717" y2="214.582" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint5_linear_949_4949" x1="354.459" y1="144.483" x2="348.883" y2="193.172" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint6_linear_949_4949" x1="453.826" y1="104.074" x2="448.25" y2="152.763" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint7_linear_949_4949" x1="474.701" y1="193.399" x2="469.117" y2="242.087" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint8_linear_949_4949" x1="503.289" y1="5.99227" x2="497.714" y2="54.6737" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint9_linear_949_4949" x1="195.912" y1="98.1128" x2="194.743" y2="121.746" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint10_linear_949_4949" x1="183.009" y1="100.666" x2="178.393" y2="193.398" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint11_linear_949_4949" x1="313.898" y1="198.677" x2="312.422" y2="228.406" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint12_linear_949_4949" x1="226.94" y1="111.579" x2="224.036" y2="169.836" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint13_linear_949_4949" x1="247.07" y1="184.842" x2="244.73" y2="231.887" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint14_linear_949_4949" x1="294.217" y1="118.092" x2="289.993" y2="202.904" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint15_linear_949_4949" x1="422.723" y1="59.0028" x2="415.053" y2="212.922" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint16_linear_949_4949" x1="484.149" y1="66.5766" x2="474.596" y2="258.435" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
        <linearGradient id="paint17_linear_949_4949" x1="334.248" y1="99.6057" x2="329.192" y2="201.192" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BFFFF" stopOpacity="0" />
          <stop offset="1" stopColor="#0081AA" />
        </linearGradient>
      </defs>
      <path d="M14.2674 217.109C13.1587 218.758 13.2216 220.094 14.4247 220.094C15.6277 220.094 17.4991 218.766 18.6 217.116C19.7008 215.467 19.6143 214.123 18.427 214.123C17.2397 214.123 15.3761 215.459 14.2674 217.101V217.109Z" fill="#19B8F7" />
      <path d="M7.48946 217.101C6.35717 218.743 6.38863 220.078 7.56809 220.086C8.74755 220.086 10.6111 218.758 11.7277 217.109C12.8442 215.459 12.797 214.123 11.6333 214.123C10.4696 214.123 8.61388 215.459 7.48946 217.101Z" fill="#19B8F7" />
      <path d="M0.860885 217.101C-0.287124 218.743 -0.287124 220.078 0.860885 220.078C2.00889 220.078 3.87244 218.743 5.00473 217.101C6.13701 215.459 6.12915 214.123 4.989 214.123C3.84885 214.123 2.00889 215.459 0.860885 217.101Z" fill="#19B8F7" />
      <path d="M41.4815 207.853C40.42 209.511 40.5773 210.863 41.8432 210.87C43.1013 210.878 44.9806 209.543 46.0264 207.885C47.0722 206.227 46.8992 204.876 45.6568 204.868C44.4066 204.86 42.543 206.196 41.4815 207.853Z" fill="#19B8F7" />
      <path d="M34.3576 207.814C33.2804 209.472 33.4062 210.815 34.6407 210.823C35.8752 210.823 37.7466 209.496 38.816 207.838C39.8775 206.18 39.7438 204.829 38.5251 204.829C37.2984 204.829 35.4427 206.164 34.3655 207.814H34.3576Z" fill="#19B8F7" />
      <path d="M27.383 207.791C26.2901 209.441 26.3766 210.784 27.5875 210.792C28.7984 210.792 30.6698 209.456 31.747 207.806C32.8322 206.149 32.7299 204.805 31.5269 204.805C30.3238 204.805 28.476 206.141 27.3752 207.799L27.383 207.791Z" fill="#19B8F7" />
      <path d="M20.5501 207.783C19.4414 209.433 19.4885 210.768 20.6759 210.776C21.8632 210.776 23.7267 209.44 24.8197 207.791C25.9205 206.141 25.8498 204.797 24.6782 204.797C23.5066 204.797 21.6588 206.141 20.5422 207.791L20.5501 207.783Z" fill="#19B8F7" />
      <path d="M47.4732 198.473C46.4117 200.13 46.5454 201.482 47.7877 201.49C49.0301 201.49 50.8937 200.162 51.9473 198.496C53.001 196.831 52.8516 195.479 51.6171 195.472C50.3904 195.472 48.5347 196.807 47.4654 198.465L47.4732 198.473Z" fill="#19B8F7" />
      <path d="M40.4593 198.449C39.3742 200.107 39.4843 201.45 40.7031 201.458C41.9219 201.458 43.7854 200.123 44.8548 198.465C45.9242 196.807 45.8062 195.456 44.6032 195.456C43.4001 195.456 41.5444 196.799 40.4672 198.457L40.4593 198.449Z" fill="#19B8F7" />
      <path d="M33.5792 198.433C32.4783 200.091 32.557 201.435 33.7521 201.435C34.9473 201.435 36.803 200.099 37.8881 198.441C38.9732 196.784 38.8867 195.44 37.7073 195.44C36.52 195.44 34.68 196.784 33.587 198.441L33.5792 198.433Z" fill="#19B8F7" />
      <path d="M26.8327 198.441C25.724 200.091 25.7633 201.435 26.9349 201.435C28.1065 201.435 29.9543 200.091 31.0551 198.441C32.156 196.784 32.1009 195.448 30.945 195.448C29.7813 195.448 27.9492 196.791 26.8405 198.449L26.8327 198.441Z" fill="#19B8F7" />
      <path d="M20.2277 198.465C19.1033 200.115 19.1111 201.45 20.2591 201.442C21.4071 201.442 23.2471 200.099 24.3637 198.449C25.4802 196.799 25.4566 195.464 24.3243 195.464C23.1921 195.464 21.36 196.815 20.2355 198.465H20.2277Z" fill="#19B8F7" />
      <path d="M7.44226 198.551C6.29425 200.193 6.23921 201.521 7.33218 201.513C8.42515 201.513 10.2494 200.17 11.3974 198.52C12.5375 196.87 12.5769 195.55 11.4839 195.558C10.3988 195.566 8.59027 196.909 7.44226 198.551Z" fill="#19B8F7" />
      <path d="M26.5889 189.131C25.4644 190.781 25.4644 192.117 26.5889 192.109C27.7211 192.109 29.5532 190.758 30.6698 189.108C31.7864 187.458 31.7706 186.114 30.6541 186.13C29.5297 186.138 27.7133 187.489 26.5889 189.139V189.131Z" fill="#19B8F7" />
      <path d="M398.379 306.288C398.064 307.553 399.315 308.732 401.178 308.936C403.042 309.14 404.819 308.308 405.141 307.043C405.456 305.786 404.198 304.568 402.334 304.356C400.47 304.144 398.701 305.016 398.387 306.281L398.379 306.288Z" fill="#19B8F7" />
      <path d="M344.525 300.844C344.21 302.148 345.468 303.319 347.34 303.491C349.211 303.664 350.98 302.769 351.295 301.472C351.609 300.176 350.351 298.966 348.48 298.793C346.608 298.62 344.839 299.547 344.525 300.852V300.844Z" fill="#19B8F7" />
      <path d="M357.058 294.394C356.752 295.737 358.01 296.955 359.881 297.143C361.753 297.324 363.514 296.413 363.821 295.069C364.127 293.734 362.861 292.477 360.998 292.288C359.134 292.099 357.373 293.05 357.058 294.394Z" fill="#19B8F7" />
      <path d="M346.294 293.357C345.979 294.708 347.237 295.926 349.109 296.106C350.98 296.287 352.742 295.352 353.056 294.009C353.363 292.665 352.105 291.416 350.241 291.227C348.377 291.047 346.608 292.005 346.302 293.357H346.294Z" fill="#19B8F7" />
      <path d="M335.537 292.351C335.223 293.71 336.481 294.928 338.344 295.101C340.208 295.274 341.977 294.331 342.291 292.979C342.606 291.628 341.348 290.379 339.484 290.206C337.621 290.033 335.852 291 335.537 292.359V292.351Z" fill="#19B8F7" />
      <path d="M324.78 291.392C324.458 292.759 325.716 293.977 327.58 294.142C329.443 294.307 331.212 293.349 331.535 291.989C331.849 290.63 330.599 289.381 328.736 289.216C326.872 289.051 325.103 290.033 324.78 291.392Z" fill="#19B8F7" />
      <path d="M314.039 290.473C313.717 291.84 314.967 293.066 316.831 293.223C318.694 293.38 320.471 292.414 320.786 291.047C321.108 289.68 319.858 288.43 317.995 288.265C316.131 288.108 314.362 289.098 314.032 290.473H314.039Z" fill="#19B8F7" />
      <path d="M303.299 289.593C302.968 290.968 304.211 292.194 306.074 292.343C307.938 292.492 309.715 291.518 310.037 290.143C310.367 288.768 309.125 287.519 307.262 287.362C305.406 287.205 303.629 288.218 303.299 289.593Z" fill="#19B8F7" />
      <path d="M369.537 287.81C369.238 289.193 370.504 290.457 372.376 290.654C374.239 290.85 375.993 289.915 376.291 288.54C376.59 287.166 375.309 285.869 373.453 285.673C371.589 285.468 369.844 286.435 369.545 287.818L369.537 287.81Z" fill="#19B8F7" />
      <path d="M348.055 285.602C347.748 286.993 349.006 288.258 350.87 288.438C352.734 288.619 354.495 287.661 354.802 286.27C355.108 284.879 353.842 283.591 351.979 283.402C350.115 283.214 348.362 284.204 348.055 285.594V285.602Z" fill="#19B8F7" />
      <path d="M337.322 284.565C337.007 285.964 338.266 287.228 340.129 287.401C341.993 287.574 343.754 286.608 344.069 285.209C344.375 283.819 343.125 282.522 341.261 282.342C339.406 282.161 337.644 283.159 337.33 284.557L337.322 284.565Z" fill="#19B8F7" />
      <path d="M326.597 283.567C326.274 284.974 327.525 286.238 329.388 286.403C331.252 286.576 333.021 285.586 333.335 284.188C333.65 282.789 332.4 281.493 330.544 281.32C328.688 281.147 326.919 282.161 326.597 283.567Z" fill="#19B8F7" />
      <path d="M315.879 282.609C315.549 284.023 316.792 285.288 318.655 285.445C320.519 285.61 322.288 284.612 322.61 283.206C322.933 281.8 321.69 280.503 319.835 280.338C317.979 280.173 316.21 281.195 315.879 282.609Z" fill="#19B8F7" />
      <path d="M305.186 281.689C304.848 283.112 306.082 284.369 307.938 284.526C309.793 284.683 311.57 283.677 311.901 282.263C312.231 280.849 310.996 279.56 309.149 279.395C307.293 279.238 305.524 280.267 305.186 281.689Z" fill="#19B8F7" />
      <path d="M294.5 280.81C294.154 282.24 295.38 283.497 297.236 283.646C299.092 283.795 300.869 282.774 301.207 281.352C301.545 279.93 300.318 278.641 298.471 278.484C296.623 278.327 294.846 279.38 294.5 280.802V280.81Z" fill="#19B8F7" />
      <path d="M283.845 279.969C283.491 281.399 284.71 282.664 286.558 282.805C288.414 282.947 290.191 281.917 290.537 280.488C290.883 279.058 289.672 277.769 287.824 277.62C285.976 277.471 284.199 278.531 283.845 279.961V279.969Z" fill="#19B8F7" />
      <path d="M273.206 279.168C272.845 280.605 274.048 281.87 275.896 282.004C277.743 282.137 279.528 281.1 279.882 279.663C280.236 278.225 279.041 276.936 277.201 276.795C275.361 276.654 273.576 277.722 273.206 279.16V279.168Z" fill="#19B8F7" />
      <path d="M262.599 278.413C262.222 279.859 263.417 281.124 265.257 281.25C267.097 281.375 268.89 280.322 269.259 278.885C269.629 277.447 268.441 276.151 266.601 276.017C264.769 275.884 262.969 276.96 262.599 278.405V278.413Z" fill="#19B8F7" />
      <path d="M252.015 277.691C251.63 279.144 252.81 280.409 254.642 280.527C256.482 280.645 258.274 279.584 258.652 278.138C259.029 276.693 257.858 275.396 256.026 275.271C254.194 275.145 252.401 276.237 252.008 277.691H252.015Z" fill="#19B8F7" />
      <path d="M435.485 287.708C435.233 289.098 436.538 290.403 438.394 290.654C440.258 290.905 441.964 289.994 442.208 288.611C442.451 287.228 441.138 285.893 439.282 285.641C437.427 285.39 435.728 286.325 435.485 287.708Z" fill="#19B8F7" />
      <path d="M414.058 284.934C413.798 286.333 415.088 287.637 416.951 287.873C418.815 288.108 420.537 287.181 420.789 285.783C421.048 284.392 419.743 283.057 417.887 282.813C416.031 282.577 414.317 283.528 414.058 284.926V284.934Z" fill="#19B8F7" />
      <path d="M403.341 283.614C403.073 285.021 404.363 286.325 406.218 286.553C408.082 286.781 409.804 285.846 410.071 284.447C410.331 283.049 409.033 281.713 407.178 281.485C405.322 281.257 403.608 282.216 403.341 283.622V283.614Z" fill="#19B8F7" />
      <path d="M392.623 282.334C392.348 283.74 393.63 285.052 395.493 285.264C397.357 285.484 399.087 284.534 399.354 283.135C399.621 281.729 398.332 280.401 396.476 280.173C394.62 279.953 392.898 280.927 392.623 282.334Z" fill="#19B8F7" />
      <path d="M371.196 279.89C370.905 281.312 372.179 282.617 374.043 282.821C375.906 283.025 377.644 282.059 377.927 280.637C378.21 279.223 376.928 277.887 375.073 277.683C373.217 277.471 371.479 278.468 371.196 279.89Z" fill="#19B8F7" />
      <path d="M339.091 276.543C338.777 277.981 340.027 279.285 341.89 279.466C343.746 279.647 345.5 278.649 345.806 277.211C346.113 275.773 344.855 274.446 343.007 274.257C341.159 274.069 339.406 275.098 339.091 276.536V276.543Z" fill="#19B8F7" />
      <path d="M328.405 275.506C328.083 276.952 329.325 278.256 331.181 278.429C333.037 278.602 334.798 277.596 335.112 276.151C335.427 274.713 334.177 273.385 332.329 273.204C330.481 273.024 328.72 274.061 328.405 275.506Z" fill="#19B8F7" />
      <path d="M285.866 271.759C285.504 273.228 286.707 274.532 288.547 274.681C290.387 274.831 292.172 273.778 292.526 272.309C292.88 270.84 291.677 269.52 289.845 269.363C288.013 269.213 286.228 270.29 285.866 271.759Z" fill="#19B8F7" />
      <path d="M275.29 270.926C274.913 272.403 276.1 273.699 277.94 273.841C279.78 273.982 281.565 272.914 281.927 271.444C282.288 269.975 281.109 268.648 279.277 268.498C277.445 268.349 275.66 269.441 275.29 270.918V270.926Z" fill="#19B8F7" />
      <path d="M264.754 270.125C264.368 271.609 265.54 272.906 267.372 273.039C269.204 273.173 270.997 272.097 271.374 270.62C271.752 269.143 270.58 267.815 268.764 267.681C266.94 267.54 265.147 268.64 264.761 270.125H264.754Z" fill="#19B8F7" />
      <path d="M254.249 269.37C253.848 270.863 255.004 272.159 256.828 272.285C258.652 272.411 260.453 271.327 260.838 269.842C261.231 268.357 260.075 267.037 258.259 266.903C256.442 266.77 254.642 267.885 254.241 269.37H254.249Z" fill="#19B8F7" />
      <path d="M458.138 282.75C457.934 284.164 459.255 285.516 461.11 285.783C462.958 286.05 464.633 285.139 464.83 283.724C465.026 282.31 463.689 280.935 461.842 280.66C459.994 280.393 458.343 281.328 458.138 282.75Z" fill="#19B8F7" />
      <path d="M447.484 281.234C447.271 282.656 448.592 284.007 450.44 284.267C452.296 284.526 453.971 283.599 454.175 282.185C454.38 280.77 453.051 279.395 451.203 279.128C449.355 278.861 447.696 279.82 447.484 281.234Z" fill="#19B8F7" />
      <path d="M415.481 276.928C415.237 278.366 416.543 279.71 418.398 279.945C420.254 280.181 421.952 279.23 422.188 277.801C422.424 276.371 421.111 274.996 419.263 274.752C417.415 274.509 415.725 275.491 415.489 276.928H415.481Z" fill="#19B8F7" />
      <path d="M404.803 275.577C404.551 277.023 405.849 278.366 407.704 278.594C409.56 278.822 411.266 277.863 411.51 276.426C411.754 274.988 410.449 273.621 408.601 273.385C406.753 273.149 405.055 274.139 404.811 275.585L404.803 275.577Z" fill="#19B8F7" />
      <path d="M383.463 272.984C383.195 274.438 384.477 275.781 386.333 275.994C388.188 276.214 389.91 275.224 390.17 273.778C390.429 272.332 389.14 270.965 387.292 270.737C385.444 270.517 383.738 271.523 383.463 272.977V272.984Z" fill="#19B8F7" />
      <path d="M362.13 270.565C361.847 272.026 363.105 273.369 364.961 273.574C366.816 273.778 368.546 272.772 368.822 271.311C369.105 269.85 367.831 268.483 365.983 268.278C364.135 268.074 362.413 269.103 362.122 270.565H362.13Z" fill="#19B8F7" />
      <path d="M319.606 266.196C319.276 267.681 320.503 269.017 322.343 269.198C324.183 269.37 325.944 268.326 326.266 266.841C326.589 265.356 325.362 263.997 323.53 263.816C321.698 263.635 319.945 264.711 319.614 266.196H319.606Z" fill="#19B8F7" />
      <path d="M287.934 263.352C287.564 264.861 288.752 266.189 290.584 266.346C292.416 266.503 294.193 265.419 294.547 263.918C294.909 262.417 293.721 261.058 291.897 260.901C290.081 260.744 288.304 261.844 287.934 263.352Z" fill="#19B8F7" />
      <path d="M277.429 262.48C277.044 263.989 278.215 265.324 280.039 265.474C281.864 265.623 283.649 264.531 284.018 263.022C284.388 261.514 283.224 260.163 281.408 260.005C279.591 259.856 277.814 260.964 277.429 262.48Z" fill="#19B8F7" />
      <path d="M266.971 261.647C266.57 263.164 267.726 264.499 269.542 264.633C271.359 264.774 273.151 263.674 273.537 262.158C273.922 260.65 272.774 259.29 270.966 259.149C269.157 259.008 267.372 260.131 266.971 261.639V261.647Z" fill="#19B8F7" />
      <path d="M256.545 260.854C256.128 262.378 257.268 263.706 259.077 263.839C260.885 263.973 262.686 262.865 263.087 261.341C263.488 259.825 262.355 258.473 260.555 258.332C258.754 258.191 256.961 259.33 256.545 260.846V260.854Z" fill="#19B8F7" />
      <path d="M225.556 258.717C225.092 260.257 226.17 261.585 227.955 261.695C229.739 261.805 231.556 260.665 232.004 259.133C232.452 257.601 231.383 256.25 229.606 256.132C227.829 256.014 226.012 257.177 225.548 258.717H225.556Z" fill="#19B8F7" />
      <path d="M384.933 264.672C384.681 266.157 385.963 267.532 387.811 267.76C389.659 267.98 391.357 266.974 391.601 265.489C391.845 264.004 390.555 262.606 388.715 262.378C386.875 262.15 385.185 263.187 384.933 264.672Z" fill="#19B8F7" />
      <path d="M374.326 263.407C374.058 264.9 375.332 266.275 377.18 266.487C379.02 266.699 380.734 265.686 380.986 264.193C381.245 262.7 379.956 261.302 378.123 261.09C376.291 260.87 374.593 261.914 374.326 263.407Z" fill="#19B8F7" />
      <path d="M363.726 262.182C363.451 263.682 364.709 265.049 366.557 265.261C368.397 265.474 370.111 264.436 370.386 262.944C370.654 261.451 369.38 260.053 367.548 259.84C365.716 259.628 364.009 260.681 363.726 262.182Z" fill="#19B8F7" />
      <path d="M290.026 254.749C289.648 256.289 290.82 257.648 292.636 257.813C294.452 257.97 296.222 256.871 296.583 255.339C296.945 253.807 295.773 252.424 293.973 252.259C292.164 252.094 290.403 253.217 290.026 254.757V254.749Z" fill="#19B8F7" />
      <path d="M279.615 253.854C279.222 255.394 280.378 256.761 282.186 256.91C283.995 257.059 285.772 255.951 286.149 254.412C286.527 252.872 285.379 251.489 283.578 251.332C281.777 251.175 280.008 252.306 279.615 253.846V253.854Z" fill="#19B8F7" />
      <path d="M269.236 252.989C268.827 254.537 269.959 255.896 271.768 256.046C273.568 256.195 275.353 255.071 275.746 253.532C276.139 251.992 275.007 250.609 273.214 250.46C271.422 250.31 269.637 251.45 269.236 252.997V252.989Z" fill="#19B8F7" />
      <path d="M258.911 252.172C258.487 253.72 259.603 255.087 261.396 255.221C263.189 255.362 264.982 254.231 265.391 252.683C265.799 251.135 264.691 249.753 262.906 249.611C261.121 249.47 259.328 250.617 258.911 252.172Z" fill="#19B8F7" />
      <path d="M248.627 251.395C248.186 252.95 249.279 254.309 251.064 254.443C252.849 254.576 254.65 253.437 255.074 251.882C255.499 250.326 254.414 248.951 252.637 248.818C250.86 248.684 249.067 249.839 248.619 251.395H248.627Z" fill="#19B8F7" />
      <path d="M238.397 250.648C237.933 252.212 239.01 253.563 240.787 253.689C242.564 253.814 244.373 252.667 244.813 251.104C245.261 249.548 244.192 248.173 242.423 248.04C240.653 247.914 238.853 249.077 238.389 250.64L238.397 250.648Z" fill="#19B8F7" />
      <path d="M481.146 269.598C481.028 271.075 482.396 272.497 484.228 272.788C486.052 273.079 487.633 272.128 487.743 270.659C487.853 269.182 486.461 267.744 484.645 267.453C482.828 267.163 481.271 268.129 481.153 269.606L481.146 269.598Z" fill="#19B8F7" />
      <path d="M470.64 267.948C470.507 269.433 471.875 270.847 473.699 271.13C475.523 271.413 477.12 270.455 477.238 268.978C477.363 267.501 475.98 266.063 474.163 265.772C472.347 265.489 470.774 266.463 470.64 267.948Z" fill="#19B8F7" />
      <path d="M460.12 266.338C459.97 267.823 461.331 269.237 463.155 269.52C464.987 269.795 466.591 268.828 466.725 267.351C466.858 265.866 465.49 264.429 463.674 264.154C461.857 263.871 460.269 264.861 460.12 266.346V266.338Z" fill="#19B8F7" />
      <path d="M449.591 264.767C449.434 266.259 450.778 267.673 452.611 267.941C454.443 268.208 456.055 267.233 456.204 265.749C456.353 264.264 454.993 262.826 453.169 262.551C451.345 262.276 449.748 263.274 449.591 264.767Z" fill="#19B8F7" />
      <path d="M386.325 256.171C386.089 257.688 387.371 259.094 389.203 259.322C391.035 259.55 392.71 258.521 392.945 257.004C393.173 255.488 391.876 254.058 390.052 253.83C388.228 253.594 386.561 254.655 386.325 256.171Z" fill="#19B8F7" />
      <path d="M375.788 254.875C375.537 256.399 376.81 257.806 378.642 258.026C380.475 258.246 382.165 257.201 382.401 255.684C382.645 254.16 381.363 252.738 379.539 252.51C377.715 252.282 376.04 253.351 375.788 254.875Z" fill="#19B8F7" />
      <path d="M365.26 253.626C364.992 255.158 366.258 256.556 368.082 256.776C369.914 256.988 371.605 255.936 371.864 254.412C372.124 252.887 370.85 251.457 369.034 251.245C367.217 251.025 365.527 252.102 365.26 253.634V253.626Z" fill="#19B8F7" />
      <path d="M292.149 245.989C291.771 247.553 292.927 248.943 294.728 249.108C296.528 249.273 298.29 248.15 298.651 246.594C299.013 245.031 297.857 243.624 296.072 243.452C294.287 243.287 292.526 244.426 292.149 245.989Z" fill="#19B8F7" />
      <path d="M281.832 245.062C281.439 246.634 282.571 248.024 284.364 248.181C286.157 248.338 287.926 247.207 288.311 245.644C288.697 244.08 287.557 242.666 285.78 242.509C283.995 242.344 282.233 243.499 281.84 245.07L281.832 245.062Z" fill="#19B8F7" />
      <path d="M271.555 244.174C271.139 245.746 272.255 247.136 274.04 247.286C275.825 247.435 277.602 246.296 278.003 244.724C278.404 243.153 277.287 241.747 275.518 241.597C273.741 241.44 271.972 242.603 271.555 244.174Z" fill="#19B8F7" />
      <path d="M261.325 243.326C260.893 244.905 261.986 246.288 263.763 246.429C265.54 246.571 267.325 245.424 267.742 243.852C268.158 242.281 267.066 240.875 265.304 240.725C263.535 240.576 261.758 241.747 261.325 243.326Z" fill="#19B8F7" />
      <path d="M251.151 242.524C250.695 244.104 251.764 245.486 253.541 245.628C255.31 245.761 257.103 244.606 257.543 243.027C257.984 241.448 256.914 240.05 255.153 239.908C253.399 239.767 251.607 240.945 251.151 242.524Z" fill="#19B8F7" />
      <path d="M241.023 241.755C240.551 243.342 241.597 244.724 243.358 244.85C245.12 244.984 246.92 243.813 247.376 242.226C247.832 240.647 246.787 239.241 245.041 239.107C243.295 238.973 241.495 240.16 241.023 241.747V241.755Z" fill="#19B8F7" />
      <path d="M230.958 241.024C230.463 242.611 231.485 243.994 233.238 244.119C234.992 244.245 236.793 243.067 237.272 241.48C237.752 239.893 236.73 238.494 234.992 238.368C233.254 238.243 231.454 239.437 230.958 241.024Z" fill="#19B8F7" />
      <path d="M211.033 239.688C210.498 241.283 211.466 242.658 213.195 242.776C214.925 242.886 216.742 241.692 217.261 240.097C217.78 238.502 216.812 237.111 215.098 236.994C213.384 236.884 211.568 238.093 211.033 239.688Z" fill="#19B8F7" />
      <path d="M492.138 262.873C492.067 264.374 493.467 265.827 495.275 266.126C497.084 266.424 498.609 265.466 498.664 263.965C498.727 262.465 497.304 260.995 495.511 260.689C493.719 260.383 492.209 261.365 492.138 262.873Z" fill="#19B8F7" />
      <path d="M481.727 261.168C481.641 262.677 483.033 264.122 484.841 264.421C486.65 264.711 488.191 263.745 488.269 262.237C488.348 260.736 486.941 259.267 485.14 258.968C483.339 258.67 481.822 259.66 481.727 261.168Z" fill="#19B8F7" />
      <path d="M471.301 259.495C471.199 261.003 472.575 262.457 474.391 262.739C476.208 263.03 477.757 262.048 477.851 260.54C477.945 259.031 476.546 257.562 474.745 257.271C472.945 256.981 471.403 257.978 471.301 259.495Z" fill="#19B8F7" />
      <path d="M460.867 257.861C460.749 259.377 462.117 260.823 463.933 261.105C465.75 261.388 467.314 260.398 467.425 258.89C467.535 257.381 466.143 255.912 464.342 255.629C462.542 255.346 460.985 256.352 460.867 257.868V257.861Z" fill="#19B8F7" />
      <path d="M377.18 246.178C376.936 247.733 378.218 249.163 380.034 249.391C381.851 249.619 383.518 248.558 383.746 247.003C383.974 245.455 382.692 244.002 380.884 243.774C379.075 243.546 377.424 244.63 377.18 246.178Z" fill="#19B8F7" />
      <path d="M366.738 244.897C366.478 246.453 367.744 247.883 369.561 248.103C371.377 248.323 373.052 247.246 373.296 245.699C373.539 244.143 372.273 242.697 370.465 242.47C368.656 242.25 366.997 243.342 366.738 244.897Z" fill="#19B8F7" />
      <path d="M314.841 239.107C314.496 240.686 315.675 242.108 317.476 242.289C319.276 242.47 320.998 241.354 321.329 239.775C321.659 238.196 320.479 236.758 318.694 236.569C316.909 236.381 315.187 237.52 314.849 239.099L314.841 239.107Z" fill="#19B8F7" />
      <path d="M304.541 238.07C304.179 239.657 305.335 241.071 307.128 241.252C308.921 241.425 310.65 240.301 311.004 238.714C311.35 237.135 310.194 235.697 308.409 235.516C306.632 235.336 304.903 236.483 304.541 238.07Z" fill="#19B8F7" />
      <path d="M294.287 237.08C293.91 238.667 295.042 240.081 296.827 240.254C298.612 240.427 300.358 239.288 300.719 237.701C301.089 236.114 299.949 234.684 298.172 234.511C296.403 234.338 294.657 235.493 294.28 237.08H294.287Z" fill="#19B8F7" />
      <path d="M284.073 236.129C283.672 237.724 284.789 239.138 286.566 239.295C288.343 239.46 290.096 238.313 290.482 236.718C290.867 235.131 289.75 233.702 287.989 233.537C286.228 233.372 284.474 234.534 284.073 236.129Z" fill="#19B8F7" />
      <path d="M273.906 235.218C273.489 236.813 274.582 238.227 276.352 238.384C278.121 238.541 279.882 237.378 280.291 235.791C280.692 234.196 279.599 232.767 277.846 232.609C276.092 232.452 274.331 233.623 273.906 235.226V235.218Z" fill="#19B8F7" />
      <path d="M263.787 234.346C263.346 235.949 264.416 237.355 266.177 237.504C267.938 237.653 269.707 236.483 270.132 234.888C270.557 233.293 269.487 231.863 267.742 231.714C265.996 231.565 264.227 232.743 263.787 234.346Z" fill="#19B8F7" />
      <path d="M253.722 233.521C253.258 235.124 254.304 236.53 256.057 236.671C257.811 236.813 259.588 235.634 260.036 234.039C260.484 232.437 259.43 231.015 257.7 230.873C255.963 230.732 254.186 231.918 253.722 233.521Z" fill="#19B8F7" />
      <path d="M243.712 232.727C243.232 234.338 244.247 235.736 245.992 235.87C247.73 236.004 249.523 234.817 249.987 233.214C250.451 231.612 249.429 230.19 247.707 230.056C245.985 229.923 244.192 231.117 243.712 232.727Z" fill="#19B8F7" />
      <path d="M233.773 231.981C233.27 233.592 234.261 234.99 235.998 235.124C237.728 235.249 239.529 234.055 240.016 232.452C240.504 230.842 239.505 229.428 237.791 229.294C236.077 229.16 234.276 230.37 233.773 231.981Z" fill="#19B8F7" />
      <path d="M223.889 231.266C223.362 232.877 224.33 234.283 226.052 234.401C227.774 234.519 229.574 233.317 230.085 231.706C230.596 230.095 229.629 228.681 227.923 228.556C226.217 228.43 224.416 229.648 223.889 231.266Z" fill="#19B8F7" />
      <path d="M214.084 230.598C213.534 232.217 214.477 233.615 216.183 233.725C217.89 233.835 219.706 232.633 220.241 231.015C220.775 229.396 219.832 227.99 218.141 227.872C216.451 227.754 214.634 228.98 214.092 230.598H214.084Z" fill="#19B8F7" />
      <path d="M492.445 254.302C492.406 255.834 493.821 257.311 495.614 257.609C497.406 257.916 498.893 256.933 498.916 255.409C498.94 253.885 497.509 252.385 495.732 252.078C493.955 251.772 492.484 252.77 492.445 254.302Z" fill="#19B8F7" />
      <path d="M482.128 252.573C482.073 254.105 483.473 255.582 485.274 255.881C487.066 256.179 488.568 255.189 488.615 253.657C488.663 252.125 487.239 250.633 485.455 250.334C483.67 250.035 482.191 251.041 482.136 252.573H482.128Z" fill="#19B8F7" />
      <path d="M461.449 249.218C461.362 250.758 462.738 252.235 464.539 252.518C466.339 252.801 467.873 251.795 467.951 250.255C468.03 248.715 466.63 247.231 464.845 246.94C463.061 246.649 461.543 247.678 461.449 249.218Z" fill="#19B8F7" />
      <path d="M368.153 236.027C367.909 237.606 369.167 239.06 370.968 239.288C372.769 239.508 374.42 238.423 374.648 236.844C374.876 235.265 373.61 233.796 371.825 233.568C370.04 233.34 368.397 234.448 368.153 236.027Z" fill="#19B8F7" />
      <path d="M357.829 234.762C357.569 236.349 358.804 237.803 360.605 238.015C362.405 238.227 364.064 237.135 364.316 235.548C364.568 233.969 363.317 232.492 361.532 232.28C359.747 232.06 358.096 233.175 357.829 234.762Z" fill="#19B8F7" />
      <path d="M347.52 233.537C347.237 235.124 348.464 236.577 350.257 236.789C352.05 237.001 353.724 235.894 353.992 234.307C354.259 232.719 353.032 231.25 351.248 231.038C349.471 230.826 347.804 231.95 347.52 233.544V233.537Z" fill="#19B8F7" />
      <path d="M337.235 232.35C336.937 233.945 338.14 235.391 339.925 235.595C341.717 235.799 343.4 234.676 343.691 233.089C343.974 231.502 342.771 230.033 340.994 229.828C339.217 229.624 337.542 230.755 337.235 232.35Z" fill="#19B8F7" />
      <path d="M326.982 231.211C326.66 232.806 327.847 234.251 329.632 234.448C331.417 234.644 333.115 233.513 333.422 231.918C333.729 230.323 332.541 228.862 330.764 228.665C328.995 228.469 327.305 229.608 326.982 231.211Z" fill="#19B8F7" />
      <path d="M316.76 230.111C316.422 231.714 317.586 233.16 319.363 233.34C321.14 233.529 322.854 232.39 323.176 230.795C323.499 229.192 322.335 227.739 320.566 227.55C318.804 227.361 317.098 228.508 316.76 230.111Z" fill="#19B8F7" />
      <path d="M306.577 229.058C306.216 230.661 307.356 232.107 309.133 232.287C310.902 232.468 312.624 231.321 312.97 229.718C313.316 228.116 312.168 226.662 310.415 226.474C308.653 226.293 306.939 227.448 306.585 229.058H306.577Z" fill="#19B8F7" />
      <path d="M296.434 228.045C296.049 229.656 297.173 231.093 298.942 231.266C300.712 231.439 302.441 230.284 302.803 228.681C303.173 227.071 302.04 225.625 300.295 225.444C298.541 225.272 296.819 226.434 296.434 228.045Z" fill="#19B8F7" />
      <path d="M286.338 227.071C285.937 228.681 287.03 230.119 288.791 230.284C290.552 230.449 292.29 229.286 292.675 227.676C293.061 226.065 291.96 224.619 290.222 224.447C288.477 224.282 286.747 225.46 286.338 227.071Z" fill="#19B8F7" />
      <path d="M276.281 226.136C275.856 227.754 276.934 229.184 278.679 229.341C280.433 229.498 282.178 228.328 282.587 226.717C282.996 225.107 281.919 223.653 280.181 223.496C278.451 223.331 276.698 224.525 276.273 226.136H276.281Z" fill="#19B8F7" />
      <path d="M266.287 225.248C265.839 226.866 266.893 228.296 268.63 228.446C270.368 228.595 272.129 227.416 272.562 225.798C272.994 224.179 271.941 222.734 270.219 222.585C268.497 222.427 266.735 223.622 266.287 225.248Z" fill="#19B8F7" />
      <path d="M256.34 224.399C255.868 226.026 256.898 227.448 258.628 227.589C260.358 227.731 262.127 226.544 262.583 224.926C263.032 223.307 262.009 221.862 260.295 221.72C258.581 221.571 256.812 222.773 256.348 224.399H256.34Z" fill="#19B8F7" />
      <path d="M246.456 223.59C245.969 225.217 246.96 226.639 248.682 226.78C250.404 226.914 252.181 225.719 252.652 224.093C253.124 222.467 252.126 221.029 250.427 220.895C248.721 220.754 246.944 221.964 246.456 223.59Z" fill="#19B8F7" />
      <path d="M236.635 222.82C236.124 224.447 237.091 225.869 238.798 226.002C240.504 226.136 242.297 224.926 242.792 223.3C243.287 221.673 242.32 220.235 240.622 220.11C238.931 219.976 237.139 221.194 236.627 222.828L236.635 222.82Z" fill="#19B8F7" />
      <path d="M226.885 222.097C226.35 223.732 227.286 225.146 228.984 225.271C230.683 225.397 232.476 224.179 232.995 222.553C233.514 220.927 232.57 219.497 230.887 219.371C229.205 219.245 227.412 220.471 226.877 222.105L226.885 222.097Z" fill="#19B8F7" />
      <path d="M217.206 221.406C216.647 223.04 217.559 224.454 219.242 224.572C220.925 224.69 222.733 223.464 223.276 221.838C223.818 220.204 222.906 218.782 221.239 218.656C219.572 218.538 217.764 219.772 217.206 221.406Z" fill="#19B8F7" />
      <path d="M207.605 220.754C207.023 222.388 207.904 223.802 209.578 223.912C211.253 224.022 213.062 222.797 213.628 221.155C214.194 219.521 213.313 218.099 211.654 217.981C210.003 217.871 208.187 219.112 207.605 220.746V220.754Z" fill="#19B8F7" />
      <path d="M198.083 220.149C197.477 221.791 198.326 223.197 199.985 223.299C201.645 223.402 203.469 222.168 204.059 220.526C204.648 218.892 203.799 217.47 202.156 217.36C200.512 217.258 198.696 218.507 198.09 220.141L198.083 220.149Z" fill="#19B8F7" />
      <path d="M188.647 219.576C188.018 221.218 188.836 222.624 190.479 222.718C192.122 222.812 193.955 221.571 194.568 219.929C195.181 218.287 194.363 216.873 192.736 216.771C191.108 216.669 189.284 217.926 188.647 219.568V219.576Z" fill="#19B8F7" />
      <path d="M379.736 228.351C379.523 229.946 380.789 231.431 382.574 231.667C384.359 231.903 385.971 230.81 386.168 229.208C386.364 227.613 385.082 226.12 383.313 225.884C381.544 225.649 379.94 226.756 379.728 228.359L379.736 228.351Z" fill="#19B8F7" />
      <path d="M369.498 227.023C369.262 228.626 370.52 230.103 372.297 230.331C374.082 230.559 375.702 229.451 375.922 227.856C376.142 226.254 374.876 224.769 373.115 224.533C371.345 224.305 369.734 225.421 369.498 227.023Z" fill="#19B8F7" />
      <path d="M359.276 225.735C359.024 227.338 360.259 228.815 362.036 229.035C363.813 229.255 365.456 228.139 365.692 226.536C365.928 224.934 364.685 223.441 362.924 223.221C361.163 223.001 359.527 224.124 359.276 225.735Z" fill="#19B8F7" />
      <path d="M349.085 224.486C348.81 226.096 350.029 227.566 351.798 227.778C353.575 227.99 355.226 226.866 355.486 225.264C355.745 223.653 354.519 222.168 352.765 221.956C351.004 221.736 349.36 222.875 349.085 224.486Z" fill="#19B8F7" />
      <path d="M338.918 223.284C338.627 224.894 339.815 226.364 341.584 226.568C343.353 226.772 345.02 225.641 345.295 224.03C345.57 222.419 344.375 220.935 342.622 220.73C340.868 220.518 339.209 221.665 338.918 223.284Z" fill="#19B8F7" />
      <path d="M328.775 222.121C328.46 223.74 329.632 225.201 331.393 225.405C333.155 225.602 334.837 224.462 335.136 222.852C335.435 221.241 334.255 219.756 332.51 219.56C330.756 219.356 329.089 220.51 328.775 222.129V222.121Z" fill="#19B8F7" />
      <path d="M318.671 221.005C318.333 222.624 319.481 224.085 321.242 224.282C323.003 224.47 324.694 223.323 325.009 221.705C325.331 220.086 324.175 218.609 322.429 218.413C320.684 218.216 319.001 219.379 318.663 221.005H318.671Z" fill="#19B8F7" />
      <path d="M308.606 219.929C308.244 221.555 309.377 223.009 311.13 223.197C312.884 223.378 314.582 222.223 314.928 220.605C315.274 218.986 314.134 217.509 312.404 217.321C310.666 217.132 308.976 218.303 308.614 219.929H308.606Z" fill="#19B8F7" />
      <path d="M298.581 218.892C298.203 220.518 299.304 221.972 301.05 222.152C302.795 222.333 304.509 221.162 304.871 219.536C305.233 217.91 304.124 216.441 302.394 216.26C300.664 216.079 298.958 217.258 298.581 218.892Z" fill="#19B8F7" />
      <path d="M288.61 217.902C288.209 219.536 289.286 220.982 291.024 221.155C292.762 221.328 294.484 220.149 294.869 218.523C295.255 216.896 294.169 215.427 292.455 215.254C290.733 215.082 289.019 216.268 288.61 217.902Z" fill="#19B8F7" />
      <path d="M278.679 216.951C278.255 218.586 279.308 220.031 281.038 220.196C282.768 220.361 284.498 219.175 284.915 217.541C285.323 215.906 284.27 214.445 282.556 214.28C280.842 214.115 279.112 215.309 278.687 216.944L278.679 216.951Z" fill="#19B8F7" />
      <path d="M268.803 216.04C268.355 217.674 269.385 219.12 271.099 219.277C272.821 219.434 274.567 218.24 274.999 216.606C275.432 214.972 274.402 213.51 272.695 213.353C270.997 213.196 269.251 214.398 268.803 216.04Z" fill="#19B8F7" />
      <path d="M258.99 215.176C258.518 216.818 259.517 218.256 261.223 218.405C262.929 218.554 264.691 217.352 265.147 215.718C265.603 214.084 264.596 212.622 262.906 212.473C261.215 212.324 259.462 213.534 258.99 215.176Z" fill="#19B8F7" />
      <path d="M249.24 214.351C248.744 215.993 249.712 217.431 251.41 217.572C253.108 217.713 254.878 216.504 255.357 214.862C255.837 213.219 254.862 211.774 253.179 211.625C251.496 211.483 249.735 212.701 249.24 214.343V214.351Z" fill="#19B8F7" />
      <path d="M239.552 213.565C239.034 215.207 239.977 216.645 241.66 216.779C243.35 216.912 245.127 215.694 245.623 214.052C246.126 212.41 245.182 210.965 243.508 210.831C241.841 210.698 240.064 211.923 239.545 213.565H239.552Z" fill="#19B8F7" />
      <path d="M229.936 212.827C229.393 214.477 230.306 215.906 231.98 216.032C233.655 216.158 235.44 214.932 235.967 213.29C236.494 211.648 235.574 210.203 233.923 210.077C232.263 209.951 230.486 211.185 229.936 212.835V212.827Z" fill="#19B8F7" />
      <path d="M220.398 212.127C219.832 213.777 220.713 215.207 222.372 215.325C224.031 215.443 225.831 214.209 226.382 212.567C226.932 210.918 226.044 209.48 224.4 209.362C222.757 209.244 220.964 210.485 220.398 212.135V212.127Z" fill="#19B8F7" />
      <path d="M210.931 211.46C210.333 213.11 211.19 214.532 212.842 214.649C214.493 214.759 216.294 213.518 216.868 211.876C217.442 210.226 216.584 208.788 214.957 208.678C213.329 208.561 211.528 209.818 210.931 211.468V211.46Z" fill="#19B8F7" />
      <path d="M201.542 210.839C200.921 212.489 201.747 213.911 203.382 214.021C205.018 214.123 206.826 212.882 207.432 211.232C208.029 209.582 207.212 208.152 205.592 208.042C203.98 207.932 202.164 209.189 201.55 210.847L201.542 210.839Z" fill="#19B8F7" />
      <path d="M192.248 210.258C191.603 211.915 192.398 213.33 194.009 213.432C195.629 213.534 197.454 212.277 198.075 210.627C198.704 208.977 197.91 207.547 196.306 207.445C194.701 207.343 192.885 208.608 192.24 210.265L192.248 210.258Z" fill="#19B8F7" />
      <path d="M391.027 220.66C390.854 222.27 392.136 223.771 393.905 224.014C395.674 224.258 397.239 223.158 397.404 221.555C397.569 219.945 396.272 218.436 394.518 218.185C392.765 217.933 391.208 219.049 391.027 220.66Z" fill="#19B8F7" />
      <path d="M380.891 219.269C380.695 220.888 381.961 222.38 383.722 222.624C385.491 222.86 387.072 221.752 387.253 220.141C387.433 218.531 386.16 217.014 384.406 216.779C382.661 216.535 381.08 217.658 380.883 219.277L380.891 219.269Z" fill="#19B8F7" />
      <path d="M370.772 217.918C370.551 219.536 371.802 221.029 373.563 221.265C375.324 221.492 376.928 220.377 377.125 218.766C377.329 217.148 376.071 215.639 374.326 215.411C372.58 215.176 370.992 216.307 370.772 217.926V217.918Z" fill="#19B8F7" />
      <path d="M360.668 216.606C360.424 218.232 361.65 219.717 363.412 219.945C365.173 220.173 366.785 219.041 367.013 217.423C367.241 215.804 366.007 214.296 364.261 214.068C362.515 213.84 360.911 214.979 360.675 216.606H360.668Z" fill="#19B8F7" />
      <path d="M350.595 215.341C350.335 216.967 351.538 218.452 353.292 218.672C355.045 218.892 356.673 217.753 356.925 216.134C357.168 214.508 355.957 213.007 354.22 212.787C352.482 212.567 350.862 213.714 350.603 215.341H350.595Z" fill="#19B8F7" />
      <path d="M340.546 214.115C340.263 215.741 341.442 217.226 343.188 217.438C344.941 217.651 346.577 216.504 346.852 214.877C347.119 213.251 345.932 211.75 344.194 211.538C342.464 211.326 340.829 212.481 340.546 214.107V214.115Z" fill="#19B8F7" />
      <path d="M330.536 212.937C330.23 214.571 331.385 216.048 333.131 216.252C334.877 216.456 336.528 215.301 336.819 213.675C337.11 212.041 335.946 210.548 334.216 210.344C332.486 210.14 330.843 211.302 330.536 212.937Z" fill="#19B8F7" />
      <path d="M320.558 211.797C320.228 213.432 321.36 214.909 323.098 215.105C324.835 215.301 326.502 214.139 326.817 212.505C327.132 210.87 325.991 209.378 324.269 209.181C322.547 208.985 320.888 210.155 320.558 211.79V211.797Z" fill="#19B8F7" />
      <path d="M310.619 210.705C310.265 212.347 311.374 213.817 313.104 214.005C314.834 214.194 316.516 213.023 316.854 211.389C317.193 209.755 316.076 208.262 314.362 208.073C312.648 207.885 310.973 209.063 310.619 210.705Z" fill="#19B8F7" />
      <path d="M300.727 209.653C300.35 211.295 301.435 212.764 303.157 212.944C304.879 213.125 306.577 211.947 306.931 210.305C307.293 208.663 306.2 207.186 304.494 206.997C302.787 206.816 301.105 208.003 300.719 209.645L300.727 209.653Z" fill="#19B8F7" />
      <path d="M290.883 208.639C290.482 210.281 291.543 211.75 293.257 211.923C294.971 212.096 296.678 210.91 297.063 209.268C297.448 207.626 296.379 206.149 294.688 205.976C292.99 205.803 291.292 206.997 290.891 208.639H290.883Z" fill="#19B8F7" />
      <path d="M281.085 207.681C280.661 209.33 281.691 210.792 283.397 210.957C285.103 211.122 286.817 209.928 287.226 208.286C287.635 206.643 286.597 205.166 284.907 205.001C283.216 204.836 281.51 206.039 281.077 207.688L281.085 207.681Z" fill="#19B8F7" />
      <path d="M271.351 206.753C270.903 208.403 271.901 209.865 273.6 210.022C275.298 210.179 277.028 208.977 277.46 207.327C277.893 205.677 276.886 204.208 275.204 204.051C273.529 203.894 271.799 205.104 271.351 206.753Z" fill="#19B8F7" />
      <path d="M261.671 205.874C261.192 207.523 262.175 208.977 263.857 209.134C265.54 209.283 267.286 208.073 267.742 206.424C268.198 204.774 267.215 203.304 265.548 203.155C263.881 203.006 262.143 204.224 261.671 205.874Z" fill="#19B8F7" />
      <path d="M252.055 205.033C251.552 206.691 252.503 208.136 254.178 208.278C255.853 208.419 257.606 207.201 258.086 205.551C258.565 203.902 257.614 202.44 255.963 202.291C254.304 202.15 252.558 203.375 252.055 205.025V205.033Z" fill="#19B8F7" />
      <path d="M242.509 204.239C241.982 205.897 242.902 207.343 244.561 207.476C246.228 207.61 247.99 206.384 248.493 204.734C249.004 203.077 248.076 201.623 246.433 201.482C244.789 201.34 243.028 202.582 242.501 204.239H242.509Z" fill="#19B8F7" />
      <path d="M233.034 203.485C232.484 205.143 233.372 206.589 235.023 206.714C236.675 206.84 238.444 205.607 238.978 203.957C239.513 202.299 238.617 200.845 236.989 200.72C235.354 200.586 233.592 201.835 233.034 203.493V203.485Z" fill="#19B8F7" />
      <path d="M223.63 202.77C223.056 204.428 223.913 205.866 225.548 205.991C227.184 206.109 228.969 204.876 229.527 203.21C230.085 201.553 229.228 200.099 227.608 199.981C225.989 199.855 224.212 201.113 223.63 202.77Z" fill="#19B8F7" />
      <path d="M214.304 202.095C213.699 203.76 214.524 205.19 216.152 205.308C217.78 205.426 219.565 204.177 220.154 202.511C220.736 200.853 219.911 199.408 218.306 199.29C216.702 199.172 214.91 200.429 214.312 202.095H214.304Z" fill="#19B8F7" />
      <path d="M205.065 201.466C204.436 203.132 205.23 204.562 206.842 204.672C208.454 204.782 210.255 203.525 210.86 201.859C211.473 200.193 210.671 198.756 209.083 198.646C207.495 198.536 205.694 199.8 205.065 201.466Z" fill="#19B8F7" />
      <path d="M195.912 200.869C195.26 202.535 196.023 203.957 197.611 204.059C199.207 204.161 201.016 202.896 201.652 201.238C202.289 199.573 201.527 198.143 199.946 198.041C198.374 197.939 196.565 199.211 195.912 200.877V200.869Z" fill="#19B8F7" />
      <path d="M186.838 200.319C186.162 201.985 186.886 203.407 188.466 203.501C190.047 203.595 191.863 202.33 192.523 200.665C193.184 198.999 192.453 197.569 190.896 197.475C189.339 197.381 187.522 198.653 186.838 200.319Z" fill="#19B8F7" />
      <path d="M177.867 199.8C177.159 201.466 177.859 202.888 179.416 202.975C180.98 203.061 182.805 201.788 183.489 200.123C184.173 198.457 183.481 197.027 181.932 196.941C180.391 196.854 178.566 198.135 177.859 199.8H177.867Z" fill="#19B8F7" />
      <path d="M391.97 211.499C391.813 213.125 393.087 214.642 394.84 214.893C396.586 215.144 398.127 214.037 398.269 212.41C398.41 210.784 397.121 209.26 395.391 209.008C393.661 208.757 392.136 209.881 391.97 211.507V211.499Z" fill="#19B8F7" />
      <path d="M381.961 210.093C381.78 211.727 383.038 213.235 384.783 213.479C386.529 213.722 388.086 212.607 388.251 210.973C388.416 209.346 387.142 207.822 385.42 207.579C383.691 207.335 382.149 208.466 381.969 210.093H381.961Z" fill="#19B8F7" />
      <path d="M371.967 208.718C371.762 210.352 372.997 211.86 374.742 212.096C376.48 212.332 378.053 211.208 378.241 209.574C378.43 207.94 377.18 206.424 375.458 206.188C373.736 205.952 372.171 207.091 371.967 208.726V208.718Z" fill="#19B8F7" />
      <path d="M361.996 207.39C361.768 209.024 362.979 210.533 364.717 210.76C366.455 210.988 368.043 209.857 368.255 208.223C368.468 206.589 367.241 205.072 365.519 204.844C363.797 204.617 362.224 205.764 361.996 207.398V207.39Z" fill="#19B8F7" />
      <path d="M352.05 206.109C351.798 207.751 352.993 209.252 354.723 209.472C356.453 209.692 358.057 208.553 358.293 206.911C358.529 205.276 357.326 203.76 355.612 203.54C353.897 203.32 352.301 204.475 352.05 206.109Z" fill="#19B8F7" />
      <path d="M342.134 204.868C341.859 206.51 343.031 208.011 344.753 208.223C346.482 208.435 348.094 207.288 348.354 205.646C348.613 204.004 347.434 202.495 345.728 202.283C344.021 202.071 342.409 203.234 342.134 204.876V204.868Z" fill="#19B8F7" />
      <path d="M332.258 203.666C331.959 205.308 333.1 206.809 334.822 207.013C336.544 207.217 338.171 206.054 338.454 204.412C338.737 202.77 337.582 201.262 335.883 201.058C334.177 200.853 332.557 202.024 332.258 203.666Z" fill="#19B8F7" />
      <path d="M322.414 202.519C322.083 204.169 323.208 205.662 324.922 205.858C326.636 206.054 328.28 204.892 328.586 203.242C328.893 201.6 327.768 200.091 326.07 199.895C324.372 199.698 322.736 200.877 322.414 202.519Z" fill="#19B8F7" />
      <path d="M312.608 201.403C312.255 203.053 313.355 204.538 315.062 204.734C316.768 204.923 318.427 203.745 318.757 202.103C319.088 200.453 317.987 198.96 316.296 198.763C314.606 198.575 312.954 199.761 312.608 201.411V201.403Z" fill="#19B8F7" />
      <path d="M302.858 200.335C302.481 201.985 303.55 203.469 305.248 203.65C306.947 203.831 308.622 202.645 308.976 200.995C309.337 199.345 308.26 197.852 306.577 197.663C304.895 197.483 303.236 198.677 302.858 200.327V200.335Z" fill="#19B8F7" />
      <path d="M293.147 199.313C292.746 200.971 293.784 202.448 295.475 202.621C297.165 202.794 298.848 201.6 299.233 199.95C299.619 198.3 298.565 196.807 296.898 196.634C295.223 196.454 293.548 197.664 293.147 199.313Z" fill="#19B8F7" />
      <path d="M283.499 198.339C283.075 199.997 284.089 201.474 285.764 201.639C287.446 201.804 289.145 200.602 289.554 198.952C289.963 197.294 288.94 195.809 287.281 195.644C285.622 195.472 283.924 196.689 283.499 198.347V198.339Z" fill="#19B8F7" />
      <path d="M273.898 197.404C273.442 199.062 274.433 200.531 276.1 200.696C277.775 200.853 279.481 199.643 279.914 197.986C280.346 196.328 279.355 194.851 277.704 194.686C276.053 194.521 274.347 195.739 273.891 197.404H273.898Z" fill="#19B8F7" />
      <path d="M264.368 196.509C263.889 198.174 264.848 199.635 266.507 199.785C268.166 199.934 269.888 198.716 270.352 197.059C270.808 195.401 269.849 193.924 268.206 193.767C266.562 193.609 264.848 194.835 264.368 196.501V196.509Z" fill="#19B8F7" />
      <path d="M254.893 195.66C254.39 197.326 255.31 198.787 256.961 198.928C258.613 199.07 260.343 197.852 260.83 196.187C261.318 194.529 260.382 193.052 258.754 192.91C257.127 192.761 255.397 193.995 254.886 195.66H254.893Z" fill="#19B8F7" />
      <path d="M245.489 194.851C244.954 196.516 245.851 197.97 247.494 198.111C249.13 198.253 250.875 197.019 251.386 195.354C251.897 193.688 251.001 192.227 249.381 192.085C247.762 191.944 246.024 193.185 245.489 194.851Z" fill="#19B8F7" />
      <path d="M236.164 194.089C235.605 195.754 236.47 197.208 238.098 197.341C239.725 197.475 241.479 196.234 242.021 194.568C242.556 192.902 241.691 191.441 240.087 191.308C238.483 191.174 236.73 192.423 236.171 194.089H236.164Z" fill="#19B8F7" />
      <path d="M226.909 193.366C226.327 195.031 227.152 196.485 228.772 196.603C230.384 196.729 232.153 195.479 232.719 193.814C233.286 192.148 232.444 190.687 230.856 190.569C229.26 190.443 227.498 191.7 226.917 193.366H226.909Z" fill="#19B8F7" />
      <path d="M217.733 192.682C217.119 194.348 217.921 195.794 219.517 195.911C221.114 196.029 222.891 194.772 223.488 193.107C224.078 191.441 223.276 189.988 221.696 189.87C220.115 189.752 218.346 191.017 217.733 192.682Z" fill="#19B8F7" />
      <path d="M208.635 192.046C207.998 193.72 208.761 195.157 210.349 195.267C211.93 195.377 213.722 194.112 214.336 192.447C214.949 190.781 214.178 189.328 212.614 189.218C211.049 189.108 209.264 190.373 208.627 192.046H208.635Z" fill="#19B8F7" />
      <path d="M199.624 191.441C198.963 193.115 199.695 194.544 201.267 194.647C202.84 194.749 204.633 193.484 205.277 191.81C205.922 190.137 205.183 188.699 203.634 188.597C202.085 188.495 200.292 189.768 199.632 191.441H199.624Z" fill="#19B8F7" />
      <path d="M190.707 190.883C190.015 192.557 190.715 193.987 192.272 194.081C193.829 194.175 195.629 192.902 196.298 191.229C196.966 189.555 196.266 188.118 194.733 188.023C193.2 187.929 191.399 189.21 190.707 190.883Z" fill="#19B8F7" />
      <path d="M181.877 190.357C181.161 192.03 181.83 193.452 183.363 193.547C184.904 193.633 186.713 192.352 187.412 190.687C188.104 189.013 187.436 187.583 185.918 187.497C184.401 187.411 182.592 188.691 181.877 190.365V190.357Z" fill="#19B8F7" />
      <path d="M392.828 202.267C392.686 203.909 393.96 205.434 395.682 205.685C397.404 205.936 398.914 204.821 399.039 203.187C399.165 201.552 397.876 200.013 396.169 199.753C394.463 199.502 392.969 200.625 392.828 202.267Z" fill="#19B8F7" />
      <path d="M382.944 200.838C382.778 202.48 384.029 204.004 385.751 204.247C387.473 204.491 388.998 203.367 389.148 201.725C389.297 200.083 388.031 198.551 386.333 198.308C384.626 198.056 383.117 199.196 382.951 200.838H382.944Z" fill="#19B8F7" />
      <path d="M373.091 199.455C372.895 201.097 374.129 202.621 375.851 202.857C377.565 203.092 379.106 201.961 379.287 200.319C379.46 198.677 378.218 197.145 376.519 196.909C374.821 196.666 373.288 197.813 373.099 199.455H373.091Z" fill="#19B8F7" />
      <path d="M363.254 198.111C363.034 199.761 364.245 201.278 365.959 201.505C367.674 201.733 369.23 200.594 369.427 198.952C369.624 197.31 368.405 195.778 366.714 195.55C365.016 195.314 363.475 196.469 363.254 198.119V198.111Z" fill="#19B8F7" />
      <path d="M353.449 196.807C353.205 198.457 354.393 199.973 356.099 200.193C357.805 200.413 359.378 199.266 359.606 197.616C359.834 195.967 358.639 194.442 356.948 194.222C355.258 193.995 353.693 195.157 353.449 196.807Z" fill="#19B8F7" />
      <path d="M343.675 195.55C343.408 197.2 344.564 198.708 346.27 198.928C347.977 199.141 349.565 197.986 349.809 196.336C350.06 194.686 348.889 193.17 347.206 192.95C345.523 192.73 343.943 193.9 343.675 195.55Z" fill="#19B8F7" />
      <path d="M333.933 194.34C333.642 195.998 334.774 197.498 336.473 197.711C338.171 197.923 339.775 196.752 340.05 195.102C340.326 193.452 339.186 191.936 337.503 191.724C335.828 191.512 334.232 192.69 333.933 194.34Z" fill="#19B8F7" />
      <path d="M324.23 193.177C323.908 194.835 325.016 196.336 326.707 196.532C328.397 196.736 330.017 195.558 330.316 193.9C330.615 192.242 329.498 190.734 327.831 190.538C326.157 190.333 324.552 191.52 324.23 193.177Z" fill="#19B8F7" />
      <path d="M314.574 192.054C314.228 193.712 315.305 195.212 316.988 195.401C318.671 195.597 320.306 194.411 320.629 192.753C320.959 191.095 319.866 189.595 318.207 189.398C316.54 189.202 314.92 190.396 314.574 192.054Z" fill="#19B8F7" />
      <path d="M304.965 190.97C304.596 192.627 305.642 194.12 307.317 194.309C308.991 194.497 310.643 193.303 310.989 191.645C311.342 189.988 310.281 188.487 308.63 188.298C306.978 188.11 305.335 189.312 304.958 190.97H304.965Z" fill="#19B8F7" />
      <path d="M295.404 189.94C295.003 191.606 296.025 193.091 297.692 193.272C299.359 193.452 301.018 192.25 301.403 190.593C301.781 188.935 300.751 187.434 299.107 187.261C297.464 187.081 295.805 188.291 295.404 189.948V189.94Z" fill="#19B8F7" />
      <path d="M285.898 188.943C285.473 190.608 286.464 192.093 288.123 192.258C289.782 192.431 291.457 191.221 291.858 189.556C292.267 187.89 291.26 186.405 289.625 186.232C287.989 186.059 286.322 187.277 285.89 188.943H285.898Z" fill="#19B8F7" />
      <path d="M276.454 188C275.998 189.665 276.965 191.143 278.608 191.308C280.252 191.473 281.942 190.255 282.375 188.589C282.807 186.924 281.84 185.439 280.213 185.274C278.585 185.109 276.902 186.334 276.454 188Z" fill="#19B8F7" />
      <path d="M267.065 187.096C266.586 188.762 267.521 190.239 269.157 190.388C270.792 190.545 272.499 189.32 272.955 187.654C273.411 185.989 272.475 184.504 270.855 184.354C269.243 184.197 267.545 185.431 267.065 187.096Z" fill="#19B8F7" />
      <path d="M257.748 186.24C257.237 187.906 258.141 189.375 259.768 189.524C261.396 189.673 263.11 188.44 263.598 186.774C264.085 185.109 263.173 183.632 261.569 183.482C259.965 183.333 258.259 184.574 257.748 186.24Z" fill="#19B8F7" />
      <path d="M248.501 185.423C247.966 187.096 248.839 188.558 250.451 188.699C252.063 188.841 253.793 187.599 254.304 185.934C254.815 184.268 253.942 182.791 252.346 182.657C250.757 182.516 249.035 183.757 248.501 185.431V185.423Z" fill="#19B8F7" />
      <path d="M239.317 184.653C238.758 186.326 239.592 187.78 241.196 187.913C242.8 188.047 244.538 186.806 245.072 185.132C245.615 183.459 244.766 181.997 243.193 181.864C241.613 181.73 239.883 182.98 239.317 184.653Z" fill="#19B8F7" />
      <path d="M230.211 183.922C229.621 185.596 230.431 187.049 232.012 187.175C233.6 187.301 235.346 186.051 235.92 184.378C236.486 182.705 235.676 181.243 234.111 181.118C232.546 180.992 230.801 182.249 230.211 183.922Z" fill="#19B8F7" />
      <path d="M221.184 183.231C220.571 184.904 221.342 186.358 222.914 186.468C224.487 186.586 226.248 185.329 226.846 183.655C227.443 181.982 226.665 180.528 225.108 180.41C223.559 180.293 221.798 181.557 221.184 183.231Z" fill="#19B8F7" />
      <path d="M212.244 182.587C211.599 184.26 212.338 185.706 213.903 185.816C215.46 185.926 217.229 184.661 217.858 182.987C218.479 181.314 217.732 179.861 216.199 179.751C214.658 179.641 212.897 180.913 212.252 182.587H212.244Z" fill="#19B8F7" />
      <path d="M203.382 181.982C202.714 183.655 203.422 185.093 204.963 185.203C206.504 185.305 208.289 184.032 208.934 182.359C209.586 180.685 208.871 179.24 207.345 179.138C205.82 179.036 204.043 180.308 203.374 181.982H203.382Z" fill="#19B8F7" />
      <path d="M194.615 181.416C193.915 183.09 194.591 184.527 196.117 184.622C197.642 184.716 199.435 183.443 200.111 181.762C200.788 180.088 200.111 178.651 198.602 178.548C197.092 178.454 195.307 179.735 194.615 181.408V181.416Z" fill="#19B8F7" />
      <path d="M54.4085 177.126C53.3234 178.792 53.3941 180.135 54.5736 180.135C55.7609 180.135 57.593 178.784 58.6703 177.126C59.7475 175.461 59.6689 174.117 58.4894 174.117C57.3178 174.117 55.4857 175.469 54.4006 177.134L54.4085 177.126Z" fill="#19B8F7" />
      <path d="M432.662 199.18C432.638 200.814 433.991 202.37 435.697 202.652C437.403 202.935 438.803 201.843 438.811 200.209C438.819 198.575 437.451 197.011 435.768 196.729C434.077 196.446 432.693 197.538 432.67 199.18H432.662Z" fill="#19B8F7" />
      <path d="M422.88 197.569C422.825 199.211 424.162 200.759 425.868 201.034C427.574 201.309 428.99 200.217 429.021 198.575C429.053 196.941 427.708 195.377 426.018 195.102C424.327 194.819 422.927 195.927 422.872 197.569H422.88Z" fill="#19B8F7" />
      <path d="M413.106 195.998C413.028 197.64 414.341 199.188 416.047 199.455C417.753 199.722 419.185 198.622 419.247 196.98C419.302 195.338 417.981 193.79 416.291 193.515C414.608 193.24 413.185 194.356 413.106 195.998Z" fill="#19B8F7" />
      <path d="M403.333 194.466C403.23 196.108 404.52 197.656 406.226 197.915C407.932 198.174 409.387 197.066 409.466 195.424C409.552 193.782 408.247 192.235 406.556 191.968C404.874 191.7 403.427 192.824 403.325 194.466H403.333Z" fill="#19B8F7" />
      <path d="M393.582 192.981C393.457 194.631 394.723 196.163 396.429 196.422C398.127 196.681 399.605 195.558 399.708 193.908C399.818 192.266 398.536 190.718 396.853 190.459C395.171 190.2 393.708 191.331 393.582 192.981Z" fill="#19B8F7" />
      <path d="M383.848 191.535C383.691 193.185 384.941 194.717 386.639 194.969C388.338 195.22 389.824 194.089 389.957 192.439C390.091 190.789 388.833 189.249 387.158 188.998C385.483 188.746 383.997 189.885 383.848 191.535Z" fill="#19B8F7" />
      <path d="M374.129 190.137C373.948 191.787 375.175 193.319 376.865 193.562C378.556 193.806 380.066 192.667 380.223 191.017C380.38 189.367 379.146 187.827 377.471 187.584C375.796 187.34 374.302 188.487 374.121 190.137H374.129Z" fill="#19B8F7" />
      <path d="M364.442 188.778C364.237 190.436 365.432 191.96 367.123 192.188C368.814 192.423 370.339 191.276 370.52 189.618C370.709 187.969 369.498 186.437 367.831 186.201C366.164 185.965 364.646 187.12 364.442 188.778Z" fill="#19B8F7" />
      <path d="M354.778 187.466C354.542 189.123 355.722 190.64 357.404 190.868C359.087 191.095 360.628 189.94 360.841 188.283C361.053 186.625 359.873 185.101 358.206 184.873C356.539 184.645 355.006 185.808 354.778 187.466Z" fill="#19B8F7" />
      <path d="M345.154 186.193C344.894 187.851 346.042 189.367 347.717 189.587C349.392 189.807 350.957 188.644 351.193 186.986C351.428 185.329 350.273 183.805 348.613 183.585C346.954 183.365 345.405 184.535 345.146 186.193H345.154Z" fill="#19B8F7" />
      <path d="M335.561 184.975C335.278 186.633 336.394 188.149 338.069 188.361C339.744 188.573 341.316 187.403 341.584 185.745C341.851 184.087 340.719 182.571 339.068 182.359C337.416 182.147 335.852 183.325 335.561 184.983V184.975Z" fill="#19B8F7" />
      <path d="M326.015 183.797C325.7 185.454 326.793 186.963 328.46 187.167C330.127 187.371 331.716 186.193 332.007 184.527C332.297 182.87 331.197 181.353 329.553 181.149C327.91 180.945 326.329 182.131 326.015 183.789V183.797Z" fill="#19B8F7" />
      <path d="M316.508 182.657C316.17 184.323 317.232 185.824 318.891 186.02C320.55 186.216 322.154 185.03 322.477 183.365C322.799 181.699 321.722 180.19 320.086 179.994C318.451 179.798 316.854 180.992 316.508 182.657Z" fill="#19B8F7" />
      <path d="M307.049 181.573C306.68 183.239 307.718 184.739 309.361 184.92C311.012 185.109 312.632 183.914 312.978 182.249C313.324 180.583 312.286 179.083 310.65 178.894C309.023 178.706 307.411 179.908 307.041 181.573H307.049Z" fill="#19B8F7" />
      <path d="M297.645 180.528C297.252 182.194 298.258 183.687 299.894 183.867C301.537 184.048 303.173 182.846 303.542 181.18C303.92 179.515 302.905 178.014 301.286 177.834C299.666 177.653 298.038 178.863 297.645 180.528Z" fill="#19B8F7" />
      <path d="M288.296 179.531C287.871 181.196 288.846 182.689 290.474 182.862C292.101 183.035 293.753 181.825 294.162 180.159C294.563 178.493 293.58 177.001 291.976 176.828C290.364 176.655 288.72 177.873 288.296 179.538V179.531Z" fill="#19B8F7" />
      <path d="M279.002 178.572C278.553 180.238 279.497 181.723 281.117 181.888C282.737 182.053 284.404 180.835 284.836 179.169C285.268 177.504 284.317 176.011 282.713 175.846C281.117 175.681 279.458 176.906 279.002 178.572Z" fill="#19B8F7" />
      <path d="M269.77 177.661C269.291 179.334 270.203 180.811 271.815 180.968C273.427 181.125 275.102 179.9 275.565 178.234C276.021 176.561 275.102 175.084 273.513 174.927C271.925 174.769 270.25 176.003 269.77 177.669V177.661Z" fill="#19B8F7" />
      <path d="M260.61 176.796C260.099 178.47 260.979 179.939 262.583 180.088C264.18 180.238 265.87 179.004 266.358 177.331C266.845 175.657 265.957 174.18 264.384 174.031C262.804 173.882 261.121 175.115 260.61 176.789V176.796Z" fill="#19B8F7" />
      <path d="M251.512 175.971C250.978 177.645 251.827 179.114 253.407 179.256C254.996 179.397 256.702 178.156 257.213 176.482C257.724 174.809 256.875 173.34 255.31 173.198C253.745 173.057 252.047 174.298 251.512 175.971Z" fill="#19B8F7" />
      <path d="M242.493 175.194C241.927 176.867 242.745 178.328 244.318 178.462C245.89 178.596 247.612 177.346 248.155 175.673C248.697 174 247.872 172.53 246.323 172.397C244.774 172.263 243.059 173.512 242.493 175.186V175.194Z" fill="#19B8F7" />
      <path d="M233.545 174.463C232.955 176.137 233.734 177.598 235.299 177.724C236.855 177.849 238.585 176.6 239.159 174.927C239.733 173.253 238.939 171.792 237.406 171.666C235.865 171.54 234.143 172.797 233.553 174.471L233.545 174.463Z" fill="#19B8F7" />
      <path d="M224.668 173.772C224.046 175.445 224.793 176.899 226.342 177.016C227.891 177.134 229.629 175.877 230.227 174.204C230.824 172.53 230.07 171.077 228.544 170.951C227.019 170.833 225.281 172.098 224.66 173.772H224.668Z" fill="#19B8F7" />
      <path d="M215.885 173.12C215.24 174.793 215.955 176.239 217.481 176.356C219.014 176.466 220.768 175.202 221.389 173.528C222.018 171.855 221.294 170.401 219.785 170.291C218.275 170.181 216.529 171.446 215.877 173.127L215.885 173.12Z" fill="#19B8F7" />
      <path d="M207.172 172.507C206.496 174.18 207.172 175.626 208.698 175.728C210.215 175.83 211.977 174.557 212.629 172.884C213.282 171.21 212.598 169.765 211.096 169.663C209.602 169.561 207.841 170.833 207.164 172.507H207.172Z" fill="#19B8F7" />
      <path d="M198.554 171.941C197.855 173.615 198.499 175.052 200.001 175.147C201.503 175.241 203.28 173.968 203.956 172.287C204.64 170.613 203.988 169.168 202.502 169.073C201.023 168.979 199.254 170.26 198.554 171.933V171.941Z" fill="#19B8F7" />
      <path d="M190.023 171.415C189.292 173.088 189.905 174.518 191.391 174.612C192.877 174.707 194.662 173.418 195.37 171.745C196.078 170.071 195.464 168.634 193.994 168.547C192.523 168.453 190.754 169.741 190.023 171.423V171.415Z" fill="#19B8F7" />
      <path d="M181.586 170.928C180.831 172.601 181.405 174.031 182.875 174.109C184.346 174.196 186.139 172.9 186.87 171.226C187.609 169.553 187.019 168.123 185.572 168.036C184.118 167.95 182.333 169.246 181.578 170.92L181.586 170.928Z" fill="#19B8F7" />
      <path d="M60.5652 167.738C59.4722 169.403 59.5351 170.747 60.6989 170.739C61.8705 170.739 63.6947 169.388 64.7798 167.722C65.8571 166.056 65.7942 164.713 64.6304 164.721C63.4746 164.721 61.6503 166.08 60.5652 167.738Z" fill="#19B8F7" />
      <path d="M413.476 186.696C413.421 188.346 414.726 189.893 416.409 190.168C418.092 190.443 419.491 189.328 419.531 187.686C419.57 186.036 418.249 184.48 416.59 184.205C414.931 183.93 413.539 185.046 413.484 186.696H413.476Z" fill="#19B8F7" />
      <path d="M403.851 185.156C403.765 186.806 405.055 188.354 406.729 188.621C408.404 188.888 409.827 187.772 409.89 186.122C409.953 184.472 408.656 182.917 406.997 182.65C405.338 182.383 403.93 183.506 403.844 185.156H403.851Z" fill="#19B8F7" />
      <path d="M394.243 183.655C394.133 185.305 395.391 186.853 397.073 187.112C398.748 187.371 400.187 186.248 400.282 184.598C400.376 182.948 399.094 181.4 397.443 181.133C395.784 180.874 394.361 182.005 394.251 183.655H394.243Z" fill="#19B8F7" />
      <path d="M384.658 182.202C384.524 183.859 385.759 185.399 387.433 185.643C389.108 185.894 390.563 184.763 390.681 183.105C390.799 181.455 389.549 179.908 387.897 179.656C386.246 179.405 384.799 180.544 384.666 182.194L384.658 182.202Z" fill="#19B8F7" />
      <path d="M375.096 180.788C374.931 182.445 376.142 183.977 377.809 184.221C379.476 184.464 380.954 183.325 381.096 181.668C381.237 180.01 380.019 178.47 378.367 178.226C376.716 177.983 375.253 179.13 375.088 180.788H375.096Z" fill="#19B8F7" />
      <path d="M365.558 179.42C365.37 181.078 366.557 182.61 368.216 182.846C369.883 183.082 371.369 181.935 371.542 180.277C371.715 178.619 370.512 177.087 368.877 176.844C367.233 176.608 365.747 177.763 365.558 179.42Z" fill="#19B8F7" />
      <path d="M356.052 178.093C355.832 179.75 356.995 181.283 358.655 181.502C360.314 181.73 361.823 180.575 362.02 178.918C362.217 177.26 361.045 175.728 359.409 175.5C357.774 175.272 356.272 176.435 356.052 178.093Z" fill="#19B8F7" />
      <path d="M346.577 176.812C346.325 178.47 347.465 179.994 349.117 180.214C350.768 180.434 352.301 179.271 352.521 177.614C352.749 175.956 351.601 174.424 349.974 174.204C348.338 173.984 346.821 175.154 346.577 176.812Z" fill="#19B8F7" />
      <path d="M337.141 175.579C336.866 177.244 337.967 178.761 339.618 178.973C341.261 179.185 342.81 178.014 343.062 176.349C343.314 174.691 342.205 173.167 340.577 172.955C338.95 172.742 337.416 173.921 337.141 175.579Z" fill="#19B8F7" />
      <path d="M327.753 174.392C327.446 176.058 328.523 177.566 330.159 177.771C331.794 177.975 333.359 176.796 333.642 175.131C333.925 173.465 332.84 171.949 331.22 171.745C329.6 171.54 328.051 172.727 327.745 174.384L327.753 174.392Z" fill="#19B8F7" />
      <path d="M318.403 173.253C318.073 174.919 319.119 176.427 320.747 176.624C322.374 176.82 323.955 175.634 324.269 173.968C324.584 172.302 323.522 170.794 321.918 170.598C320.306 170.401 318.742 171.595 318.403 173.261V173.253Z" fill="#19B8F7" />
      <path d="M309.101 172.153C308.74 173.819 309.754 175.319 311.382 175.508C313.002 175.697 314.598 174.502 314.936 172.837C315.274 171.171 314.252 169.663 312.648 169.474C311.044 169.286 309.463 170.488 309.101 172.153Z" fill="#19B8F7" />
      <path d="M299.862 171.1C299.469 172.766 300.46 174.267 302.072 174.447C303.684 174.628 305.296 173.426 305.665 171.76C306.035 170.095 305.036 168.594 303.448 168.414C301.86 168.233 300.255 169.443 299.862 171.108V171.1Z" fill="#19B8F7" />
      <path d="M290.67 170.095C290.254 171.76 291.205 173.253 292.809 173.426C294.413 173.599 296.041 172.389 296.434 170.723C296.835 169.058 295.868 167.565 294.287 167.392C292.707 167.219 291.087 168.429 290.67 170.103V170.095Z" fill="#19B8F7" />
      <path d="M281.541 169.136C281.093 170.81 282.013 172.287 283.609 172.452C285.198 172.617 286.841 171.399 287.274 169.733C287.698 168.068 286.77 166.575 285.198 166.41C283.625 166.245 281.997 167.463 281.541 169.136Z" fill="#19B8F7" />
      <path d="M272.467 168.217C271.988 169.89 272.884 171.368 274.465 171.525C276.045 171.682 277.704 170.456 278.16 168.791C278.616 167.125 277.72 165.64 276.155 165.483C274.598 165.326 272.947 166.551 272.467 168.225V168.217Z" fill="#19B8F7" />
      <path d="M263.464 167.345C262.961 169.018 263.818 170.488 265.383 170.637C266.955 170.786 268.622 169.553 269.11 167.887C269.597 166.214 268.725 164.737 267.183 164.587C265.634 164.438 263.975 165.672 263.464 167.345Z" fill="#19B8F7" />
      <path d="M254.532 166.52C253.997 168.193 254.823 169.663 256.379 169.804C257.936 169.945 259.619 168.704 260.138 167.039C260.649 165.365 259.816 163.896 258.282 163.755C256.749 163.613 255.066 164.854 254.532 166.528V166.52Z" fill="#19B8F7" />
      <path d="M245.67 165.742C245.104 167.416 245.898 168.877 247.439 169.011C248.988 169.144 250.679 167.903 251.221 166.229C251.764 164.556 250.962 163.095 249.444 162.961C247.919 162.827 246.228 164.077 245.67 165.742Z" fill="#19B8F7" />
      <path d="M236.879 164.996C236.289 166.669 237.044 168.123 238.578 168.248C240.111 168.374 241.817 167.125 242.391 165.452C242.965 163.778 242.195 162.317 240.685 162.191C239.175 162.065 237.469 163.315 236.879 164.988V164.996Z" fill="#19B8F7" />
      <path d="M228.167 164.304C227.546 165.978 228.269 167.431 229.787 167.549C231.304 167.667 233.026 166.41 233.624 164.737C234.221 163.063 233.49 161.61 231.996 161.492C230.502 161.374 228.788 162.631 228.159 164.304H228.167Z" fill="#19B8F7" />
      <path d="M219.541 163.652C218.888 165.326 219.58 166.772 221.09 166.882C222.592 166.991 224.33 165.727 224.959 164.053C225.588 162.38 224.888 160.934 223.41 160.816C221.924 160.706 220.202 161.971 219.549 163.645L219.541 163.652Z" fill="#19B8F7" />
      <path d="M210.986 163.04C210.31 164.713 210.962 166.151 212.456 166.261C213.95 166.363 215.688 165.098 216.349 163.425C217.009 161.751 216.349 160.306 214.87 160.203C213.4 160.101 211.662 161.374 210.986 163.047V163.04Z" fill="#19B8F7" />
      <path d="M202.525 162.474C201.818 164.147 202.439 165.585 203.917 165.679C205.395 165.774 207.149 164.501 207.833 162.827C208.517 161.154 207.888 159.716 206.433 159.622C204.979 159.528 203.233 160.8 202.525 162.474Z" fill="#19B8F7" />
      <path d="M194.143 161.94C193.412 163.613 193.994 165.043 195.456 165.137C196.919 165.232 198.68 163.943 199.396 162.27C200.111 160.596 199.514 159.166 198.075 159.072C196.636 158.986 194.874 160.266 194.143 161.94Z" fill="#19B8F7" />
      <path d="M185.856 161.453C185.093 163.126 185.651 164.548 187.09 164.635C188.537 164.721 190.306 163.432 191.045 161.759C191.784 160.086 191.226 158.664 189.803 158.577C188.38 158.491 186.61 159.787 185.856 161.46V161.453Z" fill="#19B8F7" />
      <path d="M80.16 158.318C79.0985 159.983 79.1929 161.335 80.3881 161.335C81.5832 161.335 83.4075 159.991 84.4611 158.326C85.5148 156.66 85.4047 155.309 84.2252 155.309C83.0458 155.309 81.2215 156.652 80.16 158.318Z" fill="#19B8F7" />
      <path d="M66.7377 158.341C65.6448 159.999 65.6919 161.343 66.8478 161.335C68.0037 161.335 69.8201 159.983 70.9052 158.318C71.9903 156.652 71.9352 155.317 70.7872 155.317C69.6392 155.317 67.8307 156.676 66.7377 158.333V158.341Z" fill="#19B8F7" />
      <path d="M47.4181 158.561C46.2937 160.211 46.2701 161.539 47.3631 161.523C48.4639 161.508 50.2646 160.156 51.3811 158.498C52.4977 156.841 52.5134 155.513 51.4204 155.537C50.3275 155.552 48.5347 156.911 47.4103 158.561H47.4181Z" fill="#19B8F7" />
      <path d="M461.213 185.777C461.307 187.419 462.715 188.99 464.374 189.304C466.033 189.611 467.291 188.542 467.173 186.9C467.063 185.266 465.632 183.679 463.996 183.365C462.361 183.05 461.118 184.134 461.205 185.769L461.213 185.777Z" fill="#19B8F7" />
      <path d="M451.714 184.017C451.785 185.659 453.177 187.23 454.828 187.536C456.487 187.843 457.761 186.759 457.674 185.117C457.588 183.475 456.18 181.895 454.545 181.589C452.909 181.283 451.643 182.375 451.706 184.009L451.714 184.017Z" fill="#19B8F7" />
      <path d="M442.216 182.296C442.255 183.938 443.631 185.509 445.282 185.808C446.941 186.106 448.239 185.014 448.176 183.372C448.113 181.73 446.729 180.151 445.093 179.853C443.458 179.554 442.168 180.646 442.208 182.296H442.216Z" fill="#19B8F7" />
      <path d="M432.717 180.615C432.733 182.265 434.077 183.828 435.736 184.119C437.395 184.41 438.716 183.31 438.677 181.668C438.646 180.026 437.277 178.454 435.642 178.164C434.006 177.873 432.693 178.973 432.709 180.623L432.717 180.615Z" fill="#19B8F7" />
      <path d="M423.226 178.973C423.21 180.623 424.539 182.186 426.198 182.469C427.858 182.752 429.194 181.644 429.186 180.002C429.179 178.352 427.842 176.789 426.206 176.506C424.571 176.223 423.242 177.331 423.226 178.981V178.973Z" fill="#19B8F7" />
      <path d="M413.743 177.378C413.704 179.028 415.009 180.583 416.661 180.858C418.312 181.133 419.68 180.026 419.696 178.376C419.711 176.726 418.398 175.162 416.763 174.887C415.127 174.612 413.775 175.728 413.735 177.378H413.743Z" fill="#19B8F7" />
      <path d="M404.268 175.822C404.205 177.472 405.479 179.028 407.13 179.295C408.782 179.562 410.166 178.446 410.213 176.796C410.26 175.147 408.97 173.591 407.335 173.316C405.707 173.049 404.331 174.172 404.268 175.822Z" fill="#19B8F7" />
      <path d="M394.817 174.314C394.722 175.964 395.981 177.519 397.624 177.779C399.275 178.038 400.675 176.914 400.746 175.257C400.816 173.607 399.55 172.051 397.923 171.792C396.295 171.533 394.903 172.664 394.809 174.314H394.817Z" fill="#19B8F7" />
      <path d="M385.381 172.845C385.255 174.502 386.49 176.042 388.133 176.294C389.777 176.545 391.2 175.414 391.302 173.756C391.404 172.098 390.162 170.55 388.534 170.299C386.907 170.048 385.499 171.187 385.373 172.837L385.381 172.845Z" fill="#19B8F7" />
      <path d="M375.977 171.423C375.827 173.08 377.031 174.62 378.674 174.864C380.317 175.107 381.756 173.968 381.882 172.31C382.008 170.653 380.797 169.113 379.177 168.869C377.557 168.626 376.126 169.765 375.977 171.423Z" fill="#19B8F7" />
      <path d="M366.596 170.048C366.415 171.705 367.595 173.237 369.23 173.473C370.866 173.709 372.328 172.562 372.486 170.904C372.643 169.246 371.456 167.706 369.844 167.471C368.232 167.235 366.777 168.382 366.604 170.04L366.596 170.048Z" fill="#19B8F7" />
      <path d="M357.247 168.712C357.043 170.37 358.191 171.902 359.818 172.13C361.446 172.357 362.924 171.203 363.113 169.545C363.302 167.887 362.138 166.355 360.534 166.127C358.922 165.899 357.459 167.054 357.247 168.72V168.712Z" fill="#19B8F7" />
      <path d="M347.937 167.424C347.701 169.081 348.818 170.605 350.446 170.825C352.073 171.045 353.567 169.883 353.779 168.225C353.992 166.567 352.867 165.035 351.263 164.815C349.659 164.595 348.173 165.758 347.937 167.424Z" fill="#19B8F7" />
      <path d="M338.667 166.182C338.399 167.84 339.492 169.364 341.112 169.576C342.732 169.788 344.241 168.618 344.485 166.96C344.729 165.302 343.628 163.778 342.032 163.566C340.436 163.354 338.934 164.524 338.667 166.19V166.182Z" fill="#19B8F7" />
      <path d="M329.435 164.988C329.137 166.654 330.198 168.162 331.81 168.366C333.422 168.571 334.955 167.392 335.23 165.734C335.506 164.077 334.436 162.56 332.848 162.348C331.26 162.144 329.734 163.322 329.443 164.988H329.435Z" fill="#19B8F7" />
      <path d="M320.251 163.841C319.929 165.507 320.959 167.015 322.555 167.211C324.159 167.408 325.708 166.221 326.007 164.564C326.314 162.898 325.268 161.39 323.687 161.193C322.107 160.997 320.566 162.183 320.243 163.849L320.251 163.841Z" fill="#19B8F7" />
      <path d="M311.122 162.741C310.768 164.407 311.767 165.907 313.363 166.096C314.959 166.284 316.524 165.09 316.854 163.425C317.185 161.759 316.178 160.258 314.606 160.07C313.033 159.881 311.476 161.075 311.122 162.741Z" fill="#19B8F7" />
      <path d="M302.04 161.68C301.655 163.346 302.622 164.839 304.211 165.019C305.791 165.2 307.379 163.998 307.741 162.333C308.103 160.667 307.128 159.174 305.563 158.986C303.998 158.805 302.426 160.007 302.04 161.673V161.68Z" fill="#19B8F7" />
      <path d="M293.021 160.675C292.605 162.34 293.54 163.833 295.113 164.006C296.686 164.179 298.29 162.969 298.683 161.303C299.076 159.638 298.132 158.145 296.576 157.972C295.027 157.799 293.43 159.009 293.021 160.675Z" fill="#19B8F7" />
      <path d="M284.05 159.708C283.601 161.374 284.506 162.859 286.07 163.024C287.635 163.189 289.247 161.979 289.672 160.306C290.096 158.64 289.184 157.155 287.643 156.99C286.102 156.825 284.498 158.043 284.05 159.708Z" fill="#19B8F7" />
      <path d="M275.149 158.789C274.677 160.455 275.542 161.932 277.099 162.089C278.648 162.246 280.283 161.028 280.731 159.363C281.188 157.697 280.307 156.22 278.774 156.063C277.24 155.906 275.62 157.131 275.149 158.797V158.789Z" fill="#19B8F7" />
      <path d="M266.311 157.909C265.807 159.575 266.641 161.052 268.182 161.201C269.723 161.35 271.366 160.125 271.854 158.459C272.334 156.794 271.492 155.317 269.975 155.167C268.457 155.018 266.822 156.244 266.318 157.917L266.311 157.909Z" fill="#19B8F7" />
      <path d="M257.543 157.084C257.008 158.75 257.811 160.219 259.344 160.36C260.877 160.502 262.536 159.268 263.047 157.603C263.558 155.937 262.749 154.468 261.239 154.327C259.729 154.185 258.078 155.419 257.543 157.084Z" fill="#19B8F7" />
      <path d="M248.847 156.299C248.28 157.964 249.051 159.426 250.569 159.559C252.086 159.693 253.761 158.451 254.304 156.786C254.846 155.12 254.068 153.659 252.574 153.525C251.08 153.392 249.413 154.633 248.854 156.299H248.847Z" fill="#19B8F7" />
      <path d="M240.221 155.552C239.631 157.218 240.362 158.679 241.864 158.805C243.366 158.931 245.057 157.681 245.623 156.016C246.197 154.35 245.45 152.897 243.972 152.763C242.485 152.638 240.811 153.887 240.221 155.552Z" fill="#19B8F7" />
      <path d="M231.674 154.861C231.053 156.526 231.752 157.98 233.246 158.098C234.74 158.216 236.439 156.966 237.036 155.293C237.634 153.627 236.926 152.174 235.456 152.056C233.985 151.938 232.295 153.187 231.674 154.861Z" fill="#19B8F7" />
      <path d="M223.205 154.201C222.553 155.867 223.221 157.312 224.699 157.422C226.177 157.532 227.892 156.275 228.521 154.602C229.15 152.936 228.473 151.49 227.019 151.38C225.564 151.27 223.858 152.528 223.205 154.201Z" fill="#19B8F7" />
      <path d="M214.815 153.588C214.139 155.254 214.768 156.692 216.231 156.802C217.693 156.904 219.415 155.639 220.076 153.973C220.736 152.308 220.091 150.87 218.652 150.76C217.214 150.658 215.492 151.923 214.815 153.588Z" fill="#19B8F7" />
      <path d="M206.504 153.022C205.796 154.688 206.394 156.126 207.841 156.22C209.287 156.314 211.025 155.042 211.709 153.376C212.393 151.71 211.788 150.273 210.365 150.178C208.942 150.084 207.212 151.357 206.504 153.022Z" fill="#19B8F7" />
      <path d="M198.287 152.496C197.556 154.162 198.114 155.592 199.545 155.678C200.976 155.764 202.722 154.492 203.437 152.818C204.153 151.153 203.579 149.723 202.171 149.636C200.756 149.55 199.018 150.823 198.287 152.496Z" fill="#19B8F7" />
      <path d="M190.149 152.001C189.386 153.667 189.913 155.089 191.336 155.175C192.752 155.262 194.513 153.973 195.252 152.308C195.991 150.642 195.456 149.22 194.057 149.134C192.657 149.047 190.912 150.336 190.149 152.001Z" fill="#19B8F7" />
      <path d="M182.105 151.553C181.319 153.219 181.806 154.633 183.214 154.712C184.613 154.79 186.382 153.502 187.153 151.828C187.924 150.163 187.42 148.748 186.036 148.67C184.653 148.591 182.899 149.88 182.105 151.553Z" fill="#19B8F7" />
      <path d="M174.147 151.145C173.33 152.81 173.794 154.224 175.177 154.295C176.561 154.366 178.338 153.07 179.133 151.404C179.927 149.738 179.463 148.324 178.095 148.253C176.726 148.183 174.965 149.479 174.147 151.145Z" fill="#19B8F7" />
      <path d="M166.284 150.768C165.443 152.433 165.868 153.84 167.244 153.902C168.612 153.965 170.397 152.669 171.215 150.995C172.032 149.33 171.6 147.924 170.247 147.861C168.895 147.798 167.126 149.102 166.284 150.768Z" fill="#19B8F7" />
      <path d="M86.1753 148.913C85.1059 150.571 85.1924 151.923 86.3719 151.923C87.5513 151.923 89.3677 150.579 90.4214 148.921C91.475 147.256 91.3807 145.912 90.2169 145.912C89.0453 145.912 87.2447 147.256 86.1753 148.913Z" fill="#19B8F7" />
      <path d="M79.4917 148.929C78.4144 150.587 78.4695 151.93 79.6332 151.93C80.7969 151.93 82.6055 150.579 83.6748 148.921C84.7442 147.264 84.6734 145.92 83.5254 145.92C82.3774 145.92 80.5689 147.271 79.4917 148.929Z" fill="#19B8F7" />
      <path d="M72.9103 148.961C71.8173 150.618 71.8487 151.954 72.9967 151.946C74.1369 151.946 75.9533 150.587 77.0384 148.929C78.1235 147.271 78.0763 145.936 76.944 145.936C75.8117 145.936 74.0111 147.295 72.9181 148.953L72.9103 148.961Z" fill="#19B8F7" />
      <path d="M66.4311 149.023C65.3224 150.681 65.3381 152.009 66.4626 152.001C67.587 151.993 69.3955 150.642 70.4885 148.984C71.5893 147.326 71.5657 145.991 70.4491 146.006C69.3326 146.014 67.5398 147.374 66.4311 149.023Z" fill="#19B8F7" />
      <path d="M60.0541 149.11C58.9376 150.768 58.9297 152.095 60.0305 152.08C61.1392 152.064 62.9399 150.713 64.0486 149.055C65.1573 147.397 65.1573 146.069 64.0564 146.085C62.9556 146.101 61.1628 147.452 60.0541 149.11Z" fill="#19B8F7" />
      <path d="M53.7716 149.22C52.6471 150.87 52.6157 152.198 53.7008 152.174C54.7938 152.158 56.5865 150.799 57.7031 149.149C58.8197 147.499 58.8432 146.172 57.7581 146.187C56.673 146.211 54.8881 147.562 53.7637 149.22H53.7716Z" fill="#19B8F7" />
      <path d="M47.5912 149.346C46.4589 150.995 46.4039 152.315 47.4732 152.292C48.5426 152.268 50.3354 150.917 51.4598 149.259C52.5842 147.609 52.6314 146.289 51.562 146.313C50.4927 146.337 48.7156 147.696 47.5912 149.346Z" fill="#19B8F7" />
      <path d="M488.608 182.045C488.804 183.679 490.267 185.274 491.894 185.604C493.514 185.934 494.67 184.881 494.458 183.247C494.246 181.613 492.767 180.018 491.163 179.688C489.559 179.358 488.419 180.41 488.616 182.045H488.608Z" fill="#19B8F7" />
      <path d="M479.29 180.159C479.455 181.793 480.91 183.388 482.537 183.71C484.165 184.032 485.337 182.972 485.148 181.337C484.959 179.703 483.497 178.108 481.893 177.778C480.289 177.448 479.125 178.517 479.29 180.151V180.159Z" fill="#19B8F7" />
      <path d="M460.607 176.498C460.725 178.14 462.133 179.719 463.76 180.033C465.388 180.348 466.607 179.271 466.473 177.629C466.339 175.987 464.916 174.408 463.304 174.094C461.692 173.78 460.489 174.856 460.607 176.498Z" fill="#19B8F7" />
      <path d="M432.568 171.305C432.599 172.955 433.944 174.518 435.579 174.809C437.207 175.099 438.496 174.007 438.441 172.357C438.386 170.715 437.026 169.136 435.422 168.846C433.81 168.555 432.544 169.655 432.575 171.297L432.568 171.305Z" fill="#19B8F7" />
      <path d="M423.226 169.655C423.226 171.305 424.555 172.868 426.183 173.151C427.81 173.434 429.116 172.334 429.092 170.684C429.061 169.034 427.732 167.471 426.12 167.188C424.508 166.905 423.218 168.013 423.226 169.655Z" fill="#19B8F7" />
      <path d="M413.9 168.052C413.877 169.702 415.174 171.258 416.802 171.54C418.43 171.815 419.758 170.708 419.758 169.058C419.758 167.408 418.445 165.844 416.841 165.569C415.237 165.294 413.924 166.402 413.9 168.052Z" fill="#19B8F7" />
      <path d="M404.591 166.489C404.543 168.139 405.809 169.694 407.437 169.961C409.065 170.228 410.409 169.113 410.433 167.463C410.464 165.813 409.175 164.257 407.571 163.982C405.967 163.715 404.63 164.831 404.583 166.481L404.591 166.489Z" fill="#19B8F7" />
      <path d="M395.297 164.964C395.218 166.614 396.46 168.162 398.088 168.429C399.708 168.688 401.076 167.565 401.131 165.915C401.186 164.265 399.928 162.71 398.332 162.45C396.728 162.191 395.375 163.315 395.297 164.964Z" fill="#19B8F7" />
      <path d="M386.026 163.495C385.916 165.145 387.135 166.693 388.754 166.944C390.374 167.196 391.758 166.064 391.845 164.414C391.931 162.765 390.697 161.217 389.1 160.958C387.504 160.706 386.128 161.838 386.018 163.487L386.026 163.495Z" fill="#19B8F7" />
      <path d="M376.779 162.065C376.645 163.715 377.833 165.255 379.445 165.507C381.056 165.75 382.464 164.611 382.582 162.961C382.7 161.311 381.497 159.771 379.901 159.52C378.312 159.276 376.913 160.416 376.779 162.065Z" fill="#19B8F7" />
      <path d="M367.563 160.683C367.398 162.34 368.562 163.872 370.166 164.108C371.778 164.344 373.201 163.197 373.343 161.547C373.484 159.889 372.313 158.357 370.724 158.121C369.136 157.886 367.721 159.033 367.556 160.683H367.563Z" fill="#19B8F7" />
      <path d="M358.379 159.347C358.183 161.005 359.315 162.529 360.919 162.757C362.523 162.985 363.97 161.83 364.143 160.18C364.316 158.522 363.168 156.998 361.587 156.762C360.007 156.534 358.576 157.689 358.379 159.339V159.347Z" fill="#19B8F7" />
      <path d="M349.235 158.051C349.007 159.708 350.115 161.225 351.711 161.453C353.308 161.673 354.77 160.518 354.975 158.86C355.179 157.202 354.063 155.678 352.49 155.458C350.917 155.238 349.463 156.401 349.235 158.051Z" fill="#19B8F7" />
      <path d="M340.137 156.809C339.877 158.467 340.955 159.983 342.543 160.196C344.131 160.408 345.618 159.245 345.846 157.587C346.081 155.929 344.996 154.413 343.424 154.201C341.859 153.989 340.389 155.159 340.129 156.809H340.137Z" fill="#19B8F7" />
      <path d="M331.071 155.607C330.788 157.265 331.826 158.773 333.414 158.978C334.995 159.182 336.496 158.011 336.764 156.354C337.031 154.696 335.97 153.187 334.413 152.975C332.856 152.771 331.362 153.949 331.079 155.599L331.071 155.607Z" fill="#19B8F7" />
      <path d="M322.06 154.452C321.745 156.11 322.76 157.611 324.332 157.815C325.905 158.011 327.422 156.833 327.721 155.175C328.012 153.517 326.99 152.017 325.441 151.813C323.892 151.616 322.382 152.795 322.068 154.452H322.06Z" fill="#19B8F7" />
      <path d="M313.096 153.345C312.75 155.002 313.733 156.503 315.298 156.691C316.862 156.88 318.396 155.694 318.726 154.036C319.048 152.378 318.058 150.878 316.516 150.689C314.975 150.5 313.45 151.687 313.104 153.345H313.096Z" fill="#19B8F7" />
      <path d="M304.187 152.284C303.81 153.942 304.761 155.434 306.31 155.615C307.867 155.796 309.416 154.602 309.77 152.944C310.124 151.286 309.164 149.793 307.631 149.613C306.098 149.432 304.557 150.626 304.179 152.284H304.187Z" fill="#19B8F7" />
      <path d="M295.333 151.27C294.924 152.928 295.844 154.413 297.385 154.586C298.927 154.759 300.499 153.557 300.885 151.899C301.27 150.241 300.342 148.756 298.824 148.583C297.299 148.411 295.742 149.613 295.333 151.27Z" fill="#19B8F7" />
      <path d="M286.542 150.304C286.102 151.962 286.983 153.439 288.524 153.612C290.057 153.777 291.645 152.567 292.062 150.909C292.479 149.251 291.583 147.774 290.073 147.601C288.563 147.436 286.983 148.646 286.542 150.304Z" fill="#19B8F7" />
      <path d="M277.814 149.385C277.342 151.043 278.192 152.52 279.717 152.677C281.243 152.834 282.847 151.616 283.287 149.958C283.735 148.301 282.878 146.824 281.376 146.666C279.874 146.509 278.286 147.727 277.814 149.385Z" fill="#19B8F7" />
      <path d="M269.141 148.505C268.638 150.163 269.456 151.632 270.965 151.781C272.475 151.93 274.095 150.705 274.575 149.047C275.054 147.389 274.229 145.92 272.735 145.771C271.241 145.622 269.637 146.839 269.133 148.505H269.141Z" fill="#19B8F7" />
      <path d="M260.547 147.68C260.012 149.338 260.798 150.799 262.3 150.94C263.802 151.082 265.438 149.856 265.941 148.191C266.452 146.533 265.658 145.072 264.18 144.93C262.701 144.789 261.082 146.014 260.547 147.68Z" fill="#19B8F7" />
      <path d="M252.015 146.886C251.457 148.544 252.204 150.006 253.69 150.139C255.176 150.273 256.828 149.039 257.362 147.381C257.897 145.724 257.142 144.27 255.68 144.129C254.217 143.995 252.574 145.229 252.015 146.886Z" fill="#19B8F7" />
      <path d="M243.555 146.148C242.965 147.806 243.681 149.259 245.151 149.385C246.629 149.511 248.288 148.269 248.855 146.612C249.421 144.954 248.705 143.508 247.25 143.375C245.796 143.249 244.145 144.49 243.555 146.148Z" fill="#19B8F7" />
      <path d="M235.173 145.449C234.552 147.106 235.228 148.552 236.69 148.67C238.153 148.788 239.828 147.539 240.425 145.881C241.023 144.223 240.339 142.778 238.9 142.66C237.461 142.542 235.794 143.791 235.173 145.449Z" fill="#19B8F7" />
      <path d="M226.869 144.797C226.217 146.454 226.869 147.892 228.316 148.002C229.763 148.112 231.454 146.863 232.083 145.197C232.712 143.54 232.059 142.102 230.628 141.992C229.197 141.882 227.522 143.131 226.869 144.797Z" fill="#19B8F7" />
      <path d="M218.645 144.184C217.968 145.842 218.574 147.271 220.013 147.381C221.452 147.484 223.15 146.227 223.803 144.569C224.463 142.911 223.842 141.481 222.427 141.371C221.011 141.269 219.321 142.526 218.645 144.184Z" fill="#19B8F7" />
      <path d="M210.498 143.61C209.791 145.268 210.365 146.698 211.788 146.792C213.211 146.886 214.917 145.622 215.609 143.964C216.294 142.306 215.712 140.884 214.312 140.782C212.912 140.688 211.206 141.953 210.498 143.61Z" fill="#19B8F7" />
      <path d="M202.431 143.084C201.692 144.742 202.234 146.164 203.642 146.25C205.049 146.336 206.771 145.064 207.487 143.406C208.202 141.748 207.652 140.326 206.268 140.24C204.884 140.153 203.17 141.418 202.431 143.084Z" fill="#19B8F7" />
      <path d="M194.45 142.597C193.687 144.255 194.19 145.669 195.582 145.755C196.974 145.842 198.712 144.561 199.451 142.903C200.19 141.245 199.679 139.831 198.311 139.745C196.942 139.658 195.213 140.939 194.45 142.597Z" fill="#19B8F7" />
      <path d="M186.555 142.149C185.769 143.807 186.241 145.221 187.617 145.292C188.993 145.37 190.738 144.082 191.509 142.424C192.28 140.766 191.8 139.36 190.44 139.281C189.087 139.203 187.35 140.483 186.555 142.149Z" fill="#19B8F7" />
      <path d="M178.747 141.733C177.93 143.39 178.37 144.797 179.73 144.867C181.09 144.938 182.844 143.65 183.638 141.992C184.432 140.334 183.992 138.928 182.647 138.865C181.303 138.794 179.557 140.083 178.747 141.74V141.733Z" fill="#19B8F7" />
      <path d="M171.034 141.363C170.192 143.021 170.593 144.419 171.938 144.482C173.282 144.545 175.044 143.249 175.869 141.591C176.695 139.933 176.278 138.535 174.949 138.472C173.621 138.409 171.867 139.698 171.026 141.363H171.034Z" fill="#19B8F7" />
      <path d="M163.406 141.033C162.541 142.691 162.911 144.082 164.24 144.144C165.569 144.199 167.338 142.903 168.187 141.245C169.036 139.588 168.659 138.197 167.346 138.142C166.033 138.087 164.271 139.383 163.406 141.041V141.033Z" fill="#19B8F7" />
      <path d="M112.58 139.666C111.557 141.324 111.715 142.683 112.933 142.699C114.144 142.715 115.953 141.387 116.959 139.729C117.966 138.071 117.801 136.72 116.598 136.704C115.394 136.689 113.602 138.016 112.587 139.674L112.58 139.666Z" fill="#19B8F7" />
      <path d="M105.691 139.596C104.653 141.253 104.779 142.605 105.982 142.613C107.178 142.62 108.986 141.293 110.008 139.635C111.03 137.977 110.897 136.626 109.709 136.618C108.522 136.61 106.729 137.938 105.691 139.596Z" fill="#19B8F7" />
      <path d="M98.8978 139.556C97.8441 141.214 97.9463 142.558 99.1258 142.565C100.305 142.565 102.114 141.23 103.152 139.58C104.19 137.922 104.08 136.579 102.916 136.571C101.744 136.571 99.9514 137.899 98.8978 139.556Z" fill="#19B8F7" />
      <path d="M92.2063 139.548C91.1369 141.206 91.2155 142.55 92.3793 142.55C93.543 142.55 95.3515 141.206 96.4052 139.556C97.4588 137.899 97.3802 136.563 96.2243 136.555C95.0684 136.555 93.2757 137.899 92.2063 139.548Z" fill="#19B8F7" />
      <path d="M85.6013 139.564C84.5162 141.222 84.5712 142.558 85.7192 142.558C86.8672 142.558 88.6679 141.206 89.7373 139.556C90.8067 137.899 90.7516 136.563 89.6115 136.571C88.4713 136.571 86.6785 137.922 85.6013 139.572V139.564Z" fill="#19B8F7" />
      <path d="M79.0906 139.603C77.9977 141.253 78.0212 142.589 79.1535 142.581C80.2858 142.581 82.0865 141.222 83.1716 139.572C84.2567 137.914 84.2252 136.587 83.1008 136.594C81.9764 136.602 80.1915 137.954 79.0906 139.603Z" fill="#19B8F7" />
      <path d="M72.6744 139.674C71.5657 141.324 71.5736 142.66 72.6823 142.644C73.7988 142.628 75.5916 141.285 76.6924 139.627C77.7933 137.977 77.7775 136.642 76.6688 136.657C75.5602 136.673 73.7752 138.024 72.6665 139.674H72.6744Z" fill="#19B8F7" />
      <path d="M66.3524 139.761C65.2359 141.41 65.2202 142.738 66.3131 142.722C67.414 142.707 69.2067 141.355 70.3076 139.698C71.4163 138.048 71.4241 136.72 70.339 136.736C69.2461 136.751 67.469 138.103 66.3524 139.761Z" fill="#19B8F7" />
      <path d="M60.1249 139.878C59.0005 141.528 58.9612 142.848 60.0384 142.833C61.1157 142.809 62.9084 141.458 64.025 139.808C65.1416 138.158 65.173 136.838 64.0958 136.854C63.0185 136.877 61.2415 138.229 60.1171 139.878H60.1249Z" fill="#19B8F7" />
      <path d="M47.9293 140.177C46.797 141.827 46.7184 143.139 47.7642 143.108C48.81 143.084 50.587 141.717 51.7193 140.075C52.8516 138.425 52.9145 137.113 51.8766 137.137C50.8308 137.168 49.0694 138.527 47.9293 140.177Z" fill="#19B8F7" />
      <path d="M487.436 172.805C487.656 174.439 489.119 176.034 490.723 176.372C492.319 176.702 493.436 175.657 493.192 174.023C492.956 172.389 491.478 170.794 489.897 170.456C488.317 170.118 487.224 171.179 487.436 172.813V172.805Z" fill="#19B8F7" />
      <path d="M459.884 167.227C460.025 168.869 461.425 170.448 463.037 170.763C464.641 171.077 465.82 170.001 465.663 168.366C465.506 166.724 464.083 165.145 462.502 164.831C460.914 164.517 459.75 165.593 459.892 167.235L459.884 167.227Z" fill="#19B8F7" />
      <path d="M450.684 165.444C450.794 167.086 452.178 168.665 453.782 168.971C455.386 169.278 456.589 168.201 456.456 166.559C456.322 164.917 454.93 163.338 453.342 163.032C451.761 162.725 450.566 163.809 450.676 165.444H450.684Z" fill="#19B8F7" />
      <path d="M441.492 163.7C441.571 165.342 442.939 166.913 444.543 167.219C446.147 167.518 447.374 166.434 447.264 164.792C447.161 163.15 445.785 161.57 444.205 161.272C442.624 160.973 441.414 162.058 441.492 163.7Z" fill="#19B8F7" />
      <path d="M432.3 162.002C432.355 163.644 433.692 165.216 435.296 165.506C436.9 165.797 438.142 164.705 438.072 163.063C437.993 161.421 436.648 159.85 435.06 159.559C433.48 159.268 432.245 160.36 432.3 162.002Z" fill="#19B8F7" />
      <path d="M423.124 160.345C423.148 161.987 424.461 163.55 426.065 163.841C427.669 164.124 428.935 163.024 428.888 161.382C428.84 159.74 427.512 158.168 425.931 157.886C424.351 157.603 423.093 158.703 423.124 160.345Z" fill="#19B8F7" />
      <path d="M413.956 158.734C413.956 160.376 415.237 161.94 416.841 162.215C418.438 162.49 419.727 161.382 419.711 159.74C419.696 158.098 418.39 156.534 416.81 156.259C415.229 155.984 413.956 157.092 413.956 158.734Z" fill="#19B8F7" />
      <path d="M404.811 157.171C404.779 158.821 406.038 160.368 407.642 160.643C409.238 160.91 410.551 159.803 410.559 158.153C410.567 156.511 409.293 154.955 407.72 154.68C406.148 154.405 404.85 155.529 404.819 157.171H404.811Z" fill="#19B8F7" />
      <path d="M395.682 155.647C395.619 157.296 396.853 158.844 398.45 159.103C400.046 159.363 401.375 158.247 401.414 156.597C401.453 154.947 400.203 153.4 398.638 153.14C397.066 152.881 395.745 153.997 395.69 155.647H395.682Z" fill="#19B8F7" />
      <path d="M386.576 154.162C386.482 155.811 387.693 157.351 389.281 157.603C390.87 157.854 392.222 156.731 392.293 155.081C392.364 153.431 391.145 151.891 389.572 151.64C388.007 151.388 386.663 152.512 386.576 154.162Z" fill="#19B8F7" />
      <path d="M368.452 151.341C368.303 152.991 369.451 154.523 371.031 154.759C372.611 154.995 374.003 153.855 374.129 152.205C374.263 150.556 373.099 149.031 371.542 148.788C369.985 148.552 368.609 149.691 368.452 151.341Z" fill="#19B8F7" />
      <path d="M359.441 150.006C359.26 151.655 360.377 153.18 361.949 153.407C363.522 153.635 364.937 152.488 365.094 150.838C365.252 149.188 364.119 147.664 362.57 147.436C361.021 147.209 359.622 148.356 359.441 150.006Z" fill="#19B8F7" />
      <path d="M350.469 148.709C350.257 150.359 351.35 151.875 352.915 152.095C354.479 152.315 355.91 151.16 356.099 149.511C356.288 147.861 355.187 146.344 353.646 146.124C352.105 145.904 350.681 147.059 350.469 148.709Z" fill="#19B8F7" />
      <path d="M341.544 147.46C341.301 149.11 342.362 150.618 343.919 150.838C345.476 151.05 346.931 149.888 347.151 148.246C347.371 146.596 346.302 145.087 344.768 144.875C343.235 144.663 341.796 145.818 341.552 147.468L341.544 147.46Z" fill="#19B8F7" />
      <path d="M332.651 146.258C332.376 147.908 333.406 149.408 334.955 149.621C336.504 149.825 337.975 148.654 338.226 147.012C338.478 145.362 337.44 143.862 335.915 143.65C334.389 143.445 332.927 144.608 332.651 146.258Z" fill="#19B8F7" />
      <path d="M323.813 145.103C323.507 146.753 324.505 148.253 326.046 148.45C327.588 148.646 329.074 147.476 329.357 145.826C329.64 144.176 328.633 142.683 327.116 142.479C325.598 142.282 324.12 143.453 323.813 145.103Z" fill="#19B8F7" />
      <path d="M315.03 143.995C314.692 145.645 315.651 147.138 317.193 147.327C318.726 147.515 320.228 146.337 320.55 144.687C320.865 143.037 319.89 141.544 318.38 141.356C316.87 141.167 315.376 142.345 315.038 143.995H315.03Z" fill="#19B8F7" />
      <path d="M306.294 142.935C305.925 144.584 306.853 146.069 308.378 146.25C309.903 146.431 311.429 145.244 311.775 143.595C312.121 141.945 311.177 140.46 309.683 140.279C308.181 140.098 306.672 141.285 306.302 142.935H306.294Z" fill="#19B8F7" />
      <path d="M297.621 141.921C297.22 143.571 298.117 145.048 299.634 145.221C301.152 145.394 302.693 144.2 303.07 142.55C303.448 140.9 302.544 139.423 301.05 139.25C299.556 139.077 298.03 140.271 297.629 141.921H297.621Z" fill="#19B8F7" />
      <path d="M288.996 140.955C288.563 142.605 289.428 144.074 290.93 144.247C292.432 144.412 293.989 143.21 294.397 141.56C294.806 139.91 293.934 138.441 292.455 138.276C290.977 138.111 289.428 139.313 288.996 140.963V140.955Z" fill="#19B8F7" />
      <path d="M280.441 140.028C279.977 141.678 280.802 143.147 282.296 143.304C283.79 143.461 285.363 142.251 285.803 140.601C286.244 138.951 285.402 137.49 283.932 137.333C282.461 137.176 280.897 138.386 280.433 140.028H280.441Z" fill="#19B8F7" />
      <path d="M271.948 139.156C271.453 140.805 272.247 142.267 273.733 142.416C275.219 142.565 276.808 141.348 277.28 139.706C277.751 138.056 276.949 136.602 275.487 136.445C274.024 136.296 272.444 137.506 271.948 139.156Z" fill="#19B8F7" />
      <path d="M263.527 138.323C263 139.973 263.763 141.426 265.233 141.568C266.704 141.709 268.308 140.491 268.811 138.841C269.314 137.192 268.544 135.738 267.097 135.597C265.65 135.455 264.054 136.673 263.527 138.323Z" fill="#19B8F7" />
      <path d="M255.169 137.537C254.61 139.187 255.342 140.633 256.796 140.766C258.259 140.9 259.879 139.674 260.413 138.024C260.948 136.374 260.209 134.929 258.778 134.795C257.339 134.662 255.727 135.887 255.176 137.537H255.169Z" fill="#19B8F7" />
      <path d="M246.881 136.799C246.291 138.449 246.983 139.886 248.43 140.012C249.877 140.138 251.512 138.904 252.078 137.254C252.644 135.604 251.945 134.167 250.521 134.041C249.098 133.915 247.471 135.149 246.889 136.791L246.881 136.799Z" fill="#19B8F7" />
      <path d="M238.664 136.099C238.043 137.749 238.703 139.179 240.134 139.305C241.565 139.423 243.217 138.181 243.814 136.531C244.412 134.882 243.744 133.452 242.336 133.334C240.929 133.216 239.285 134.449 238.664 136.099Z" fill="#19B8F7" />
      <path d="M230.526 135.439C229.881 137.089 230.502 138.519 231.917 138.629C233.341 138.739 235 137.498 235.629 135.848C236.258 134.198 235.629 132.776 234.221 132.658C232.822 132.548 231.17 133.79 230.526 135.439Z" fill="#19B8F7" />
      <path d="M222.466 134.827C221.79 136.477 222.38 137.899 223.787 138.001C225.195 138.103 226.869 136.854 227.522 135.204C228.175 133.554 227.577 132.14 226.193 132.03C224.809 131.928 223.142 133.177 222.466 134.819V134.827Z" fill="#19B8F7" />
      <path d="M214.485 134.261C213.777 135.911 214.336 137.325 215.727 137.427C217.119 137.521 218.81 136.264 219.494 134.615C220.178 132.965 219.62 131.55 218.244 131.456C216.875 131.362 215.193 132.611 214.485 134.261Z" fill="#19B8F7" />
      <path d="M206.575 133.735C205.844 135.384 206.362 136.791 207.739 136.885C209.115 136.979 210.821 135.714 211.529 134.065C212.244 132.415 211.717 131.008 210.357 130.922C208.997 130.836 207.306 132.093 206.575 133.742V133.735Z" fill="#19B8F7" />
      <path d="M198.751 133.247C197.988 134.897 198.476 136.304 199.836 136.382C201.204 136.469 202.911 135.196 203.65 133.546C204.389 131.896 203.901 130.498 202.557 130.411C201.212 130.325 199.514 131.598 198.751 133.24V133.247Z" fill="#19B8F7" />
      <path d="M191.014 132.792C190.227 134.442 190.676 135.84 192.028 135.919C193.381 135.997 195.103 134.717 195.865 133.075C196.636 131.425 196.172 130.034 194.843 129.956C193.514 129.877 191.8 131.15 191.014 132.8V132.792Z" fill="#19B8F7" />
      <path d="M183.355 132.383C182.537 134.033 182.954 135.424 184.291 135.494C185.627 135.565 187.357 134.285 188.151 132.635C188.946 130.985 188.521 129.594 187.208 129.523C185.895 129.453 184.173 130.733 183.355 132.375V132.383Z" fill="#19B8F7" />
      <path d="M175.783 132.014C174.942 133.664 175.327 135.047 176.648 135.117C177.969 135.18 179.707 133.892 180.532 132.25C181.358 130.6 180.965 129.217 179.659 129.154C178.362 129.091 176.624 130.372 175.783 132.022V132.014Z" fill="#19B8F7" />
      <path d="M168.297 131.684C167.432 133.334 167.778 134.717 169.084 134.772C170.389 134.827 172.142 133.538 172.984 131.888C173.833 130.238 173.471 128.863 172.182 128.801C170.892 128.746 169.154 130.034 168.289 131.684H168.297Z" fill="#19B8F7" />
      <path d="M160.898 131.385C160.01 133.035 160.324 134.41 161.614 134.465C162.903 134.512 164.665 133.216 165.537 131.574C166.41 129.924 166.08 128.549 164.814 128.502C163.54 128.455 161.794 129.743 160.906 131.393L160.898 131.385Z" fill="#19B8F7" />
      <path d="M153.578 131.126C152.665 132.776 152.949 134.151 154.222 134.19C155.496 134.237 157.265 132.933 158.162 131.283C159.058 129.633 158.759 128.266 157.509 128.219C156.251 128.172 154.498 129.476 153.578 131.118V131.126Z" fill="#19B8F7" />
      <path d="M146.351 130.898C145.416 132.548 145.667 133.915 146.925 133.955C148.183 133.994 149.953 132.69 150.873 131.04C151.793 129.39 151.525 128.031 150.283 127.991C149.041 127.952 147.279 129.256 146.344 130.906L146.351 130.898Z" fill="#19B8F7" />
      <path d="M139.22 130.702C138.26 132.352 138.488 133.711 139.731 133.742C140.973 133.774 142.75 132.462 143.694 130.812C144.637 129.162 144.401 127.803 143.175 127.771C141.948 127.74 140.179 129.044 139.22 130.694V130.702Z" fill="#19B8F7" />
      <path d="M132.166 130.545C131.184 132.195 131.38 133.554 132.607 133.577C133.833 133.601 135.618 132.289 136.578 130.639C137.545 128.989 137.34 127.638 136.122 127.606C134.911 127.583 133.134 128.895 132.159 130.537L132.166 130.545Z" fill="#19B8F7" />
      <path d="M125.2 130.419C124.201 132.069 124.366 133.42 125.577 133.444C126.788 133.467 128.573 132.148 129.564 130.498C130.547 128.848 130.374 127.496 129.179 127.481C127.983 127.457 126.206 128.777 125.208 130.427L125.2 130.419Z" fill="#19B8F7" />
      <path d="M118.327 130.317C117.313 131.967 117.447 133.318 118.642 133.334C119.837 133.35 121.63 132.022 122.629 130.38C123.635 128.73 123.486 127.386 122.298 127.371C121.119 127.355 119.334 128.675 118.32 130.325L118.327 130.317Z" fill="#19B8F7" />
      <path d="M111.542 130.254C110.504 131.904 110.622 133.247 111.801 133.255C112.981 133.263 114.773 131.935 115.796 130.286C116.818 128.636 116.7 127.292 115.528 127.284C114.357 127.276 112.58 128.596 111.542 130.246V130.254Z" fill="#19B8F7" />
      <path d="M104.842 130.215C103.789 131.865 103.875 133.208 105.039 133.208C106.203 133.208 107.995 131.88 109.033 130.231C110.071 128.581 109.977 127.245 108.821 127.237C107.665 127.237 105.888 128.565 104.834 130.207L104.842 130.215Z" fill="#19B8F7" />
      <path d="M98.2373 130.207C97.1679 131.857 97.2308 133.192 98.3788 133.192C99.5268 133.192 101.32 131.857 102.373 130.207C103.427 128.557 103.356 127.221 102.224 127.221C101.084 127.221 99.2988 128.557 98.2373 130.207Z" fill="#19B8F7" />
      <path d="M91.7109 130.223C90.6337 131.873 90.6651 133.208 91.8053 133.2C92.9376 133.2 94.7303 131.857 95.7997 130.207C96.8691 128.557 96.8219 127.229 95.6975 127.229C94.5731 127.229 92.7882 128.573 91.7109 130.223Z" fill="#19B8F7" />
      <path d="M85.271 130.27C84.178 131.92 84.1938 133.247 85.3103 133.24C86.4269 133.232 88.2197 131.888 89.3048 130.238C90.3899 128.588 90.3663 127.261 89.2576 127.269C88.1489 127.276 86.364 128.62 85.271 130.27Z" fill="#19B8F7" />
      <path d="M78.9255 130.341C77.8168 131.99 77.8168 133.318 78.9177 133.302C80.0185 133.287 81.8113 131.943 82.9042 130.293C84.0051 128.643 83.9972 127.324 82.9042 127.331C81.8113 127.347 80.0342 128.691 78.9255 130.341Z" fill="#19B8F7" />
      <path d="M72.6508 130.435C71.5342 132.085 71.5106 133.405 72.5957 133.389C73.6808 133.373 75.4658 132.022 76.5744 130.372C77.6831 128.722 77.6989 127.402 76.6216 127.418C75.5444 127.434 73.7673 128.785 72.6508 130.435Z" fill="#19B8F7" />
      <path d="M66.4704 130.553C65.346 132.203 65.2988 133.522 66.376 133.499C67.4454 133.475 69.2303 132.124 70.3469 130.474C71.4635 128.824 71.5028 127.504 70.4334 127.528C69.364 127.551 67.5948 128.903 66.4704 130.553Z" fill="#19B8F7" />
      <path d="M60.3687 130.694C59.2364 132.344 59.1735 133.656 60.235 133.632C61.2965 133.609 63.0657 132.25 64.1902 130.6C65.3146 128.95 65.3696 127.638 64.316 127.661C63.2623 127.685 61.501 129.044 60.3687 130.694Z" fill="#19B8F7" />
      <path d="M459.035 157.964C459.192 159.598 460.592 161.178 462.172 161.5C463.752 161.814 464.893 160.745 464.712 159.111C464.531 157.477 463.116 155.898 461.559 155.576C460.002 155.262 458.877 156.33 459.035 157.964Z" fill="#19B8F7" />
      <path d="M449.992 156.173C450.126 157.807 451.502 159.386 453.082 159.693C454.663 159.999 455.819 158.923 455.669 157.289C455.52 155.654 454.128 154.075 452.571 153.769C451.014 153.462 449.866 154.539 449.992 156.173Z" fill="#19B8F7" />
      <path d="M440.957 154.429C441.06 156.063 442.412 157.634 443.993 157.941C445.573 158.239 446.752 157.163 446.635 155.521C446.509 153.887 445.141 152.315 443.584 152.009C442.027 151.71 440.855 152.787 440.957 154.429Z" fill="#19B8F7" />
      <path d="M431.931 152.732C432.001 154.366 433.338 155.937 434.911 156.228C436.483 156.519 437.694 155.434 437.6 153.792C437.506 152.158 436.161 150.587 434.612 150.296C433.055 150.006 431.86 151.09 431.939 152.732H431.931Z" fill="#19B8F7" />
      <path d="M422.92 151.066C422.967 152.708 424.272 154.264 425.845 154.547C427.417 154.829 428.644 153.737 428.581 152.103C428.518 150.469 427.197 148.906 425.648 148.623C424.099 148.332 422.88 149.432 422.92 151.066Z" fill="#19B8F7" />
      <path d="M413.916 149.456C413.932 151.098 415.214 152.645 416.778 152.928C418.351 153.203 419.601 152.103 419.562 150.469C419.523 148.835 418.233 147.279 416.684 146.996C415.135 146.714 413.893 147.821 413.908 149.456H413.916Z" fill="#19B8F7" />
      <path d="M404.937 147.884C404.921 149.526 406.171 151.074 407.744 151.341C409.309 151.616 410.582 150.501 410.574 148.866C410.574 147.224 409.301 145.684 407.76 145.409C406.211 145.134 404.952 146.25 404.937 147.884Z" fill="#19B8F7" />
      <path d="M395.973 146.352C395.926 147.994 397.152 149.534 398.717 149.793C400.282 150.053 401.571 148.945 401.595 147.303C401.618 145.661 400.384 144.121 398.843 143.862C397.302 143.595 396.02 144.71 395.973 146.352Z" fill="#19B8F7" />
      <path d="M387.04 144.875C386.962 146.517 388.157 148.049 389.722 148.309C391.278 148.56 392.599 147.444 392.647 145.802C392.702 144.16 391.491 142.628 389.957 142.377C388.424 142.125 387.119 143.241 387.04 144.883V144.875Z" fill="#19B8F7" />
      <path d="M378.139 143.437C378.029 145.079 379.201 146.604 380.75 146.855C382.307 147.099 383.643 145.975 383.722 144.333C383.809 142.691 382.629 141.167 381.096 140.915C379.562 140.672 378.241 141.795 378.131 143.437H378.139Z" fill="#19B8F7" />
      <path d="M360.432 140.711C360.259 142.353 361.367 143.87 362.908 144.097C364.45 144.325 365.826 143.186 365.975 141.552C366.124 139.91 365.008 138.401 363.482 138.166C361.965 137.938 360.597 139.077 360.424 140.711H360.432Z" fill="#19B8F7" />
      <path d="M351.641 139.415C351.436 141.057 352.513 142.565 354.047 142.785C355.58 143.005 356.98 141.858 357.161 140.224C357.341 138.582 356.248 137.081 354.739 136.854C353.229 136.634 351.845 137.773 351.641 139.415Z" fill="#19B8F7" />
      <path d="M342.889 138.166C342.653 139.8 343.699 141.308 345.224 141.52C346.75 141.733 348.165 140.578 348.377 138.943C348.59 137.309 347.536 135.809 346.026 135.589C344.525 135.377 343.117 136.524 342.881 138.166H342.889Z" fill="#19B8F7" />
      <path d="M334.177 136.971C333.909 138.606 334.924 140.106 336.441 140.31C337.959 140.515 339.398 139.352 339.642 137.718C339.885 136.084 338.863 134.583 337.361 134.379C335.867 134.174 334.444 135.329 334.177 136.971Z" fill="#19B8F7" />
      <path d="M325.52 135.817C325.221 137.451 326.204 138.943 327.713 139.14C329.223 139.336 330.678 138.174 330.953 136.531C331.228 134.897 330.237 133.405 328.751 133.208C327.265 133.012 325.818 134.175 325.52 135.809V135.817Z" fill="#19B8F7" />
      <path d="M316.909 134.709C316.579 136.343 317.523 137.828 319.025 138.016C320.527 138.205 321.997 137.034 322.304 135.392C322.61 133.758 321.651 132.273 320.173 132.085C318.694 131.896 317.232 133.067 316.902 134.701L316.909 134.709Z" fill="#19B8F7" />
      <path d="M308.354 133.648C307.993 135.282 308.905 136.759 310.399 136.94C311.893 137.121 313.387 135.942 313.725 134.308C314.063 132.674 313.143 131.197 311.673 131.016C310.202 130.836 308.724 132.014 308.362 133.648H308.354Z" fill="#19B8F7" />
      <path d="M299.854 132.635C299.461 134.269 300.342 135.738 301.82 135.911C303.306 136.084 304.816 134.897 305.186 133.263C305.555 131.629 304.667 130.16 303.204 129.987C301.742 129.814 300.248 130.993 299.854 132.635Z" fill="#19B8F7" />
      <path d="M291.417 131.668C290.993 133.302 291.834 134.764 293.312 134.937C294.783 135.102 296.316 133.907 296.717 132.273C297.118 130.639 296.261 129.178 294.814 129.013C293.367 128.848 291.85 130.034 291.425 131.668H291.417Z" fill="#19B8F7" />
      <path d="M283.043 130.749C282.587 132.383 283.397 133.845 284.86 134.002C286.322 134.159 287.863 132.957 288.304 131.323C288.736 129.688 287.918 128.235 286.479 128.078C285.04 127.921 283.507 129.115 283.051 130.749H283.043Z" fill="#19B8F7" />
      <path d="M274.724 129.869C274.237 131.503 275.007 132.957 276.462 133.106C277.916 133.255 279.473 132.053 279.937 130.419C280.401 128.785 279.615 127.339 278.192 127.19C276.761 127.041 275.212 128.243 274.724 129.877V129.869Z" fill="#19B8F7" />
      <path d="M266.476 129.044C265.957 130.678 266.696 132.124 268.135 132.265C269.574 132.407 271.154 131.197 271.65 129.563C272.145 127.929 271.398 126.491 269.983 126.349C268.567 126.208 267.002 127.41 266.476 129.052V129.044Z" fill="#19B8F7" />
      <path d="M258.29 128.259C257.74 129.893 258.448 131.33 259.871 131.464C261.302 131.598 262.89 130.38 263.417 128.746C263.944 127.111 263.228 125.682 261.829 125.548C260.421 125.414 258.841 126.624 258.29 128.259Z" fill="#19B8F7" />
      <path d="M250.176 127.512C249.594 129.146 250.262 130.576 251.677 130.702C253.093 130.828 254.705 129.602 255.263 127.968C255.821 126.334 255.145 124.912 253.745 124.778C252.354 124.652 250.757 125.87 250.176 127.504V127.512Z" fill="#19B8F7" />
      <path d="M242.139 126.821C241.526 128.455 242.163 129.877 243.563 129.995C244.97 130.113 246.59 128.887 247.18 127.253C247.769 125.619 247.125 124.205 245.741 124.079C244.357 123.961 242.745 125.187 242.132 126.821H242.139Z" fill="#19B8F7" />
      <path d="M234.166 126.161C233.522 127.795 234.127 129.209 235.519 129.327C236.911 129.437 238.546 128.204 239.167 126.569C239.788 124.935 239.175 123.521 237.807 123.411C236.439 123.301 234.811 124.527 234.166 126.161Z" fill="#19B8F7" />
      <path d="M226.272 125.556C225.595 127.19 226.162 128.604 227.546 128.706C228.922 128.808 230.573 127.567 231.225 125.933C231.878 124.299 231.296 122.892 229.944 122.79C228.591 122.688 226.948 123.922 226.272 125.556Z" fill="#19B8F7" />
      <path d="M218.456 124.982C217.756 126.617 218.283 128.023 219.651 128.117C221.019 128.211 222.678 126.97 223.362 125.336C224.047 123.702 223.504 122.303 222.159 122.209C220.815 122.115 219.164 123.356 218.464 124.99L218.456 124.982Z" fill="#19B8F7" />
      <path d="M210.711 124.456C209.98 126.09 210.475 127.489 211.827 127.583C213.18 127.669 214.855 126.42 215.562 124.786C216.27 123.152 215.767 121.761 214.438 121.667C213.109 121.573 211.442 122.822 210.711 124.456Z" fill="#19B8F7" />
      <path d="M203.044 123.969C202.281 125.603 202.745 126.994 204.09 127.08C205.427 127.167 207.117 125.902 207.856 124.267C208.596 122.633 208.124 121.243 206.803 121.164C205.49 121.078 203.807 122.335 203.052 123.969H203.044Z" fill="#19B8F7" />
      <path d="M195.456 123.521C194.67 125.155 195.102 126.546 196.423 126.616C197.744 126.695 199.443 125.43 200.213 123.796C200.976 122.162 200.536 120.779 199.238 120.7C197.933 120.622 196.243 121.887 195.456 123.513V123.521Z" fill="#19B8F7" />
      <path d="M187.955 123.112C187.145 124.747 187.538 126.129 188.851 126.2C190.157 126.271 191.871 124.998 192.665 123.364C193.459 121.73 193.05 120.355 191.761 120.284C190.471 120.213 188.773 121.478 187.955 123.112Z" fill="#19B8F7" />
      <path d="M180.524 122.735C179.683 124.37 180.053 125.744 181.342 125.807C182.64 125.87 184.354 124.597 185.179 122.963C185.997 121.329 185.627 119.954 184.354 119.891C183.08 119.828 181.366 121.101 180.532 122.735H180.524Z" fill="#19B8F7" />
      <path d="M173.18 122.405C172.315 124.04 172.645 125.414 173.927 125.469C175.209 125.524 176.939 124.244 177.78 122.61C178.629 120.976 178.283 119.608 177.025 119.553C175.767 119.498 174.045 120.771 173.18 122.405Z" fill="#19B8F7" />
      <path d="M165.923 122.107C165.034 123.741 165.333 125.108 166.599 125.163C167.865 125.21 169.603 123.93 170.475 122.295C171.348 120.661 171.034 119.302 169.791 119.247C168.541 119.2 166.819 120.481 165.923 122.115V122.107Z" fill="#19B8F7" />
      <path d="M158.744 121.848C157.831 123.482 158.099 124.849 159.349 124.888C160.599 124.935 162.345 123.639 163.241 122.005C164.138 120.371 163.855 119.011 162.62 118.964C161.386 118.917 159.648 120.206 158.736 121.84L158.744 121.848Z" fill="#19B8F7" />
      <path d="M151.643 121.62C150.708 123.254 150.943 124.613 152.186 124.652C153.42 124.692 155.174 123.395 156.094 121.761C157.014 120.127 156.762 118.776 155.543 118.736C154.325 118.697 152.579 119.993 151.643 121.628V121.62Z" fill="#19B8F7" />
      <path d="M144.629 121.423C143.67 123.057 143.882 124.417 145.101 124.448C146.32 124.48 148.081 123.183 149.025 121.541C149.968 119.907 149.748 118.556 148.537 118.524C147.334 118.493 145.581 119.789 144.622 121.423H144.629Z" fill="#19B8F7" />
      <path d="M137.694 121.258C136.719 122.893 136.9 124.244 138.103 124.267C139.314 124.291 141.075 122.987 142.035 121.353C142.994 119.718 142.805 118.375 141.618 118.344C140.423 118.32 138.669 119.616 137.694 121.251V121.258Z" fill="#19B8F7" />
      <path d="M130.845 121.133C129.847 122.767 129.996 124.118 131.191 124.134C132.387 124.157 134.156 122.845 135.139 121.211C136.122 119.577 135.956 118.234 134.785 118.21C133.605 118.186 131.844 119.498 130.845 121.133Z" fill="#19B8F7" />
      <path d="M124.075 121.031C123.061 122.665 123.187 124.008 124.366 124.024C125.546 124.04 127.315 122.728 128.321 121.086C129.328 119.451 129.186 118.108 128.023 118.092C126.859 118.076 125.098 119.389 124.075 121.023V121.031Z" fill="#19B8F7" />
      <path d="M117.392 120.968C116.354 122.602 116.456 123.945 117.62 123.953C118.783 123.961 120.56 122.641 121.583 121.007C122.605 119.373 122.495 118.037 121.347 118.021C120.199 118.014 118.43 119.326 117.392 120.968Z" fill="#19B8F7" />
      <path d="M110.787 120.928C109.733 122.57 109.812 123.898 110.96 123.906C112.108 123.906 113.885 122.586 114.923 120.952C115.961 119.318 115.874 117.982 114.742 117.974C113.602 117.974 111.833 119.286 110.787 120.928Z" fill="#19B8F7" />
      <path d="M104.26 120.913C103.191 122.555 103.246 123.882 104.378 123.882C105.511 123.882 107.296 122.555 108.349 120.913C109.403 119.271 109.34 117.943 108.223 117.943C107.099 117.943 105.33 119.271 104.26 120.905V120.913Z" fill="#19B8F7" />
      <path d="M97.8127 120.928C96.7354 122.57 96.759 123.898 97.8834 123.89C99 123.89 100.785 122.555 101.854 120.913C102.924 119.271 102.884 117.951 101.776 117.951C100.667 117.951 98.8978 119.286 97.8127 120.928Z" fill="#19B8F7" />
      <path d="M91.4514 120.976C90.3584 122.618 90.3663 123.937 91.475 123.93C92.5837 123.922 94.3607 122.586 95.4458 120.944C96.5309 119.302 96.5152 117.982 95.4144 117.99C94.3214 117.99 92.5444 119.334 91.4514 120.976Z" fill="#19B8F7" />
      <path d="M85.161 121.038C84.0601 122.68 84.0444 124 85.1374 123.992C86.2303 123.977 88.0074 122.633 89.1004 120.999C90.1933 119.357 90.2012 118.037 89.1161 118.053C88.031 118.069 86.2618 119.404 85.161 121.046V121.038Z" fill="#19B8F7" />
      <path d="M78.9491 121.133C77.8326 122.775 77.8011 124.095 78.8784 124.079C79.9556 124.063 81.7327 122.712 82.8414 121.07C83.9501 119.428 83.9736 118.108 82.8964 118.124C81.827 118.139 80.0578 119.483 78.9491 121.125V121.133Z" fill="#19B8F7" />
      <path d="M72.8159 121.25C71.6915 122.892 71.6443 124.212 72.7058 124.189C73.7673 124.165 75.5444 122.822 76.6609 121.172C77.7775 119.53 77.8168 118.218 76.7632 118.234C75.7016 118.257 73.9403 119.601 72.8159 121.25Z" fill="#19B8F7" />
      <path d="M458.067 148.733C458.248 150.359 459.64 151.938 461.189 152.253C462.738 152.567 463.839 151.506 463.642 149.88C463.446 148.254 462.038 146.674 460.505 146.36C458.98 146.046 457.887 147.106 458.067 148.733Z" fill="#19B8F7" />
      <path d="M449.19 146.949C449.339 148.576 450.715 150.147 452.264 150.461C453.814 150.768 454.938 149.699 454.765 148.073C454.592 146.446 453.208 144.875 451.683 144.569C450.157 144.262 449.041 145.331 449.19 146.957V146.949Z" fill="#19B8F7" />
      <path d="M440.32 145.197C440.438 146.824 441.791 148.395 443.34 148.693C444.889 148.992 446.037 147.916 445.895 146.289C445.754 144.663 444.394 143.1 442.868 142.793C441.343 142.487 440.203 143.563 440.328 145.197H440.32Z" fill="#19B8F7" />
      <path d="M431.459 143.492C431.553 145.119 432.874 146.682 434.423 146.973C435.972 147.264 437.136 146.187 437.026 144.553C436.916 142.927 435.579 141.363 434.054 141.073C432.528 140.782 431.372 141.858 431.459 143.485V143.492Z" fill="#19B8F7" />
      <path d="M422.613 141.835C422.676 143.461 423.973 145.017 425.514 145.299C427.063 145.582 428.251 144.498 428.164 142.872C428.078 141.245 426.772 139.69 425.247 139.407C423.721 139.124 422.542 140.208 422.605 141.835H422.613Z" fill="#19B8F7" />
      <path d="M413.783 140.216C413.814 141.843 415.08 143.39 416.621 143.673C418.162 143.948 419.373 142.856 419.326 141.23C419.271 139.603 417.989 138.056 416.472 137.781C414.954 137.506 413.751 138.598 413.783 140.224V140.216Z" fill="#19B8F7" />
      <path d="M404.968 138.645C404.968 140.271 406.21 141.811 407.752 142.086C409.293 142.361 410.527 141.253 410.504 139.627C410.48 138.001 409.23 136.461 407.712 136.194C406.195 135.927 404.976 137.019 404.976 138.653L404.968 138.645Z" fill="#19B8F7" />
      <path d="M396.185 137.121C396.153 138.747 397.364 140.279 398.898 140.546C400.431 140.806 401.689 139.698 401.697 138.071C401.705 136.445 400.478 134.913 398.968 134.654C397.459 134.395 396.209 135.494 396.185 137.121Z" fill="#19B8F7" />
      <path d="M387.426 135.644C387.363 137.27 388.55 138.794 390.075 139.053C391.609 139.305 392.883 138.197 392.922 136.571C392.961 134.944 391.766 133.42 390.256 133.169C388.747 132.917 387.481 134.025 387.418 135.652L387.426 135.644Z" fill="#19B8F7" />
      <path d="M378.698 134.214C378.603 135.84 379.759 137.356 381.284 137.608C382.81 137.851 384.107 136.736 384.178 135.109C384.249 133.483 383.085 131.967 381.583 131.723C380.081 131.48 378.792 132.595 378.698 134.222V134.214Z" fill="#19B8F7" />
      <path d="M310.367 124.44C310.013 126.059 310.91 127.528 312.372 127.709C313.835 127.889 315.29 126.719 315.62 125.1C315.95 123.482 315.046 122.02 313.607 121.84C312.168 121.659 310.721 122.822 310.367 124.44Z" fill="#19B8F7" />
      <path d="M302.048 123.427C301.663 125.045 302.52 126.507 303.975 126.679C305.429 126.852 306.9 125.682 307.261 124.055C307.623 122.437 306.75 120.983 305.327 120.811C303.896 120.638 302.434 121.808 302.048 123.427Z" fill="#19B8F7" />
      <path d="M293.792 122.468C293.375 124.087 294.201 125.54 295.64 125.705C297.079 125.87 298.573 124.692 298.974 123.073C299.367 121.455 298.533 120.009 297.11 119.844C295.695 119.679 294.209 120.858 293.792 122.476V122.468Z" fill="#19B8F7" />
      <path d="M285.599 121.549C285.151 123.167 285.937 124.613 287.368 124.77C288.799 124.927 290.309 123.741 290.741 122.123C291.166 120.504 290.364 119.066 288.956 118.909C287.549 118.752 286.047 119.931 285.599 121.549Z" fill="#19B8F7" />
      <path d="M277.46 120.677C276.981 122.295 277.736 123.733 279.151 123.882C280.574 124.032 282.1 122.838 282.556 121.219C283.012 119.601 282.249 118.171 280.849 118.021C279.45 117.872 277.932 119.059 277.453 120.677H277.46Z" fill="#19B8F7" />
      <path d="M269.393 119.852C268.882 121.47 269.597 122.9 271.013 123.042C272.42 123.183 273.969 121.981 274.457 120.371C274.944 118.752 274.213 117.33 272.829 117.189C271.445 117.047 269.912 118.241 269.393 119.86V119.852Z" fill="#19B8F7" />
      <path d="M261.388 119.066C260.846 120.685 261.53 122.107 262.93 122.24C264.329 122.374 265.886 121.172 266.413 119.553C266.932 117.935 266.24 116.521 264.864 116.387C263.488 116.254 261.939 117.448 261.396 119.066H261.388Z" fill="#19B8F7" />
      <path d="M253.447 118.328C252.873 119.946 253.517 121.36 254.909 121.486C256.293 121.612 257.873 120.402 258.424 118.784C258.974 117.165 258.314 115.759 256.954 115.633C255.593 115.507 254.021 116.709 253.447 118.328Z" fill="#19B8F7" />
      <path d="M245.576 117.629C244.97 119.247 245.583 120.653 246.96 120.771C248.336 120.889 249.924 119.671 250.514 118.061C251.096 116.442 250.474 115.044 249.122 114.926C247.769 114.808 246.189 116.018 245.583 117.629H245.576Z" fill="#19B8F7" />
      <path d="M237.783 116.977C237.146 118.595 237.728 119.993 239.089 120.111C240.449 120.221 242.061 119.004 242.674 117.385C243.287 115.767 242.698 114.376 241.361 114.258C240.024 114.148 238.428 115.358 237.783 116.977Z" fill="#19B8F7" />
      <path d="M230.054 116.372C229.386 117.99 229.936 119.381 231.281 119.491C232.633 119.593 234.253 118.367 234.898 116.749C235.542 115.13 234.984 113.74 233.663 113.637C232.334 113.535 230.722 114.753 230.054 116.372Z" fill="#19B8F7" />
      <path d="M222.403 115.798C221.703 117.417 222.214 118.807 223.551 118.901C224.888 118.996 226.523 117.762 227.2 116.152C227.876 114.533 227.349 113.15 226.036 113.056C224.723 112.962 223.095 114.187 222.395 115.798H222.403Z" fill="#19B8F7" />
      <path d="M214.823 115.272C214.1 116.89 214.571 118.273 215.9 118.367C217.221 118.454 218.873 117.22 219.58 115.602C220.288 113.983 219.793 112.608 218.495 112.514C217.19 112.42 215.554 113.653 214.831 115.272H214.823Z" fill="#19B8F7" />
      <path d="M207.314 114.784C206.559 116.403 207.007 117.778 208.312 117.864C209.626 117.951 211.285 116.701 212.016 115.083C212.747 113.465 212.291 112.098 211.009 112.011C209.72 111.925 208.069 113.166 207.314 114.784Z" fill="#19B8F7" />
      <path d="M199.883 114.337C199.105 115.955 199.514 117.33 200.811 117.401C202.109 117.479 203.783 116.222 204.546 114.612C205.309 112.993 204.884 111.626 203.61 111.556C202.337 111.477 200.67 112.726 199.891 114.337H199.883Z" fill="#19B8F7" />
      <path d="M192.531 113.92C191.721 115.539 192.099 116.906 193.381 116.976C194.662 117.047 196.345 115.79 197.139 114.172C197.925 112.553 197.54 111.194 196.274 111.123C195.008 111.053 193.333 112.302 192.523 113.92H192.531Z" fill="#19B8F7" />
      <path d="M185.258 113.551C184.425 115.17 184.77 116.529 186.036 116.599C187.302 116.662 189.001 115.397 189.819 113.787C190.636 112.168 190.275 110.809 189.024 110.746C187.774 110.683 186.091 111.94 185.25 113.551H185.258Z" fill="#19B8F7" />
      <path d="M178.055 113.213C177.198 114.832 177.505 116.191 178.763 116.246C180.021 116.301 181.727 115.036 182.569 113.418C183.41 111.799 183.088 110.448 181.853 110.393C180.619 110.338 178.92 111.595 178.055 113.213Z" fill="#19B8F7" />
      <path d="M170.931 112.915C170.043 114.533 170.326 115.884 171.568 115.939C172.811 115.987 174.525 114.714 175.39 113.103C176.255 111.485 175.964 110.141 174.737 110.086C173.511 110.031 171.804 111.304 170.924 112.915H170.931Z" fill="#19B8F7" />
      <path d="M163.886 112.647C162.974 114.266 163.233 115.617 164.46 115.664C165.687 115.712 167.409 114.431 168.305 112.812C169.194 111.194 168.934 109.851 167.723 109.803C166.512 109.756 164.798 111.029 163.886 112.647Z" fill="#19B8F7" />
      <path d="M156.919 112.42C155.984 114.038 156.212 115.389 157.423 115.421C158.634 115.46 160.371 114.172 161.283 112.561C162.195 110.943 161.967 109.599 160.764 109.56C159.569 109.521 157.847 110.793 156.912 112.42H156.919Z" fill="#19B8F7" />
      <path d="M150.024 112.223C149.072 113.842 149.261 115.185 150.464 115.217C151.667 115.248 153.405 113.96 154.34 112.341C155.276 110.723 155.072 109.387 153.892 109.348C152.705 109.316 150.975 110.597 150.024 112.215V112.223Z" fill="#19B8F7" />
      <path d="M143.214 112.05C142.239 113.669 142.404 115.012 143.591 115.036C144.779 115.067 146.524 113.771 147.484 112.145C148.443 110.526 148.27 109.191 147.098 109.159C145.927 109.128 144.189 110.416 143.214 112.043V112.05Z" fill="#19B8F7" />
      <path d="M136.475 111.917C135.477 113.543 135.618 114.879 136.79 114.894C137.961 114.918 139.715 113.614 140.698 111.995C141.681 110.377 141.531 109.041 140.368 109.018C139.212 108.994 137.466 110.291 136.475 111.909V111.917Z" fill="#19B8F7" />
      <path d="M129.808 111.815C128.793 113.441 128.911 114.769 130.067 114.785C131.231 114.8 132.984 113.496 133.983 111.878C134.981 110.251 134.864 108.931 133.715 108.908C132.567 108.892 130.822 110.188 129.808 111.815Z" fill="#19B8F7" />
      <path d="M123.226 111.744C122.196 113.37 122.283 114.698 123.431 114.706C124.579 114.714 126.34 113.41 127.354 111.783C128.376 110.157 128.274 108.837 127.142 108.821C126.01 108.806 124.256 110.11 123.226 111.736V111.744Z" fill="#19B8F7" />
      <path d="M116.715 111.697C115.67 113.323 115.733 114.651 116.865 114.659C117.997 114.659 119.758 113.347 120.796 111.728C121.834 110.102 121.756 108.782 120.639 108.774C119.515 108.774 117.761 110.078 116.715 111.705V111.697Z" fill="#19B8F7" />
      <path d="M110.276 111.681C109.214 113.307 109.253 114.635 110.37 114.635C111.487 114.635 113.256 113.315 114.309 111.689C115.363 110.063 115.308 108.743 114.199 108.743C113.091 108.743 111.337 110.055 110.268 111.681H110.276Z" fill="#19B8F7" />
      <path d="M103.906 111.689C102.829 113.323 102.853 114.643 103.954 114.635C105.062 114.635 106.824 113.308 107.893 111.681C108.962 110.055 108.931 108.735 107.838 108.735C106.745 108.735 104.984 110.055 103.906 111.689Z" fill="#19B8F7" />
      <path d="M97.6161 111.721C96.5231 113.355 96.5231 114.675 97.6161 114.667C98.709 114.667 100.478 113.331 101.555 111.697C102.633 110.063 102.625 108.751 101.54 108.759C100.455 108.759 98.6933 110.094 97.6082 111.721H97.6161Z" fill="#19B8F7" />
      <path d="M448.286 137.765C448.451 139.383 449.819 140.947 451.337 141.253C452.862 141.56 453.947 140.499 453.759 138.881C453.57 137.262 452.194 135.699 450.692 135.392C449.19 135.086 448.113 136.147 448.286 137.765Z" fill="#19B8F7" />
      <path d="M439.581 136.021C439.723 137.639 441.06 139.195 442.577 139.493C444.095 139.792 445.203 138.724 445.046 137.105C444.889 135.487 443.536 133.931 442.035 133.632C440.541 133.334 439.44 134.395 439.573 136.013L439.581 136.021Z" fill="#19B8F7" />
      <path d="M430.885 134.324C430.995 135.942 432.308 137.49 433.826 137.789C435.343 138.079 436.475 137.011 436.342 135.392C436.208 133.774 434.887 132.226 433.393 131.935C431.899 131.645 430.775 132.713 430.885 134.332V134.324Z" fill="#19B8F7" />
      <path d="M422.212 132.666C422.29 134.284 423.572 135.824 425.09 136.115C426.607 136.398 427.755 135.322 427.661 133.703C427.559 132.085 426.261 130.545 424.767 130.262C423.273 129.979 422.133 131.055 422.212 132.666Z" fill="#19B8F7" />
      <path d="M413.555 131.048C413.602 132.666 414.86 134.198 416.37 134.481C417.879 134.756 419.059 133.672 418.988 132.061C418.917 130.443 417.651 128.911 416.157 128.636C414.671 128.361 413.507 129.437 413.547 131.055L413.555 131.048Z" fill="#19B8F7" />
      <path d="M404.921 129.484C404.937 131.095 406.163 132.627 407.673 132.902C409.183 133.169 410.378 132.085 410.338 130.466C410.299 128.848 409.057 127.324 407.571 127.057C406.085 126.789 404.897 127.874 404.913 129.484H404.921Z" fill="#19B8F7" />
      <path d="M396.311 127.96C396.295 129.571 397.49 131.095 399 131.354C400.502 131.613 401.721 130.521 401.713 128.903C401.713 127.292 400.494 125.768 399.016 125.509C397.537 125.25 396.327 126.342 396.311 127.952V127.96Z" fill="#19B8F7" />
      <path d="M387.732 126.491C387.685 128.101 388.857 129.618 390.351 129.869C391.852 130.121 393.087 129.021 393.118 127.41C393.142 125.799 391.963 124.291 390.484 124.032C389.006 123.78 387.779 124.872 387.732 126.491Z" fill="#19B8F7" />
      <path d="M379.185 125.061C379.106 126.672 380.247 128.18 381.74 128.424C383.234 128.667 384.493 127.559 384.555 125.949C384.611 124.338 383.463 122.838 381.992 122.586C380.522 122.343 379.271 123.442 379.193 125.053L379.185 125.061Z" fill="#19B8F7" />
      <path d="M312.325 115.335C311.979 116.937 312.86 118.383 314.291 118.564C315.722 118.744 317.145 117.589 317.46 115.987C317.782 114.384 316.894 112.946 315.486 112.765C314.079 112.585 312.671 113.732 312.325 115.335Z" fill="#19B8F7" />
      <path d="M304.195 114.329C303.817 115.932 304.659 117.369 306.082 117.542C307.505 117.715 308.944 116.552 309.298 114.957C309.652 113.355 308.795 111.925 307.403 111.752C306.003 111.579 304.572 112.734 304.203 114.329H304.195Z" fill="#19B8F7" />
      <path d="M296.119 113.37C295.711 114.965 296.52 116.403 297.928 116.568C299.335 116.733 300.798 115.562 301.183 113.967C301.569 112.365 300.751 110.943 299.367 110.778C297.983 110.613 296.528 111.775 296.119 113.37Z" fill="#19B8F7" />
      <path d="M288.107 112.459C287.667 114.054 288.437 115.484 289.837 115.641C291.236 115.798 292.715 114.627 293.131 113.025C293.548 111.43 292.77 110.008 291.394 109.851C290.018 109.694 288.547 110.856 288.107 112.459Z" fill="#19B8F7" />
      <path d="M280.157 111.587C279.686 113.182 280.417 114.604 281.809 114.753C283.2 114.902 284.694 113.724 285.143 112.129C285.591 110.534 284.844 109.12 283.484 108.971C282.115 108.821 280.629 109.992 280.157 111.587Z" fill="#19B8F7" />
      <path d="M272.263 110.762C271.76 112.357 272.459 113.771 273.836 113.912C275.212 114.054 276.729 112.868 277.209 111.273C277.688 109.678 276.981 108.271 275.62 108.13C274.268 107.989 272.766 109.167 272.255 110.762H272.263Z" fill="#19B8F7" />
      <path d="M264.439 109.984C263.904 111.579 264.565 112.985 265.933 113.119C267.301 113.252 268.827 112.066 269.346 110.471C269.857 108.876 269.181 107.478 267.836 107.344C266.491 107.211 264.974 108.389 264.431 109.984H264.439Z" fill="#19B8F7" />
      <path d="M256.678 109.246C256.112 110.84 256.741 112.239 258.094 112.365C259.446 112.49 260.995 111.296 261.546 109.701C262.096 108.106 261.451 106.716 260.115 106.59C258.786 106.464 257.244 107.651 256.678 109.246Z" fill="#19B8F7" />
      <path d="M248.988 108.554C248.391 110.149 248.988 111.54 250.325 111.658C251.669 111.775 253.234 110.573 253.808 108.979C254.39 107.384 253.777 106.001 252.456 105.883C251.135 105.765 249.586 106.959 248.98 108.554H248.988Z" fill="#19B8F7" />
      <path d="M241.361 107.902C240.732 109.497 241.29 110.88 242.627 110.998C243.956 111.108 245.536 109.906 246.15 108.311C246.755 106.716 246.181 105.341 244.876 105.223C243.563 105.113 241.998 106.307 241.369 107.902H241.361Z" fill="#19B8F7" />
      <path d="M233.805 107.297C233.144 108.892 233.671 110.275 234.992 110.377C236.313 110.479 237.901 109.269 238.546 107.674C239.183 106.079 238.648 104.705 237.351 104.602C236.053 104.5 234.473 105.702 233.812 107.297H233.805Z" fill="#19B8F7" />
      <path d="M226.319 106.724C225.627 108.319 226.122 109.694 227.428 109.788C228.733 109.882 230.345 108.664 231.013 107.077C231.682 105.482 231.178 104.115 229.897 104.021C228.615 103.927 227.019 105.129 226.327 106.724H226.319Z" fill="#19B8F7" />
      <path d="M218.904 106.197C218.181 107.792 218.645 109.159 219.942 109.254C221.239 109.348 222.859 108.122 223.559 106.527C224.259 104.932 223.787 103.573 222.513 103.479C221.239 103.393 219.627 104.602 218.912 106.197H218.904Z" fill="#19B8F7" />
      <path d="M211.56 105.71C210.813 107.305 211.237 108.672 212.519 108.751C213.801 108.837 215.436 107.604 216.168 106.009C216.899 104.414 216.459 103.063 215.2 102.976C213.942 102.89 212.315 104.115 211.568 105.71H211.56Z" fill="#19B8F7" />
      <path d="M204.287 105.262C203.508 106.857 203.901 108.216 205.175 108.295C206.441 108.374 208.092 107.14 208.847 105.545C209.602 103.95 209.201 102.599 207.951 102.52C206.701 102.442 205.065 103.667 204.287 105.262Z" fill="#19B8F7" />
      <path d="M197.084 104.846C196.282 106.441 196.644 107.8 197.902 107.863C199.16 107.934 200.819 106.692 201.605 105.097C202.392 103.503 202.014 102.159 200.78 102.088C199.545 102.018 197.894 103.251 197.092 104.846H197.084Z" fill="#19B8F7" />
      <path d="M189.96 104.469C189.127 106.064 189.457 107.415 190.707 107.478C191.949 107.541 193.624 106.299 194.434 104.697C195.244 103.102 194.906 101.758 193.679 101.695C192.453 101.633 190.794 102.866 189.968 104.461L189.96 104.469Z" fill="#19B8F7" />
      <path d="M182.899 104.131C182.042 105.726 182.341 107.077 183.575 107.132C184.81 107.187 186.485 105.938 187.326 104.343C188.159 102.748 187.853 101.413 186.642 101.35C185.431 101.295 183.756 102.536 182.899 104.131Z" fill="#19B8F7" />
      <path d="M175.917 103.825C175.036 105.419 175.303 106.763 176.522 106.818C177.741 106.873 179.431 105.616 180.296 104.013C181.161 102.418 180.878 101.083 179.683 101.028C178.48 100.973 176.805 102.222 175.924 103.825H175.917Z" fill="#19B8F7" />
      <path d="M169.005 103.558C168.101 105.16 168.337 106.496 169.547 106.543C170.751 106.59 172.457 105.325 173.337 103.73C174.226 102.135 173.974 100.8 172.787 100.753C171.6 100.706 169.909 101.963 169.005 103.558Z" fill="#19B8F7" />
      <path d="M162.172 103.314C161.244 104.917 161.456 106.252 162.644 106.292C163.839 106.331 165.545 105.066 166.457 103.463C167.369 101.86 167.149 100.541 165.97 100.493C164.798 100.454 163.092 101.711 162.164 103.314H162.172Z" fill="#19B8F7" />
      <path d="M155.402 103.11C154.45 104.712 154.631 106.04 155.811 106.079C156.99 106.111 158.704 104.838 159.64 103.235C160.576 101.633 160.379 100.313 159.215 100.273C158.052 100.234 156.345 101.507 155.394 103.11H155.402Z" fill="#19B8F7" />
      <path d="M148.702 102.937C147.735 104.54 147.885 105.867 149.056 105.899C150.22 105.93 151.95 104.65 152.901 103.047C153.853 101.444 153.696 100.124 152.54 100.093C151.392 100.061 149.67 101.334 148.702 102.937Z" fill="#19B8F7" />
      <path d="M142.082 102.795C141.091 104.398 141.217 105.726 142.373 105.749C143.529 105.773 145.258 104.485 146.233 102.882C147.208 101.279 147.075 99.9592 145.935 99.9356C144.795 99.9121 143.073 101.193 142.082 102.795Z" fill="#19B8F7" />
      <path d="M135.524 102.678C134.517 104.288 134.62 105.608 135.76 105.624C136.9 105.64 138.638 104.351 139.636 102.74C140.635 101.138 140.517 99.8178 139.393 99.8021C138.268 99.7785 136.538 101.067 135.524 102.678Z" fill="#19B8F7" />
      <path d="M129.037 102.591C128.007 104.202 128.086 105.522 129.218 105.537C130.35 105.553 132.088 104.257 133.102 102.646C134.117 101.036 134.03 99.7235 132.913 99.7078C131.797 99.6921 130.067 100.981 129.037 102.591Z" fill="#19B8F7" />
      <path d="M122.621 102.536C121.575 104.147 121.63 105.467 122.746 105.474C123.863 105.482 125.609 104.178 126.639 102.568C127.669 100.957 127.606 99.6449 126.505 99.637C125.404 99.6291 123.666 100.925 122.621 102.536Z" fill="#19B8F7" />
      <path d="M109.985 102.505C108.915 104.123 108.923 105.435 110.016 105.435C111.109 105.435 112.863 104.123 113.924 102.505C114.986 100.886 114.97 99.582 113.885 99.582C112.8 99.582 111.062 100.886 109.985 102.505Z" fill="#19B8F7" />
      <path d="M438.74 126.915C438.897 128.518 440.226 130.066 441.712 130.364C443.206 130.663 444.276 129.61 444.095 127.999C443.922 126.396 442.577 124.857 441.107 124.558C439.636 124.26 438.575 125.312 438.732 126.915H438.74Z" fill="#19B8F7" />
      <path d="M404.779 120.41C404.811 122.005 406.022 123.521 407.5 123.788C408.978 124.055 410.134 122.979 410.087 121.376C410.032 119.781 408.805 118.273 407.351 117.998C405.896 117.731 404.748 118.799 404.779 120.402V120.41Z" fill="#19B8F7" />
      <path d="M396.35 118.893C396.35 120.488 397.538 121.997 399.008 122.256C400.478 122.515 401.666 121.431 401.642 119.836C401.618 118.241 400.423 116.741 398.976 116.482C397.53 116.222 396.358 117.299 396.358 118.893H396.35Z" fill="#19B8F7" />
      <path d="M387.952 117.432C387.921 119.027 389.077 120.528 390.539 120.779C392.01 121.031 393.213 119.938 393.221 118.351C393.229 116.757 392.065 115.264 390.618 115.012C389.171 114.761 387.984 115.845 387.945 117.44L387.952 117.432Z" fill="#19B8F7" />
      <path d="M379.594 116.01C379.523 117.605 380.648 119.098 382.11 119.341C383.573 119.585 384.799 118.493 384.839 116.898C384.878 115.303 383.746 113.818 382.307 113.575C380.868 113.331 379.657 114.423 379.586 116.01H379.594Z" fill="#19B8F7" />
      <path d="M354.731 112.035C354.566 113.622 355.596 115.091 357.035 115.311C358.474 115.531 359.763 114.423 359.905 112.836C360.046 111.249 359.008 109.788 357.593 109.568C356.178 109.348 354.896 110.456 354.731 112.035Z" fill="#19B8F7" />
      <path d="M330.277 108.484C330.009 110.063 330.937 111.508 332.353 111.705C333.768 111.901 335.12 110.77 335.356 109.191C335.6 107.612 334.656 106.174 333.265 105.977C331.873 105.781 330.536 106.904 330.269 108.484H330.277Z" fill="#19B8F7" />
      <path d="M322.225 107.391C321.926 108.971 322.815 110.408 324.222 110.597C325.63 110.785 326.998 109.654 327.273 108.075C327.548 106.496 326.644 105.066 325.26 104.877C323.876 104.689 322.524 105.812 322.217 107.391H322.225Z" fill="#19B8F7" />
      <path d="M314.228 106.347C313.898 107.926 314.755 109.356 316.147 109.536C317.546 109.717 318.93 108.578 319.245 106.999C319.552 105.42 318.687 103.997 317.311 103.825C315.935 103.644 314.558 104.775 314.228 106.354V106.347Z" fill="#19B8F7" />
      <path d="M306.286 105.349C305.917 106.928 306.742 108.35 308.134 108.523C309.526 108.696 310.934 107.549 311.272 105.977C311.61 104.398 310.776 102.992 309.416 102.819C308.056 102.646 306.656 103.785 306.286 105.357V105.349Z" fill="#19B8F7" />
      <path d="M298.4 104.398C297.999 105.969 298.785 107.391 300.169 107.549C301.545 107.714 302.976 106.567 303.346 104.987C303.723 103.408 302.921 102.01 301.569 101.845C300.216 101.68 298.801 102.819 298.4 104.398Z" fill="#19B8F7" />
      <path d="M290.568 103.487C290.136 105.058 290.891 106.472 292.259 106.622C293.627 106.779 295.074 105.624 295.483 104.052C295.891 102.481 295.129 101.083 293.784 100.926C292.44 100.768 291.008 101.915 290.576 103.487H290.568Z" fill="#19B8F7" />
      <path d="M282.799 102.63C282.336 104.202 283.051 105.6 284.411 105.75C285.772 105.899 287.234 104.736 287.675 103.165C288.115 101.593 287.384 100.203 286.055 100.054C284.718 99.9042 283.271 101.059 282.807 102.63H282.799Z" fill="#19B8F7" />
      <path d="M275.094 101.805C274.598 103.377 275.282 104.767 276.627 104.909C277.971 105.05 279.458 103.887 279.929 102.308C280.401 100.737 279.709 99.3542 278.388 99.2128C277.067 99.0713 275.597 100.226 275.101 101.798L275.094 101.805Z" fill="#19B8F7" />
      <path d="M267.443 101.036C266.916 102.607 267.561 103.997 268.897 104.123C270.234 104.257 271.736 103.086 272.239 101.515C272.743 99.9435 272.082 98.5686 270.777 98.435C269.464 98.3014 267.978 99.4642 267.451 101.036H267.443Z" fill="#19B8F7" />
      <path d="M259.863 100.297C259.305 101.868 259.918 103.251 261.239 103.377C262.56 103.502 264.077 102.324 264.62 100.753C265.155 99.1814 264.533 97.8143 263.236 97.6886C261.939 97.5629 260.429 98.7257 259.871 100.297H259.863Z" fill="#19B8F7" />
      <path d="M252.346 99.6134C251.756 101.185 252.33 102.56 253.643 102.677C254.956 102.795 256.49 101.617 257.056 100.046C257.622 98.4742 257.032 97.115 255.743 96.9972C254.453 96.8793 252.935 98.05 252.338 99.6213L252.346 99.6134Z" fill="#19B8F7" />
      <path d="M244.899 98.9614C244.278 100.533 244.821 101.9 246.118 102.01C247.423 102.12 248.965 100.933 249.57 99.3621C250.168 97.7907 249.617 96.4316 248.336 96.3216C247.054 96.2116 245.521 97.3901 244.899 98.9535V98.9614Z" fill="#19B8F7" />
      <path d="M237.516 98.3485C236.863 99.9199 237.374 101.279 238.664 101.381C239.954 101.483 241.518 100.289 242.147 98.7257C242.776 97.1543 242.257 95.803 240.991 95.7009C239.725 95.5987 238.169 96.7772 237.516 98.3485Z" fill="#19B8F7" />
      <path d="M230.196 97.783C229.511 99.3543 229.991 100.706 231.265 100.808C232.547 100.902 234.119 99.7078 234.78 98.1365C235.44 96.5652 234.953 95.2217 233.695 95.1274C232.436 95.0332 230.872 96.2195 230.188 97.7908L230.196 97.783Z" fill="#19B8F7" />
      <path d="M222.946 97.2565C222.23 98.8278 222.678 100.179 223.944 100.266C225.21 100.36 226.806 99.1499 227.491 97.5786C228.182 96.0073 227.726 94.6716 226.484 94.5774C225.242 94.4831 223.661 95.6773 222.946 97.2486V97.2565Z" fill="#19B8F7" />
      <path d="M215.759 96.7616C215.02 98.3329 215.429 99.6764 216.679 99.7628C217.929 99.8492 219.541 98.6393 220.257 97.068C220.98 95.4967 220.555 94.1611 219.321 94.0825C218.086 93.9961 216.49 95.1981 215.751 96.7695L215.759 96.7616Z" fill="#19B8F7" />
      <path d="M208.651 96.3059C207.88 97.8772 208.257 99.2207 209.5 99.2992C210.742 99.3778 212.362 98.16 213.109 96.5887C213.856 95.0174 213.471 93.6896 212.244 93.6111C211.025 93.5325 209.413 94.7346 208.643 96.3059H208.651Z" fill="#19B8F7" />
      <path d="M201.605 95.8895C200.811 97.4608 201.157 98.7964 202.384 98.8671C203.61 98.9378 205.246 97.7201 206.024 96.1488C206.803 94.5774 206.441 93.2497 205.238 93.179C204.027 93.1083 202.407 94.3182 201.613 95.8895H201.605Z" fill="#19B8F7" />
      <path d="M194.623 95.5124C193.797 97.0837 194.112 98.4193 195.331 98.4822C196.549 98.545 198.193 97.3194 198.995 95.7481C199.797 94.1768 199.474 92.8569 198.271 92.7862C197.076 92.7233 195.441 93.9332 194.615 95.5124H194.623Z" fill="#19B8F7" />
      <path d="M187.711 95.1666C186.862 96.7379 187.145 98.0736 188.348 98.1285C189.551 98.1835 191.21 96.9501 192.044 95.3787C192.877 93.8074 192.578 92.4875 191.391 92.4247C190.204 92.3618 188.56 93.5875 187.711 95.1588V95.1666Z" fill="#19B8F7" />
      <path d="M504.963 132.941C505.356 134.536 506.866 136.131 508.328 136.484C509.791 136.838 510.648 135.832 510.231 134.23C509.814 132.627 508.305 131.048 506.858 130.694C505.411 130.341 504.57 131.346 504.963 132.941Z" fill="#19B8F7" />
      <path d="M496.565 130.922C496.935 132.517 498.421 134.104 499.883 134.449C501.346 134.803 502.226 133.79 501.833 132.187C501.44 130.584 499.954 129.013 498.507 128.659C497.06 128.314 496.195 129.319 496.565 130.914V130.922Z" fill="#19B8F7" />
      <path d="M404.559 111.438C404.606 113.017 405.802 114.51 407.248 114.785C408.695 115.052 409.82 113.983 409.749 112.412C409.678 110.833 408.467 109.34 407.044 109.073C405.621 108.806 404.512 109.866 404.551 111.438H404.559Z" fill="#19B8F7" />
      <path d="M363.687 104.398C363.569 105.962 364.607 107.423 366.022 107.643C367.438 107.871 368.672 106.779 368.766 105.215C368.861 103.652 367.807 102.206 366.415 101.978C365.024 101.751 363.805 102.835 363.687 104.398Z" fill="#19B8F7" />
      <path d="M355.627 103.133C355.47 104.697 356.484 106.142 357.892 106.362C359.299 106.582 360.55 105.482 360.683 103.919C360.809 102.355 359.787 100.918 358.411 100.698C357.027 100.478 355.785 101.562 355.635 103.125L355.627 103.133Z" fill="#19B8F7" />
      <path d="M347.623 101.915C347.434 103.479 348.409 104.917 349.809 105.129C351.208 105.341 352.482 104.241 352.647 102.678C352.812 101.114 351.821 99.6842 350.445 99.48C349.069 99.2678 347.811 100.36 347.623 101.923V101.915Z" fill="#19B8F7" />
      <path d="M339.657 100.745C339.437 102.3 340.381 103.738 341.773 103.935C343.164 104.139 344.462 103.031 344.658 101.475C344.855 99.9199 343.903 98.4979 342.535 98.2936C341.167 98.0893 339.885 99.1892 339.665 100.745H339.657Z" fill="#19B8F7" />
      <path d="M331.739 99.6134C331.488 101.169 332.392 102.591 333.776 102.787C335.16 102.984 336.473 101.876 336.701 100.313C336.929 98.7571 336.009 97.3429 334.649 97.1465C333.288 96.9501 331.991 98.05 331.731 99.6056L331.739 99.6134Z" fill="#19B8F7" />
      <path d="M323.876 98.5372C323.585 100.093 324.458 101.507 325.834 101.696C327.21 101.884 328.539 100.768 328.806 99.2129C329.074 97.6573 328.185 96.2509 326.833 96.0702C325.48 95.8817 324.159 96.9894 323.868 98.545L323.876 98.5372Z" fill="#19B8F7" />
      <path d="M316.068 97.5C315.746 99.0478 316.587 100.462 317.947 100.635C319.316 100.815 320.668 99.692 320.967 98.1443C321.266 96.5887 320.416 95.1981 319.072 95.0174C317.735 94.8367 316.39 95.9444 316.068 97.5Z" fill="#19B8F7" />
      <path d="M308.315 96.5102C307.961 98.0579 308.763 99.4564 310.116 99.6292C311.468 99.8021 312.844 98.6786 313.175 97.123C313.505 95.5753 312.687 94.1846 311.358 94.0118C310.029 93.839 308.669 94.9546 308.307 96.5023L308.315 96.5102Z" fill="#19B8F7" />
      <path d="M300.617 95.5673C300.232 97.1151 300.995 98.5057 302.347 98.6707C303.692 98.8357 305.083 97.7043 305.453 96.1566C305.815 94.6088 305.036 93.2261 303.715 93.0689C302.394 92.9039 301.01 94.0274 300.625 95.5752L300.617 95.5673Z" fill="#19B8F7" />
      <path d="M292.974 94.6717C292.55 96.2195 293.289 97.6022 294.626 97.7593C295.962 97.9165 297.37 96.7773 297.771 95.2295C298.172 93.6818 297.425 92.3069 296.112 92.1576C294.798 92.0005 293.399 93.124 292.982 94.6717H292.974Z" fill="#19B8F7" />
      <path d="M285.386 93.8153C284.93 95.3631 285.63 96.738 286.959 96.8873C288.288 97.0365 289.711 95.8973 290.144 94.3496C290.576 92.8018 289.86 91.4348 288.563 91.2934C287.266 91.1441 285.842 92.2755 285.386 93.8153Z" fill="#19B8F7" />
      <path d="M277.869 92.9982C277.382 94.5381 278.042 95.913 279.363 96.0544C280.676 96.1958 282.123 95.0488 282.587 93.501C283.051 91.9533 282.375 90.5941 281.085 90.4605C279.796 90.3191 278.357 91.4505 277.869 92.9982Z" fill="#19B8F7" />
      <path d="M270.407 92.2283C269.888 93.7682 270.517 95.1352 271.823 95.2688C273.128 95.4023 274.59 94.2474 275.086 92.7075C275.581 91.1598 274.936 89.8085 273.662 89.6828C272.381 89.5492 270.926 90.6884 270.407 92.2283Z" fill="#19B8F7" />
      <path d="M263 91.4977C262.45 93.0376 263.047 94.3967 264.337 94.5224C265.626 94.6481 267.113 93.4932 267.639 91.9455C268.166 90.4056 267.561 89.0543 266.287 88.9364C265.013 88.8107 263.543 89.9578 262.992 91.4977H263Z" fill="#19B8F7" />
      <path d="M255.664 90.8142C255.082 92.354 255.64 93.7054 256.922 93.8232C258.204 93.9411 259.706 92.7783 260.264 91.2384C260.822 89.6985 260.248 88.3551 258.99 88.2372C257.732 88.1194 256.246 89.2664 255.664 90.8063V90.8142Z" fill="#19B8F7" />
      <path d="M248.391 90.162C247.777 91.7019 248.304 93.0532 249.578 93.1632C250.852 93.2732 252.361 92.1104 252.959 90.5627C253.549 89.0228 253.014 87.6872 251.764 87.5772C250.514 87.4672 249.012 88.6221 248.398 90.162H248.391Z" fill="#19B8F7" />
      <path d="M241.172 89.5571C240.528 91.097 241.023 92.4404 242.281 92.5426C243.539 92.6447 245.072 91.4741 245.694 89.9342C246.315 88.3943 245.812 87.0665 244.577 86.9644C243.343 86.8623 241.817 88.0172 241.172 89.5571Z" fill="#19B8F7" />
      <path d="M234.025 88.9914C233.348 90.5313 233.812 91.8669 235.055 91.9691C236.305 92.0633 237.854 90.8927 238.507 89.345C239.159 87.8051 238.688 86.4852 237.461 86.383C236.234 86.2888 234.701 87.4515 234.025 88.9914Z" fill="#19B8F7" />
      <path d="M212.967 87.5066C212.205 89.0464 212.566 90.3742 213.785 90.4528C214.996 90.5313 216.592 89.3371 217.331 87.7973C218.071 86.2574 217.701 84.9453 216.506 84.8667C215.311 84.7882 213.73 85.9667 212.975 87.5144L212.967 87.5066Z" fill="#19B8F7" />
      <path d="M206.072 87.0823C205.285 88.63 205.615 89.9421 206.819 90.0128C208.022 90.0835 209.626 88.8814 210.396 87.3415C211.167 85.7938 210.821 84.4975 209.641 84.4189C208.462 84.3482 206.866 85.5345 206.079 87.0744L206.072 87.0823Z" fill="#19B8F7" />
      <path d="M199.246 86.6894C198.429 88.2371 198.735 89.5492 199.923 89.612C201.118 89.6749 202.73 88.4728 203.524 86.9251C204.318 85.3774 204.004 84.081 202.832 84.0103C201.66 83.9396 200.056 85.1338 199.239 86.6815L199.246 86.6894Z" fill="#19B8F7" />
      <path d="M510.907 125.996C511.348 127.583 512.857 129.162 514.296 129.531C515.735 129.893 516.53 128.895 516.073 127.308C515.617 125.721 514.092 124.142 512.677 123.78C511.261 123.419 510.467 124.409 510.907 125.996Z" fill="#19B8F7" />
      <path d="M502.667 123.945C503.076 125.532 504.578 127.104 506.017 127.465C507.455 127.819 508.273 126.821 507.841 125.234C507.408 123.647 505.906 122.075 504.483 121.722C503.06 121.368 502.25 122.366 502.659 123.945H502.667Z" fill="#19B8F7" />
      <path d="M494.418 121.926C494.804 123.505 496.282 125.077 497.721 125.43C499.16 125.776 500.001 124.778 499.6 123.191C499.199 121.604 497.713 120.041 496.29 119.695C494.867 119.349 494.033 120.347 494.418 121.934V121.926Z" fill="#19B8F7" />
      <path d="M428.636 107.321C428.785 108.884 430.067 110.393 431.498 110.676C432.929 110.966 433.943 109.929 433.77 108.366C433.597 106.802 432.308 105.302 430.9 105.019C429.493 104.728 428.487 105.757 428.636 107.321Z" fill="#19B8F7" />
      <path d="M420.482 105.694C420.6 107.25 421.85 108.751 423.273 109.034C424.697 109.316 425.742 108.271 425.593 106.708C425.451 105.145 424.193 103.66 422.786 103.377C421.386 103.094 420.356 104.131 420.474 105.694H420.482Z" fill="#19B8F7" />
      <path d="M412.359 104.115C412.446 105.671 413.665 107.164 415.088 107.431C416.511 107.706 417.573 106.661 417.463 105.097C417.352 103.542 416.118 102.057 414.726 101.79C413.327 101.515 412.273 102.56 412.367 104.115H412.359Z" fill="#19B8F7" />
      <path d="M404.268 102.591C404.323 104.147 405.511 105.624 406.926 105.891C408.341 106.158 409.426 105.105 409.348 103.55C409.269 101.994 408.066 100.525 406.674 100.266C405.283 100.006 404.205 101.043 404.26 102.599L404.268 102.591Z" fill="#19B8F7" />
      <path d="M396.201 101.098C396.224 102.646 397.38 104.115 398.796 104.375C400.203 104.634 401.312 103.573 401.265 102.026C401.217 100.47 400.046 99.0164 398.662 98.7572C397.278 98.5058 396.177 99.5507 396.201 101.098Z" fill="#19B8F7" />
      <path d="M388.173 99.6607C388.165 101.208 389.289 102.67 390.697 102.921C392.096 103.173 393.228 102.112 393.213 100.564C393.197 99.0164 392.057 97.563 390.681 97.3194C389.305 97.0758 388.18 98.1208 388.173 99.6685V99.6607Z" fill="#19B8F7" />
      <path d="M364.324 95.6302C364.214 97.1701 365.244 98.6 366.628 98.8278C368.012 99.0557 369.207 97.9793 369.285 96.4394C369.372 94.8995 368.334 93.4775 366.974 93.2575C365.613 93.0375 364.434 94.0982 364.324 95.6302Z" fill="#19B8F7" />
      <path d="M356.461 94.381C356.319 95.913 357.31 97.3429 358.686 97.555C360.062 97.775 361.281 96.6987 361.391 95.1588C361.509 93.6189 360.502 92.2047 359.15 91.9926C357.797 91.7804 356.594 92.8411 356.453 94.3731L356.461 94.381Z" fill="#19B8F7" />
      <path d="M348.637 93.1711C348.464 94.7031 349.423 96.1173 350.791 96.3295C352.16 96.5416 353.394 95.4574 353.544 93.9253C353.693 92.3933 352.726 90.987 351.381 90.7827C350.037 90.5784 348.81 91.6469 348.637 93.179V93.1711Z" fill="#19B8F7" />
      <path d="M340.868 92.0162C340.656 93.5404 341.584 94.9546 342.944 95.151C344.304 95.3474 345.563 94.2632 345.743 92.739C345.932 91.207 344.989 89.8085 343.66 89.6121C342.323 89.4157 341.081 90.4842 340.868 92.0162Z" fill="#19B8F7" />
      <path d="M333.139 90.9006C332.895 92.4247 333.784 93.8232 335.136 94.0196C336.489 94.216 337.762 93.124 337.983 91.5919C338.203 90.0599 337.298 88.6772 335.97 88.4886C334.641 88.3 333.375 89.3764 333.131 90.9006H333.139Z" fill="#19B8F7" />
      <path d="M325.472 89.8399C325.197 91.3641 326.054 92.7547 327.391 92.9432C328.735 93.1239 330.033 92.0319 330.285 90.5077C330.536 88.9835 329.671 87.6008 328.35 87.4201C327.037 87.2394 325.748 88.3157 325.472 89.8399Z" fill="#19B8F7" />
      <path d="M317.853 88.8107C317.538 90.3349 318.364 91.7176 319.693 91.8905C321.022 92.0633 322.343 90.9712 322.634 89.4471C322.917 87.9229 322.083 86.5559 320.778 86.3752C319.473 86.2023 318.168 87.2865 317.853 88.8028V88.8107Z" fill="#19B8F7" />
      <path d="M310.281 87.8365C309.935 89.3528 310.721 90.7277 312.042 90.9006C313.363 91.0734 314.7 89.9657 315.022 88.4493C315.345 86.9252 314.543 85.566 313.245 85.401C311.948 85.236 310.619 86.3202 310.273 87.8444L310.281 87.8365Z" fill="#19B8F7" />
      <path d="M302.772 86.9016C302.394 88.4179 303.149 89.7849 304.462 89.9499C305.775 90.1149 307.136 89.0072 307.489 87.483C307.843 85.9667 307.081 84.6075 305.791 84.4503C304.502 84.2932 303.157 85.3853 302.78 86.9016H302.772Z" fill="#19B8F7" />
      <path d="M295.317 86.0138C294.909 87.5301 295.624 88.8893 296.929 89.0464C298.235 89.2035 299.611 88.0879 299.996 86.5716C300.381 85.0553 299.65 83.7118 298.376 83.5547C297.095 83.4054 295.734 84.4975 295.325 86.0138H295.317Z" fill="#19B8F7" />
      <path d="M287.926 85.1652C287.478 86.6816 288.162 88.0329 289.46 88.1822C290.749 88.3314 292.149 87.2079 292.565 85.6995C292.982 84.1832 292.29 82.8476 291.024 82.6983C289.758 82.5569 288.374 83.6568 287.926 85.1731V85.1652Z" fill="#19B8F7" />
      <path d="M280.582 84.3561C280.102 85.8724 280.755 87.2158 282.037 87.3573C283.318 87.4987 284.734 86.3752 285.19 84.8589C285.646 83.3426 284.985 82.0148 283.727 81.8734C282.469 81.732 281.07 82.8397 280.59 84.3561H280.582Z" fill="#19B8F7" />
      <path d="M273.301 83.5939C272.79 85.1102 273.403 86.4458 274.677 86.5794C275.951 86.713 277.382 85.5816 277.869 84.0732C278.357 82.5568 277.728 81.2369 276.478 81.1034C275.227 80.9698 273.812 82.0854 273.301 83.5939Z" fill="#19B8F7" />
      <path d="M266.083 82.8711C265.54 84.3796 266.122 85.7152 267.38 85.8409C268.646 85.9666 270.093 84.8353 270.612 83.319C271.131 81.8105 270.541 80.4906 269.299 80.3649C268.064 80.2392 266.625 81.3548 266.083 82.8711Z" fill="#19B8F7" />
      <path d="M258.919 82.1798C258.345 83.6883 258.888 85.016 260.146 85.1339C261.396 85.2517 262.867 84.1125 263.417 82.6041C263.967 81.0956 263.417 79.7836 262.182 79.6657C260.956 79.5479 259.501 80.6714 258.927 82.1798H258.919Z" fill="#19B8F7" />
      <path d="M251.819 81.5355C251.214 83.044 251.725 84.3717 252.967 84.4817C254.209 84.5917 255.688 83.4525 256.269 81.944C256.851 80.4356 256.325 79.1235 255.114 79.0135C253.895 78.9035 252.424 80.027 251.819 81.5434V81.5355Z" fill="#19B8F7" />
      <path d="M244.781 80.9306C244.145 82.439 244.624 83.7589 245.851 83.8611C247.078 83.9632 248.579 82.8161 249.193 81.3077C249.806 79.7992 249.318 78.495 248.108 78.3929C246.897 78.2907 245.411 79.4221 244.781 80.9306Z" fill="#19B8F7" />
      <path d="M224.023 79.3279C223.3 80.8442 223.677 82.1405 224.88 82.2269C226.075 82.3134 227.624 81.1506 228.324 79.6343C229.024 78.1258 228.631 76.8373 227.459 76.7509C226.28 76.6645 224.746 77.8116 224.023 79.3279Z" fill="#19B8F7" />
      <path d="M217.229 78.8642C216.482 80.3805 216.828 81.6769 218.016 81.7554C219.203 81.834 220.76 80.6634 221.491 79.1549C222.222 77.6386 221.861 76.358 220.697 76.2794C219.533 76.2009 217.984 77.3558 217.229 78.8642Z" fill="#19B8F7" />
      <path d="M596.041 141.835C596.717 143.414 598.36 145.04 599.705 145.457C601.057 145.873 601.592 144.93 600.9 143.351C600.216 141.772 598.573 140.146 597.236 139.729C595.899 139.313 595.365 140.256 596.041 141.835Z" fill="#19B8F7" />
      <path d="M588.257 139.438C588.917 141.025 590.553 142.644 591.913 143.06C593.273 143.477 593.831 142.526 593.155 140.947C592.487 139.368 590.843 137.741 589.499 137.333C588.154 136.916 587.596 137.867 588.257 139.446V139.438Z" fill="#19B8F7" />
      <path d="M516.467 119.145C516.946 120.716 518.472 122.295 519.879 122.665C521.287 123.034 522.034 122.052 521.538 120.473C521.043 118.894 519.51 117.322 518.118 116.961C516.726 116.592 515.987 117.574 516.459 119.145H516.467Z" fill="#19B8F7" />
      <path d="M508.383 117.063C508.832 118.634 510.341 120.206 511.757 120.567C513.172 120.928 513.935 119.938 513.463 118.367C512.991 116.796 511.481 115.225 510.082 114.871C508.69 114.51 507.927 115.492 508.375 117.063H508.383Z" fill="#19B8F7" />
      <path d="M500.292 115.02C500.717 116.592 502.203 118.155 503.618 118.509C505.034 118.862 505.82 117.872 505.372 116.301C504.924 114.73 503.43 113.174 502.038 112.82C500.646 112.467 499.86 113.457 500.284 115.02H500.292Z" fill="#19B8F7" />
      <path d="M492.193 113.017C492.594 114.588 494.057 116.136 495.472 116.489C496.888 116.835 497.697 115.845 497.281 114.274C496.864 112.703 495.386 111.155 493.994 110.809C492.602 110.463 491.8 111.453 492.193 113.017Z" fill="#19B8F7" />
      <path d="M484.094 111.053C484.464 112.616 485.911 114.164 487.326 114.502C488.741 114.84 489.575 113.842 489.182 112.278C488.789 110.715 487.334 109.175 485.942 108.837C484.55 108.499 483.725 109.489 484.094 111.053Z" fill="#19B8F7" />
      <path d="M476.003 109.136C476.349 110.699 477.772 112.239 479.18 112.569C480.595 112.899 481.444 111.901 481.083 110.338C480.713 108.774 479.29 107.242 477.898 106.912C476.506 106.582 475.657 107.572 476.003 109.136Z" fill="#19B8F7" />
      <path d="M427.724 98.5529C427.889 100.093 429.155 101.578 430.547 101.86C431.946 102.143 432.929 101.122 432.74 99.5821C432.552 98.0422 431.278 96.5652 429.902 96.2823C428.526 95.9995 427.551 97.013 427.716 98.5529H427.724Z" fill="#19B8F7" />
      <path d="M419.751 96.9501C419.884 98.4821 421.119 99.9592 422.511 100.234C423.902 100.509 424.909 99.4878 424.752 97.9479C424.594 96.408 423.352 94.9467 421.984 94.6717C420.616 94.3967 419.617 95.4102 419.751 96.9501Z" fill="#19B8F7" />
      <path d="M411.809 95.3867C411.911 96.9187 413.114 98.38 414.506 98.655C415.898 98.9221 416.92 97.8929 416.794 96.3609C416.668 94.8288 415.457 93.3754 414.089 93.1082C412.721 92.8411 411.707 93.8625 411.809 95.3867Z" fill="#19B8F7" />
      <path d="M403.899 93.8782C403.969 95.4023 405.141 96.8637 406.525 97.1229C407.909 97.3822 408.963 96.353 408.868 94.8209C408.774 93.2889 407.594 91.8433 406.234 91.584C404.874 91.3248 403.836 92.3461 403.907 93.8703L403.899 93.8782Z" fill="#19B8F7" />
      <path d="M396.02 92.4169C396.059 93.9411 397.199 95.3867 398.575 95.6381C399.951 95.8895 401.029 94.8525 400.966 93.3283C400.903 91.8041 399.755 90.3664 398.402 90.115C397.05 89.8635 395.988 90.8928 396.028 92.4091L396.02 92.4169Z" fill="#19B8F7" />
      <path d="M388.18 90.9948C388.18 92.5111 389.289 93.9489 390.665 94.1924C392.033 94.436 393.134 93.3989 393.103 91.8747C393.071 90.3506 391.955 88.9285 390.61 88.685C389.266 88.4414 388.18 89.4706 388.18 90.9869V90.9948Z" fill="#19B8F7" />
      <path d="M372.612 88.3C372.549 89.8085 373.594 91.2305 374.947 91.4583C376.307 91.6862 377.439 90.6413 377.479 89.1249C377.518 87.6086 376.464 86.2102 375.128 85.9823C373.799 85.7545 372.667 86.7915 372.604 88.3H372.612Z" fill="#19B8F7" />
      <path d="M364.89 87.0194C364.796 88.5279 365.802 89.9342 367.154 90.1542C368.507 90.3742 369.663 89.3214 369.734 87.813C369.804 86.3045 368.782 84.906 367.461 84.686C366.14 84.4661 364.992 85.5031 364.89 87.0116V87.0194Z" fill="#19B8F7" />
      <path d="M349.588 84.5996C349.423 86.1002 350.367 87.4908 351.704 87.695C353.04 87.8993 354.236 86.8387 354.377 85.3381C354.519 83.8375 353.559 82.4626 352.254 82.2583C350.949 82.054 349.754 83.0989 349.588 84.5996Z" fill="#19B8F7" />
      <path d="M342.008 83.4603C341.812 84.9609 342.716 86.3358 344.045 86.5401C345.374 86.7365 346.593 85.6759 346.766 84.1753C346.939 82.6747 346.019 81.3076 344.721 81.1112C343.424 80.9148 342.213 81.9676 342.016 83.4603H342.008Z" fill="#19B8F7" />
      <path d="M326.998 81.3077C326.73 82.8005 327.572 84.1597 328.877 84.3482C330.182 84.5289 331.448 83.4604 331.692 81.9677C331.936 80.4749 331.079 79.1236 329.797 78.9429C328.515 78.7622 327.265 79.815 326.998 81.3077Z" fill="#19B8F7" />
      <path d="M319.567 80.302C319.268 81.7869 320.07 83.1461 321.368 83.319C322.665 83.4918 323.947 82.4154 324.222 80.9306C324.497 79.4378 323.68 78.1022 322.406 77.9293C321.132 77.7565 319.858 78.8171 319.559 80.302H319.567Z" fill="#19B8F7" />
      <path d="M312.192 79.3357C311.861 80.8206 312.624 82.1719 313.914 82.3369C315.203 82.5019 316.508 81.4255 316.815 79.9406C317.122 78.4558 316.343 77.1201 315.077 76.9551C313.811 76.7902 312.522 77.8508 312.192 79.3357Z" fill="#19B8F7" />
      <path d="M304.871 78.4165C304.502 79.9014 305.241 81.237 306.522 81.402C307.804 81.5591 309.125 80.4749 309.463 78.99C309.809 77.5051 309.062 76.1852 307.804 76.0202C306.546 75.8631 305.241 76.9316 304.871 78.4165Z" fill="#19B8F7" />
      <path d="M297.606 77.5365C297.205 79.0214 297.904 80.3492 299.178 80.5063C300.452 80.6556 301.789 79.5714 302.166 78.0865C302.544 76.6016 301.828 75.2895 300.586 75.1403C299.343 74.991 298.015 76.0595 297.614 77.5444L297.606 77.5365Z" fill="#19B8F7" />
      <path d="M283.24 75.9023C282.776 77.3794 283.405 78.6993 284.655 78.8328C285.905 78.9743 287.281 77.8743 287.73 76.3973C288.17 74.9203 287.525 73.6161 286.298 73.4825C285.072 73.3489 283.704 74.4253 283.24 75.9023Z" fill="#19B8F7" />
      <path d="M276.139 75.1403C275.644 76.6173 276.234 77.9294 277.476 78.0629C278.718 78.1965 280.11 77.0966 280.59 75.6117C281.062 74.1346 280.456 72.8383 279.237 72.7126C278.019 72.5869 276.635 73.6633 276.139 75.1403Z" fill="#19B8F7" />
      <path d="M262.119 73.7418C261.561 75.2189 262.088 76.5152 263.307 76.633C264.526 76.7509 265.957 75.6431 266.499 74.1661C267.034 72.689 266.499 71.4084 265.296 71.2906C264.101 71.1727 262.678 72.2648 262.119 73.7418Z" fill="#19B8F7" />
      <path d="M255.192 73.0975C254.602 74.5746 255.098 75.8709 256.309 75.9809C257.52 76.0909 258.966 74.9753 259.54 73.4982C260.107 72.0212 259.603 70.7484 258.416 70.6384C257.229 70.5284 255.79 71.6284 255.2 73.0975H255.192Z" fill="#19B8F7" />
      <path d="M234.764 71.377C234.08 72.854 234.481 74.1346 235.66 74.2289C236.84 74.3232 238.342 73.1918 239.002 71.7148C239.663 70.2377 239.254 68.9728 238.098 68.8864C236.942 68.7921 235.456 69.9078 234.772 71.3848L234.764 71.377Z" fill="#19B8F7" />
      <path d="M614.983 140.24C615.706 141.811 617.35 143.437 618.655 143.869C619.96 144.302 620.416 143.375 619.685 141.803C618.954 140.232 617.311 138.606 616.021 138.173C614.724 137.741 614.26 138.668 614.983 140.24Z" fill="#19B8F7" />
      <path d="M607.442 137.773C608.15 139.344 609.801 140.97 611.114 141.395C612.428 141.819 612.907 140.892 612.192 139.313C611.468 137.741 609.833 136.115 608.527 135.691C607.222 135.267 606.742 136.194 607.45 137.765L607.442 137.773Z" fill="#19B8F7" />
      <path d="M599.847 135.329C600.546 136.909 602.182 138.527 603.511 138.951C604.832 139.376 605.335 138.441 604.627 136.861C603.92 135.282 602.276 133.664 600.963 133.24C599.65 132.815 599.147 133.75 599.847 135.329Z" fill="#19B8F7" />
      <path d="M592.196 132.902C592.88 134.481 594.515 136.099 595.852 136.516C597.189 136.932 597.708 135.997 597.008 134.418C596.316 132.839 594.681 131.22 593.352 130.804C592.031 130.388 591.512 131.323 592.196 132.902Z" fill="#19B8F7" />
      <path d="M529.606 114.533C530.14 116.097 531.689 117.676 533.073 118.053C534.457 118.43 535.133 117.464 534.575 115.9C534.025 114.329 532.468 112.758 531.1 112.38C529.732 112.003 529.063 112.97 529.598 114.533H529.606Z" fill="#19B8F7" />
      <path d="M521.672 112.388C522.183 113.952 523.716 115.523 525.1 115.892C526.484 116.262 527.184 115.295 526.649 113.732C526.122 112.168 524.581 110.605 523.213 110.228C521.845 109.858 521.153 110.825 521.664 112.388H521.672Z" fill="#19B8F7" />
      <path d="M513.73 110.283C514.218 111.846 515.735 113.402 517.119 113.771C518.503 114.132 519.219 113.166 518.715 111.603C518.212 110.039 516.687 108.484 515.319 108.122C513.95 107.761 513.243 108.727 513.73 110.291V110.283Z" fill="#19B8F7" />
      <path d="M505.788 108.216C506.252 109.772 507.746 111.328 509.138 111.689C510.522 112.05 511.261 111.068 510.782 109.513C510.302 107.957 508.792 106.402 507.424 106.048C506.056 105.694 505.325 106.661 505.788 108.216Z" fill="#19B8F7" />
      <path d="M497.839 106.189C498.279 107.745 499.758 109.293 501.141 109.638C502.533 109.992 503.288 109.01 502.832 107.454C502.376 105.899 500.89 104.359 499.522 104.013C498.153 103.667 497.399 104.634 497.839 106.189Z" fill="#19B8F7" />
      <path d="M489.889 104.21C490.298 105.757 491.761 107.297 493.145 107.643C494.536 107.989 495.315 107.006 494.882 105.451C494.45 103.895 492.987 102.371 491.619 102.025C490.251 101.688 489.48 102.662 489.889 104.21Z" fill="#19B8F7" />
      <path d="M481.948 102.269C482.333 103.817 483.764 105.341 485.156 105.679C486.54 106.017 487.342 105.027 486.941 103.479C486.54 101.931 485.093 100.407 483.725 100.077C482.356 99.7392 481.562 100.721 481.948 102.269Z" fill="#19B8F7" />
      <path d="M474.014 100.368C474.368 101.908 475.783 103.424 477.167 103.754C478.551 104.084 479.376 103.094 478.999 101.554C478.622 100.014 477.206 98.4979 475.838 98.1758C474.47 97.8537 473.66 98.8279 474.014 100.368Z" fill="#19B8F7" />
      <path d="M418.949 88.3472C419.098 89.8557 420.309 91.3013 421.677 91.5763C423.037 91.8512 424.012 90.8456 423.839 89.3293C423.666 87.8208 422.44 86.3831 421.103 86.1081C419.766 85.841 418.799 86.8309 418.949 88.3393V88.3472Z" fill="#19B8F7" />
      <path d="M411.188 86.8152C411.298 88.3158 412.485 89.7614 413.846 90.0207C415.206 90.2878 416.197 89.2743 416.055 87.7658C415.921 86.2574 414.718 84.8353 413.389 84.5682C412.053 84.3089 411.07 85.3067 411.188 86.8073V86.8152Z" fill="#19B8F7" />
      <path d="M403.458 85.3303C403.537 86.8309 404.693 88.2608 406.045 88.5122C407.398 88.7715 408.412 87.758 408.31 86.2574C408.208 84.7568 407.036 83.3426 405.715 83.0833C404.386 82.8319 403.38 83.8297 403.466 85.3303H403.458Z" fill="#19B8F7" />
      <path d="M395.768 83.8847C395.815 85.3774 396.94 86.7995 398.284 87.043C399.629 87.2944 400.667 86.2731 400.596 84.7803C400.525 83.2876 399.385 81.8812 398.064 81.6298C396.743 81.3863 395.713 82.3841 395.768 83.8768V83.8847Z" fill="#19B8F7" />
      <path d="M388.118 82.494C388.133 83.9789 389.226 85.3931 390.563 85.6367C391.9 85.8802 392.961 84.8589 392.922 83.3661C392.883 81.8734 391.782 80.4828 390.469 80.2392C389.155 80.0035 388.11 81.0091 388.125 82.494H388.118Z" fill="#19B8F7" />
      <path d="M372.926 79.8463C372.871 81.3234 373.901 82.714 375.222 82.9418C376.543 83.1697 377.644 82.1405 377.675 80.6634C377.699 79.1785 376.669 77.8115 375.364 77.5836C374.066 77.3637 372.973 78.3693 372.918 79.8542L372.926 79.8463Z" fill="#19B8F7" />
      <path d="M365.401 78.5893C365.315 80.0663 366.305 81.4412 367.626 81.6612C368.939 81.8812 370.064 80.8519 370.127 79.3749C370.19 77.8979 369.183 76.5308 367.894 76.3187C366.604 76.1066 365.488 77.1201 365.401 78.5893Z" fill="#19B8F7" />
      <path d="M357.915 77.3794C357.798 78.8486 358.757 80.2156 360.062 80.4278C361.367 80.6399 362.515 79.6028 362.61 78.1336C362.704 76.6645 361.737 75.3053 360.447 75.101C359.166 74.8967 358.033 75.9102 357.915 77.3794Z" fill="#19B8F7" />
      <path d="M343.086 75.0931C342.897 76.5545 343.786 77.9058 345.083 78.1022C346.372 78.2986 347.56 77.2616 347.725 75.7924C347.89 74.3311 346.986 72.9954 345.72 72.799C344.454 72.6105 343.282 73.6318 343.094 75.0931H343.086Z" fill="#19B8F7" />
      <path d="M321.218 71.9898C320.928 73.4433 321.714 74.7632 322.98 74.936C324.246 75.1089 325.496 74.0561 325.755 72.6026C326.023 71.1492 325.221 69.8371 323.978 69.6721C322.736 69.4993 321.502 70.5364 321.218 71.982V71.9898Z" fill="#19B8F7" />
      <path d="M306.9 70.1278C306.546 71.5734 307.261 72.8854 308.512 73.0426C309.762 73.1997 311.044 72.1469 311.374 70.6935C311.704 69.2478 310.973 67.9515 309.754 67.8022C308.527 67.6451 307.261 68.6822 306.9 70.1278Z" fill="#19B8F7" />
      <path d="M278.915 66.8988C278.428 68.3365 279.009 69.6171 280.22 69.7507C281.431 69.8764 282.792 68.8079 283.256 67.3702C283.719 65.9324 283.122 64.6675 281.942 64.5418C280.755 64.4161 279.41 65.4689 278.923 66.9066L278.915 66.8988Z" fill="#19B8F7" />
      <path d="M272.051 66.1838C271.532 67.6216 272.082 68.8943 273.277 69.02C274.48 69.1457 275.856 68.0694 276.352 66.6316C276.847 65.1939 276.289 63.9368 275.109 63.8111C273.93 63.6854 272.57 64.7461 272.051 66.1838Z" fill="#19B8F7" />
      <path d="M265.249 65.5002C264.699 66.938 265.21 68.2108 266.405 68.3208C267.592 68.4386 268.992 67.3544 269.519 65.9166C270.046 64.4789 269.519 63.2297 268.355 63.1118C267.191 62.994 265.807 64.0546 265.257 65.4924L265.249 65.5002Z" fill="#19B8F7" />
      <path d="M258.495 64.8638C257.913 66.3016 258.392 67.5665 259.572 67.6765C260.751 67.7865 262.167 66.7023 262.725 65.2645C263.283 63.8268 262.788 62.5854 261.632 62.4754C260.476 62.3654 259.069 63.4339 258.495 64.8717V64.8638Z" fill="#19B8F7" />
      <path d="M557.622 114.588C558.251 116.152 559.847 117.746 561.192 118.147C562.536 118.548 563.11 117.597 562.473 116.034C561.836 114.47 560.232 112.875 558.896 112.483C557.567 112.082 556.993 113.025 557.622 114.596V114.588Z" fill="#19B8F7" />
      <path d="M549.877 112.318C550.482 113.881 552.071 115.468 553.423 115.861C554.776 116.254 555.365 115.303 554.744 113.74C554.123 112.176 552.535 110.589 551.198 110.204C549.861 109.811 549.271 110.762 549.877 112.318Z" fill="#19B8F7" />
      <path d="M542.116 110.078C542.706 111.634 544.278 113.213 545.631 113.606C546.983 113.991 547.596 113.04 546.991 111.485C546.386 109.921 544.813 108.35 543.468 107.965C542.132 107.58 541.518 108.531 542.108 110.086L542.116 110.078Z" fill="#19B8F7" />
      <path d="M534.339 107.879C534.905 109.434 536.462 111.006 537.823 111.39C539.183 111.775 539.812 110.817 539.23 109.261C538.648 107.706 537.084 106.134 535.739 105.757C534.394 105.38 533.773 106.331 534.339 107.886V107.879Z" fill="#19B8F7" />
      <path d="M526.539 105.718C527.082 107.266 528.631 108.829 529.991 109.206C531.351 109.583 532.004 108.625 531.438 107.069C530.872 105.514 529.331 103.958 527.986 103.581C526.641 103.212 525.997 104.162 526.539 105.71V105.718Z" fill="#19B8F7" />
      <path d="M518.739 103.597C519.258 105.144 520.783 106.7 522.151 107.069C523.512 107.439 524.18 106.48 523.645 104.924C523.103 103.377 521.577 101.821 520.233 101.46C518.888 101.09 518.228 102.049 518.747 103.597H518.739Z" fill="#19B8F7" />
      <path d="M510.939 101.507C511.434 103.047 512.944 104.595 514.304 104.956C515.665 105.317 516.357 104.351 515.838 102.811C515.319 101.263 513.809 99.7235 512.464 99.3699C511.12 99.0085 510.436 99.967 510.931 101.507H510.939Z" fill="#19B8F7" />
      <path d="M503.131 99.4642C503.603 101.004 505.089 102.536 506.457 102.897C507.825 103.251 508.533 102.285 508.037 100.745C507.542 99.2049 506.056 97.6729 504.703 97.3193C503.359 96.9658 502.659 97.9243 503.131 99.4642Z" fill="#19B8F7" />
      <path d="M495.323 97.4687C495.771 99.0007 497.241 100.533 498.602 100.878C499.962 101.224 500.693 100.258 500.229 98.7179C499.757 97.178 498.287 95.6617 496.942 95.316C495.598 94.9703 494.874 95.9366 495.323 97.4687Z" fill="#19B8F7" />
      <path d="M487.523 95.5045C487.947 97.0287 489.386 98.5528 490.754 98.8907C492.115 99.2285 492.862 98.2621 492.421 96.7301C491.981 95.1981 490.526 93.6896 489.19 93.3518C487.845 93.0139 487.106 93.9803 487.523 95.5045Z" fill="#19B8F7" />
      <path d="M479.73 93.5953C480.123 95.1195 481.547 96.6279 482.907 96.9579C484.267 97.2879 485.038 96.3215 484.621 94.7895C484.204 93.2653 482.781 91.7647 481.437 91.4347C480.092 91.1047 479.329 92.0711 479.73 93.5953Z" fill="#19B8F7" />
      <path d="M464.185 89.8949C464.523 91.4034 465.899 92.8961 467.252 93.2104C468.604 93.5246 469.422 92.5504 469.06 91.0341C468.698 89.5178 467.322 88.0408 465.986 87.7265C464.649 87.4122 463.847 88.3786 464.185 89.8949Z" fill="#19B8F7" />
      <path d="M380.561 72.854C380.561 74.2997 381.599 75.6746 382.896 75.9024C384.194 76.1302 385.24 75.1325 385.224 73.679C385.208 72.2255 384.154 70.8742 382.881 70.6463C381.607 70.4185 380.569 71.4006 380.569 72.854H380.561Z" fill="#19B8F7" />
      <path d="M373.178 71.5812C373.138 73.0268 374.145 74.386 375.434 74.606C376.724 74.826 377.793 73.8282 377.809 72.3747C377.825 70.9291 376.803 69.5857 375.537 69.3657C374.271 69.1457 373.217 70.1356 373.178 71.5734V71.5812Z" fill="#19B8F7" />
      <path d="M365.841 70.3556C365.771 71.7933 366.746 73.1447 368.027 73.3568C369.309 73.5689 370.394 72.5633 370.449 71.1255C370.496 69.6878 369.513 68.3522 368.255 68.1479C366.997 67.9358 365.92 68.9257 365.849 70.3634L365.841 70.3556Z" fill="#19B8F7" />
      <path d="M358.544 69.1693C358.434 70.5992 359.378 71.9427 360.652 72.1469C361.926 72.3512 363.034 71.3456 363.121 69.9078C363.207 68.4701 362.248 67.1502 360.998 66.9459C359.747 66.7416 358.654 67.7315 358.544 69.1614V69.1693Z" fill="#19B8F7" />
      <path d="M351.303 68.0301C351.161 69.46 352.065 70.7878 353.339 70.9842C354.605 71.1806 355.737 70.1749 355.855 68.745C355.973 67.3152 355.053 66.0031 353.811 65.8067C352.569 65.6103 351.452 66.6002 351.31 68.0301H351.303Z" fill="#19B8F7" />
      <path d="M336.952 65.8774C336.74 67.2994 337.582 68.6036 338.832 68.7922C340.082 68.9729 341.254 67.9594 341.434 66.5374C341.615 65.1153 340.766 63.8268 339.539 63.6461C338.313 63.4654 337.157 64.4632 336.945 65.8774H336.952Z" fill="#19B8F7" />
      <path d="M322.807 63.8896C322.532 65.3038 323.302 66.5923 324.529 66.7652C325.763 66.9301 326.974 65.9166 327.226 64.5025C327.477 63.0883 326.699 61.8155 325.488 61.6505C324.277 61.4855 323.082 62.4833 322.807 63.8975V63.8896Z" fill="#19B8F7" />
      <path d="M315.809 62.9625C315.502 64.3688 316.233 65.6573 317.46 65.8145C318.687 65.9794 319.913 64.9502 320.196 63.5439C320.479 62.1297 319.732 60.8727 318.537 60.7077C317.334 60.5505 316.123 61.5483 315.809 62.9625Z" fill="#19B8F7" />
      <path d="M288.351 59.6157C287.91 61.0141 288.508 62.2712 289.695 62.4047C290.883 62.5383 292.188 61.5012 292.605 60.1028C293.021 58.7043 292.408 57.4708 291.244 57.3373C290.081 57.2037 288.791 58.2172 288.343 59.6157H288.351Z" fill="#19B8F7" />
      <path d="M268.308 57.4944C267.773 58.8929 268.268 60.1263 269.432 60.2442C270.588 60.362 271.956 59.3093 272.467 57.9186C272.978 56.5202 272.467 55.3103 271.335 55.1924C270.195 55.0746 268.85 56.1038 268.316 57.5023L268.308 57.4944Z" fill="#19B8F7" />
      <path d="M261.734 56.8502C261.168 58.2486 261.632 59.4742 262.78 59.5921C263.928 59.7021 265.312 58.6493 265.855 57.2508C266.397 55.8524 265.917 54.6425 264.793 54.5325C263.669 54.4225 262.3 55.4517 261.734 56.8502Z" fill="#19B8F7" />
      <path d="M569.259 110.393C569.928 111.948 571.54 113.551 572.861 113.96C574.182 114.368 574.701 113.433 574.016 111.87C573.34 110.306 571.72 108.711 570.415 108.311C569.11 107.902 568.591 108.837 569.259 110.393Z" fill="#19B8F7" />
      <path d="M561.663 108.067C562.316 109.623 563.92 111.21 565.241 111.618C566.562 112.019 567.097 111.084 566.436 109.529C565.768 107.973 564.172 106.386 562.859 105.985C561.546 105.584 561.011 106.519 561.663 108.075V108.067Z" fill="#19B8F7" />
      <path d="M554.044 105.781C554.681 107.336 556.269 108.916 557.598 109.316C558.927 109.717 559.478 108.774 558.833 107.219C558.188 105.663 556.592 104.084 555.279 103.691C553.966 103.298 553.415 104.233 554.044 105.789V105.781Z" fill="#19B8F7" />
      <path d="M546.409 103.526C547.022 105.074 548.603 106.645 549.932 107.038C551.261 107.431 551.835 106.488 551.206 104.932C550.577 103.377 548.996 101.813 547.675 101.421C546.354 101.036 545.788 101.97 546.409 103.518V103.526Z" fill="#19B8F7" />
      <path d="M538.758 101.31C539.356 102.858 540.921 104.422 542.257 104.807C543.594 105.192 544.184 104.249 543.571 102.701C542.957 101.153 541.392 99.5899 540.071 99.2128C538.75 98.8278 538.169 99.7706 538.766 101.318L538.758 101.31Z" fill="#19B8F7" />
      <path d="M531.1 99.1342C531.674 100.674 533.223 102.23 534.56 102.607C535.896 102.984 536.502 102.041 535.912 100.493C535.322 98.9535 533.765 97.3979 532.444 97.0208C531.123 96.6437 530.518 97.5865 531.1 99.1264V99.1342Z" fill="#19B8F7" />
      <path d="M523.425 96.9973C523.976 98.5293 525.509 100.077 526.854 100.454C528.19 100.823 528.819 99.8807 528.245 98.3408C527.671 96.8009 526.138 95.261 524.817 94.8917C523.496 94.5225 522.875 95.4653 523.425 96.9973Z" fill="#19B8F7" />
      <path d="M515.751 94.8995C516.286 96.4316 517.795 97.9636 519.132 98.3329C520.469 98.6943 521.114 97.7436 520.563 96.2116C520.013 94.6795 518.495 93.1475 517.174 92.7861C515.853 92.4247 515.216 93.3675 515.743 94.8995H515.751Z" fill="#19B8F7" />
      <path d="M508.085 92.8411C508.596 94.3653 510.09 95.8894 511.426 96.2509C512.763 96.6123 513.431 95.6537 512.905 94.1296C512.378 92.6054 510.876 91.0812 509.555 90.7277C508.234 90.3741 507.573 91.3169 508.085 92.8411Z" fill="#19B8F7" />
      <path d="M500.41 90.8298C500.89 92.3462 502.368 93.8625 503.705 94.216C505.041 94.5696 505.726 93.6111 505.222 92.0869C504.719 90.5627 503.241 89.0543 501.92 88.7086C500.599 88.3629 499.923 89.3057 500.402 90.8298H500.41Z" fill="#19B8F7" />
      <path d="M492.751 88.8578C493.208 90.3662 494.662 91.8747 495.999 92.2204C497.336 92.5661 498.035 91.6076 497.564 90.0913C497.084 88.5749 495.629 87.0743 494.308 86.7365C492.987 86.3987 492.295 87.3493 492.751 88.8578Z" fill="#19B8F7" />
      <path d="M485.101 86.933C485.533 88.4415 486.964 89.9342 488.301 90.272C489.638 90.6099 490.361 89.6514 489.905 88.1429C489.457 86.6344 488.018 85.1496 486.697 84.8196C485.384 84.4896 484.668 85.4402 485.101 86.9409V86.933Z" fill="#19B8F7" />
      <path d="M477.458 85.0552C477.859 86.5558 479.266 88.0407 480.603 88.3629C481.94 88.6928 482.679 87.7343 482.254 86.2259C481.83 84.7253 480.414 83.2482 479.101 82.9261C477.788 82.604 477.057 83.5546 477.458 85.0474V85.0552Z" fill="#19B8F7" />
      <path d="M469.839 83.2169C470.216 84.7096 471.6 86.1788 472.929 86.5009C474.258 86.823 475.02 85.8567 474.627 84.3639C474.226 82.8633 472.842 81.402 471.529 81.0877C470.216 80.7735 469.461 81.7241 469.839 83.2169Z" fill="#19B8F7" />
      <path d="M462.235 81.4255C462.581 82.9104 463.941 84.3717 465.27 84.686C466.599 85.0002 467.377 84.0339 467.008 82.549C466.638 81.0562 465.278 79.6106 463.965 79.3042C462.66 78.9978 461.881 79.9485 462.235 81.4334V81.4255Z" fill="#19B8F7" />
      <path d="M454.655 79.6813C454.977 81.1583 456.306 82.6118 457.627 82.9104C458.956 83.2168 459.75 82.2504 459.412 80.7655C459.074 79.2806 457.737 77.8429 456.432 77.5443C455.127 77.2458 454.332 78.1964 454.655 79.6735V79.6813Z" fill="#19B8F7" />
      <path d="M366.219 62.334C366.156 63.7325 367.115 65.0445 368.365 65.2567C369.616 65.4688 370.669 64.4867 370.709 63.0882C370.748 61.6898 369.781 60.3934 368.554 60.1892C367.327 59.9849 366.29 60.9434 366.227 62.3419L366.219 62.334Z" fill="#19B8F7" />
      <path d="M338.093 57.9658C337.896 59.3407 338.714 60.6135 339.933 60.7942C341.151 60.9749 342.284 59.985 342.457 58.6101C342.63 57.2273 341.796 55.9781 340.601 55.8053C339.406 55.6324 338.289 56.5909 338.085 57.9737L338.093 57.9658Z" fill="#19B8F7" />
      <path d="M324.324 56.0252C324.057 57.3923 324.812 58.6493 326.015 58.8143C327.218 58.9793 328.39 57.9894 328.625 56.6223C328.869 55.2474 328.099 54.0218 326.927 53.8568C325.755 53.6918 324.592 54.6582 324.324 56.0331V56.0252Z" fill="#19B8F7" />
      <path d="M310.761 54.2418C310.43 55.601 311.114 56.8424 312.294 56.9916C313.473 57.1409 314.692 56.151 314.999 54.7839C315.305 53.4169 314.606 52.207 313.45 52.0577C312.294 51.9084 311.091 52.8826 310.761 54.2418Z" fill="#19B8F7" />
      <path d="M290.796 51.8377C290.372 53.1891 290.953 54.4068 292.109 54.5404C293.265 54.674 294.539 53.6683 294.94 52.317C295.341 50.9578 294.751 49.7715 293.619 49.6379C292.487 49.5043 291.229 50.4864 290.804 51.8377H290.796Z" fill="#19B8F7" />
      <path d="M580.393 106.315C581.093 107.871 582.713 109.473 584.01 109.89C585.308 110.306 585.772 109.379 585.064 107.824C584.348 106.26 582.729 104.665 581.447 104.249C580.165 103.832 579.694 104.759 580.393 106.315Z" fill="#19B8F7" />
      <path d="M572.939 103.942C573.631 105.498 575.243 107.093 576.54 107.501C577.838 107.91 578.325 106.983 577.626 105.427C576.926 103.872 575.314 102.277 574.024 101.868C572.735 101.46 572.247 102.387 572.939 103.942Z" fill="#19B8F7" />
      <path d="M565.454 101.609C566.13 103.157 567.734 104.744 569.039 105.152C570.344 105.561 570.848 104.626 570.156 103.078C569.472 101.523 567.868 99.9435 566.57 99.5349C565.281 99.1342 564.777 100.061 565.454 101.609Z" fill="#19B8F7" />
      <path d="M557.952 99.307C558.613 100.855 560.209 102.434 561.514 102.827C562.819 103.227 563.338 102.293 562.67 100.745C562.002 99.197 560.405 97.6179 559.108 97.225C557.811 96.8322 557.3 97.7593 557.952 99.307Z" fill="#19B8F7" />
      <path d="M550.435 97.0365C551.08 98.5764 552.66 100.148 553.965 100.541C555.279 100.933 555.813 99.9984 555.153 98.4585C554.5 96.9108 552.912 95.3473 551.614 94.9623C550.317 94.5695 549.782 95.5044 550.427 97.0443L550.435 97.0365Z" fill="#19B8F7" />
      <path d="M542.902 94.8132C543.523 96.3452 545.096 97.9087 546.409 98.2936C547.722 98.6786 548.273 97.7437 547.636 96.2038C546.999 94.6639 545.426 93.1083 544.129 92.7233C542.832 92.3383 542.281 93.2733 542.902 94.8053V94.8132Z" fill="#19B8F7" />
      <path d="M535.362 92.6212C535.967 94.1532 537.516 95.701 538.837 96.0781C540.15 96.4552 540.716 95.5203 540.103 93.9882C539.482 92.4562 537.925 90.9084 536.627 90.5313C535.322 90.1542 534.764 91.0891 535.369 92.6133L535.362 92.6212Z" fill="#19B8F7" />
      <path d="M527.813 90.4763C528.395 92.0005 529.936 93.5404 531.249 93.9096C532.562 94.2789 533.152 93.344 532.554 91.8198C531.957 90.2956 530.416 88.7557 529.11 88.3865C527.805 88.0172 527.231 88.9522 527.813 90.4763Z" fill="#19B8F7" />
      <path d="M520.264 88.3707C520.823 89.887 522.348 91.4191 523.661 91.7805C524.982 92.1497 525.58 91.2069 525.006 89.6828C524.432 88.1586 522.906 86.6423 521.601 86.2809C520.304 85.9195 519.706 86.8544 520.264 88.3707Z" fill="#19B8F7" />
      <path d="M512.724 86.3045C513.258 87.8129 514.768 89.3292 516.081 89.6906C517.402 90.052 518.016 89.1093 517.465 87.5929C516.907 86.0766 515.405 84.5682 514.1 84.2146C512.802 83.8611 512.181 84.796 512.724 86.3045Z" fill="#19B8F7" />
      <path d="M505.183 84.2854C505.694 85.786 507.18 87.2944 508.493 87.648C509.807 88.0015 510.443 87.0587 509.917 85.5503C509.382 84.0418 507.896 82.5412 506.598 82.1955C505.301 81.8498 504.664 82.7847 505.183 84.2854Z" fill="#19B8F7" />
      <path d="M497.65 82.3133C498.138 83.806 499.6 85.3066 500.913 85.6445C502.227 85.9902 502.887 85.0474 502.376 83.5468C501.865 82.0462 500.402 80.5613 499.105 80.2234C497.808 79.8856 497.155 80.8205 497.65 82.3133Z" fill="#19B8F7" />
      <path d="M490.133 80.3806C490.597 81.8655 492.036 83.3504 493.349 83.6882C494.662 84.026 495.338 83.0832 494.851 81.5905C494.363 80.0977 492.924 78.6207 491.627 78.2907C490.33 77.9608 489.661 78.8957 490.125 80.3806H490.133Z" fill="#19B8F7" />
      <path d="M482.624 78.495C483.064 79.9721 484.48 81.4491 485.793 81.7712C487.106 82.1012 487.798 81.1584 487.342 79.6735C486.886 78.1886 485.462 76.7273 484.173 76.4052C482.883 76.0831 482.191 77.018 482.632 78.495H482.624Z" fill="#19B8F7" />
      <path d="M475.138 76.6488C475.547 78.1179 476.939 79.5793 478.252 79.9014C479.557 80.2235 480.273 79.2807 479.84 77.8037C479.408 76.3267 478.008 74.881 476.726 74.5668C475.437 74.2525 474.729 75.1874 475.146 76.6566L475.138 76.6488Z" fill="#19B8F7" />
      <path d="M467.676 74.8575C468.061 76.3188 469.43 77.7644 470.735 78.0787C472.04 78.3929 472.771 77.4501 472.363 75.981C471.954 74.5118 470.585 73.074 469.304 72.7676C468.022 72.4612 467.291 73.3961 467.676 74.8575Z" fill="#19B8F7" />
      <path d="M460.23 73.1054C460.584 74.5589 461.928 75.9966 463.226 76.303C464.531 76.6095 465.278 75.6667 464.9 74.2053C464.523 72.744 463.178 71.322 461.897 71.0234C460.615 70.7249 459.876 71.6598 460.23 73.1133V73.1054Z" fill="#19B8F7" />
      <path d="M452.815 71.4084C453.145 72.854 454.458 74.276 455.748 74.5746C457.045 74.8731 457.816 73.9304 457.47 72.4769C457.124 71.0234 455.803 69.6171 454.529 69.3264C453.255 69.0357 452.493 69.9706 452.815 71.4163V71.4084Z" fill="#19B8F7" />
      <path d="M445.424 69.7507C445.722 71.1885 447.004 72.6026 448.302 72.8855C449.591 73.1762 450.385 72.2255 450.063 70.7799C449.74 69.3343 448.451 67.9437 447.177 67.6609C445.911 67.378 445.125 68.3051 445.424 69.7507Z" fill="#19B8F7" />
      <path d="M438.064 68.1401C438.331 69.57 439.589 70.9684 440.871 71.2513C442.16 71.5341 442.97 70.5835 442.679 69.1457C442.389 67.708 441.123 66.3252 439.864 66.0502C438.599 65.7752 437.796 66.7023 438.064 68.1401Z" fill="#19B8F7" />
      <path d="M430.735 66.5766C430.971 67.9986 432.198 69.3814 433.48 69.6564C434.761 69.9314 435.595 68.9807 435.335 67.5508C435.076 66.1209 433.841 64.7539 432.583 64.4867C431.325 64.2196 430.5 65.1467 430.735 66.5766Z" fill="#19B8F7" />
      <path d="M423.446 65.0603C423.651 66.4745 424.846 67.8415 426.128 68.1087C427.401 68.3758 428.259 67.4251 428.023 66.0031C427.795 64.5811 426.592 63.2297 425.333 62.9705C424.083 62.7112 423.234 63.6383 423.446 65.0524V65.0603Z" fill="#19B8F7" />
      <path d="M416.189 63.5911C416.362 64.9975 417.525 66.3566 418.799 66.6159C420.065 66.8752 420.938 65.9245 420.742 64.5103C420.545 63.0962 419.365 61.7605 418.123 61.5091C416.881 61.2577 416.016 62.1848 416.189 63.5911Z" fill="#19B8F7" />
      <path d="M408.97 62.1691C409.112 63.5676 410.244 64.911 411.51 65.1624C412.776 65.4138 413.665 64.4632 413.5 63.0569C413.334 61.6506 412.186 60.3228 410.952 60.0792C409.71 59.8357 408.829 60.7628 408.97 62.1612V62.1691Z" fill="#19B8F7" />
      <path d="M387.559 58.1622C387.606 59.5371 388.644 60.8491 389.887 61.0691C391.129 61.2969 392.081 60.3463 392.01 58.9635C391.939 57.5808 390.893 56.2923 389.674 56.0723C388.456 55.8523 387.512 56.7794 387.559 58.1543V58.1622Z" fill="#19B8F7" />
      <path d="M380.506 56.913C380.522 58.28 381.52 59.5764 382.755 59.7963C383.989 60.0163 384.964 59.0657 384.925 57.6908C384.886 56.3159 383.871 55.0431 382.661 54.831C381.45 54.6189 380.483 55.5459 380.498 56.913H380.506Z" fill="#19B8F7" />
      <path d="M373.5 55.711C373.476 57.0702 374.451 58.3587 375.678 58.5708C376.905 58.7829 377.903 57.8323 377.896 56.4653C377.896 55.0982 376.913 53.8333 375.71 53.629C374.507 53.4248 373.524 54.3518 373.5 55.711Z" fill="#19B8F7" />
      <path d="M366.541 54.5482C366.486 55.9074 367.43 57.1802 368.641 57.3766C369.86 57.5809 370.874 56.6302 370.905 55.271C370.937 53.9118 369.985 52.6548 368.79 52.4584C367.595 52.262 366.588 53.189 366.533 54.5404L366.541 54.5482Z" fill="#19B8F7" />
      <path d="M359.63 53.4247C359.543 54.7761 360.447 56.0331 361.658 56.2295C362.869 56.4259 363.907 55.4753 363.97 54.124C364.033 52.7726 363.113 51.5313 361.933 51.3349C360.746 51.1463 359.724 52.0734 359.637 53.4247H359.63Z" fill="#19B8F7" />
      <path d="M352.757 52.3483C352.639 53.6918 353.512 54.941 354.707 55.1295C355.91 55.3181 356.964 54.3674 357.058 53.0161C357.153 51.6726 356.272 50.4392 355.093 50.2585C353.913 50.0778 352.867 51.0048 352.749 52.3483H352.757Z" fill="#19B8F7" />
      <path d="M345.94 51.3034C345.783 52.6391 346.624 53.8804 347.819 54.0611C349.014 54.2418 350.092 53.2912 350.217 51.9477C350.343 50.6042 349.494 49.3864 348.33 49.2136C347.159 49.0407 346.097 49.9678 345.94 51.3034Z" fill="#19B8F7" />
      <path d="M339.17 50.3056C338.981 51.6334 339.783 52.8669 340.97 53.0476C342.158 53.2204 343.251 52.2698 343.416 50.9341C343.581 49.5985 342.763 48.3886 341.6 48.2158C340.436 48.0429 339.351 48.9779 339.162 50.3056H339.17Z" fill="#19B8F7" />
      <path d="M332.447 49.3471C332.227 50.6749 332.997 51.9005 334.169 52.0655C335.348 52.2305 336.465 51.2798 336.661 49.9521C336.858 48.6243 336.08 47.4223 334.924 47.2573C333.776 47.0923 332.667 48.0272 332.447 49.3471Z" fill="#19B8F7" />
      <path d="M325.779 48.4201C325.527 49.74 326.259 50.9578 327.43 51.1149C328.594 51.2799 329.734 50.3214 329.962 48.9936C330.19 47.6659 329.443 46.4795 328.303 46.3224C327.163 46.1653 326.031 47.1002 325.779 48.4201Z" fill="#19B8F7" />
      <path d="M319.158 47.5323C318.875 48.8522 319.575 50.0542 320.731 50.2114C321.887 50.3685 323.05 49.41 323.31 48.0901C323.569 46.7702 322.854 45.5917 321.722 45.4346C320.589 45.2853 319.441 46.2124 319.158 47.5323Z" fill="#19B8F7" />
      <path d="M583.767 99.9198C584.482 101.475 586.102 103.078 587.384 103.495C588.657 103.911 589.106 102.992 588.374 101.436C587.651 99.8806 586.031 98.2778 584.757 97.8614C583.491 97.445 583.043 98.3642 583.759 99.9198H583.767Z" fill="#19B8F7" />
      <path d="M569.023 95.1824C569.715 96.7302 571.319 98.3093 572.609 98.7179C573.898 99.1264 574.37 98.1993 573.663 96.6516C572.955 95.1039 571.351 93.5168 570.077 93.1161C568.803 92.7155 568.331 93.6347 569.023 95.1824Z" fill="#19B8F7" />
      <path d="M561.624 92.8726C562.3 94.4124 563.897 95.9838 565.186 96.3923C566.476 96.793 566.963 95.8659 566.271 94.326C565.579 92.7861 563.983 91.207 562.709 90.8141C561.428 90.4134 560.948 91.3405 561.624 92.8804V92.8726Z" fill="#19B8F7" />
      <path d="M554.209 90.602C554.87 92.134 556.458 93.7053 557.748 94.0982C559.037 94.491 559.54 93.5639 558.864 92.0319C558.188 90.492 556.6 88.9285 555.326 88.5436C554.044 88.1507 553.549 89.0778 554.209 90.6098V90.602Z" fill="#19B8F7" />
      <path d="M546.787 88.3707C547.431 89.8949 549.004 91.4505 550.301 91.8433C551.599 92.2283 552.11 91.3012 551.449 89.7692C550.789 88.2371 549.216 86.6894 547.935 86.3044C546.653 85.9194 546.142 86.8465 546.787 88.3707Z" fill="#19B8F7" />
      <path d="M539.356 86.1709C539.985 87.6872 541.542 89.235 542.839 89.6121C544.137 89.9971 544.664 89.0621 544.027 87.538C543.382 86.0138 541.825 84.4739 540.543 84.0968C539.262 83.7197 538.735 84.6467 539.364 86.1631L539.356 86.1709Z" fill="#19B8F7" />
      <path d="M531.925 84.026C532.531 85.5345 534.08 87.0744 535.377 87.4436C536.675 87.8208 537.217 86.8858 536.596 85.3695C535.975 83.8532 534.426 82.329 533.144 81.9597C531.862 81.5905 531.32 82.5176 531.925 84.026Z" fill="#19B8F7" />
      <path d="M524.495 81.9126C525.084 83.4211 526.61 84.9374 527.907 85.3066C529.205 85.6759 529.771 84.741 529.165 83.2325C528.56 81.724 527.034 80.2077 525.753 79.8463C524.471 79.4849 523.913 80.412 524.503 81.9126H524.495Z" fill="#19B8F7" />
      <path d="M517.072 79.8464C517.638 81.3391 519.148 82.8554 520.445 83.209C521.743 83.5704 522.317 82.6354 521.735 81.1348C521.153 79.6342 519.643 78.1336 518.362 77.7801C517.08 77.4265 516.506 78.3458 517.072 79.8464Z" fill="#19B8F7" />
      <path d="M509.649 77.8272C510.192 79.3121 511.686 80.8127 512.975 81.1663C514.273 81.5198 514.87 80.5927 514.304 79.0921C513.746 77.5994 512.252 76.1066 510.97 75.761C509.689 75.4153 509.099 76.3345 509.641 77.8272H509.649Z" fill="#19B8F7" />
      <path d="M502.242 75.8553C502.761 77.3323 504.232 78.8172 505.521 79.1629C506.811 79.5086 507.432 78.5736 506.889 77.0888C506.355 75.6039 504.876 74.1268 503.602 73.789C502.329 73.4512 501.715 74.3704 502.234 75.8553H502.242Z" fill="#19B8F7" />
      <path d="M494.851 73.9225C495.346 75.3917 496.793 76.8609 498.083 77.1987C499.372 77.5366 500.009 76.6095 499.49 75.1246C498.979 73.6476 497.524 72.1862 496.251 71.8563C494.977 71.5263 494.348 72.4455 494.851 73.9147V73.9225Z" fill="#19B8F7" />
      <path d="M487.475 72.0369C487.947 73.4982 489.37 74.9596 490.66 75.2817C491.949 75.6117 492.594 74.6846 492.107 73.2154C491.619 71.7462 490.188 70.3006 488.914 69.9785C487.648 69.6564 487.004 70.5756 487.475 72.0369Z" fill="#19B8F7" />
      <path d="M480.115 70.1985C480.564 71.652 481.963 73.0976 483.245 73.4118C484.527 73.7339 485.195 72.8069 484.731 71.3455C484.267 69.8842 482.86 68.4543 481.594 68.1401C480.328 67.8258 479.667 68.745 480.108 70.1985H480.115Z" fill="#19B8F7" />
      <path d="M472.779 68.4072C473.196 69.8528 474.572 71.2827 475.854 71.597C477.135 71.9112 477.82 70.9842 477.379 69.5307C476.939 68.0772 475.563 66.663 474.297 66.3566C473.039 66.0502 472.355 66.9695 472.771 68.4072H472.779Z" fill="#19B8F7" />
      <path d="M465.474 66.6631C465.868 68.1008 467.212 69.515 468.494 69.8214C469.768 70.1278 470.475 69.2008 470.066 67.7551C469.658 66.3174 468.297 64.9111 467.039 64.6125C465.781 64.314 465.081 65.2253 465.474 66.6631Z" fill="#19B8F7" />
      <path d="M458.185 64.966C458.547 66.388 459.868 67.7943 461.142 68.0929C462.416 68.3914 463.139 67.4644 462.754 66.0345C462.369 64.6046 461.04 63.214 459.789 62.9233C458.539 62.6326 457.824 63.5439 458.185 64.9738V64.966Z" fill="#19B8F7" />
      <path d="M450.936 63.3083C451.266 64.7225 452.563 66.1131 453.837 66.4038C455.103 66.6944 455.85 65.7674 455.496 64.3453C455.142 62.9233 453.837 61.5484 452.595 61.2656C451.344 60.9827 450.613 61.8941 450.943 63.3083H450.936Z" fill="#19B8F7" />
      <path d="M443.709 61.7055C444.016 63.1119 445.282 64.4868 446.54 64.7696C447.806 65.0524 448.569 64.1254 448.239 62.7112C447.908 61.297 446.634 59.9378 445.4 59.6628C444.158 59.3878 443.403 60.2992 443.709 61.7055Z" fill="#19B8F7" />
      <path d="M436.523 60.1421C436.798 61.5406 438.032 62.8998 439.29 63.1748C440.549 63.4497 441.327 62.5227 441.028 61.1163C440.729 59.71 439.487 58.3665 438.253 58.1073C437.018 57.8401 436.24 58.7515 436.515 60.15L436.523 60.1421Z" fill="#19B8F7" />
      <path d="M429.367 58.6336C429.611 60.0242 430.822 61.3676 432.072 61.6348C433.322 61.9019 434.124 60.9748 433.857 59.5842C433.59 58.1936 432.371 56.858 431.144 56.6066C429.918 56.3473 429.123 57.2587 429.367 58.6414V58.6336Z" fill="#19B8F7" />
      <path d="M422.251 57.1644C422.463 58.5393 423.643 59.8749 424.885 60.1342C426.128 60.3935 426.953 59.4664 426.717 58.0836C426.481 56.7009 425.294 55.381 424.075 55.1374C422.857 54.886 422.039 55.7895 422.251 57.1723V57.1644Z" fill="#19B8F7" />
      <path d="M408.129 54.3675C408.278 55.7266 409.395 57.0308 410.63 57.2744C411.864 57.5179 412.721 56.5909 412.548 55.2238C412.375 53.8568 411.251 52.5683 410.04 52.3405C408.829 52.1048 407.98 53.0083 408.129 54.3675Z" fill="#19B8F7" />
      <path d="M401.131 53.0319C401.249 54.3832 402.334 55.6717 403.561 55.9074C404.787 56.1431 405.668 55.216 405.526 53.8647C405.385 52.5055 404.292 51.2327 403.089 51.0049C401.886 50.777 401.013 51.6805 401.131 53.0319Z" fill="#19B8F7" />
      <path d="M387.268 50.4942C387.323 51.8298 388.345 53.0947 389.549 53.3147C390.759 53.5347 391.679 52.6155 391.601 51.272C391.522 49.9285 390.492 48.6872 389.313 48.475C388.125 48.2629 387.213 49.1586 387.268 50.4942Z" fill="#19B8F7" />
      <path d="M380.404 49.2922C380.427 50.6199 381.41 51.877 382.613 52.0813C383.816 52.2934 384.76 51.3742 384.713 50.0385C384.666 48.7108 383.667 47.4773 382.495 47.273C381.316 47.0688 380.388 47.9644 380.404 49.2922Z" fill="#19B8F7" />
      <path d="M373.579 48.1294C373.571 49.4493 374.522 50.6906 375.717 50.8949C376.913 51.0992 377.872 50.18 377.864 48.8522C377.848 47.5323 376.889 46.3067 375.717 46.1103C374.546 45.9138 373.594 46.8095 373.587 48.1294H373.579Z" fill="#19B8F7" />
      <path d="M366.809 47.0058C366.761 48.3179 367.681 49.5514 368.869 49.7399C370.056 49.9363 371.039 49.0171 371.055 47.7051C371.078 46.393 370.143 45.1831 368.987 44.9945C367.823 44.806 366.856 45.7016 366.809 47.0058Z" fill="#19B8F7" />
      <path d="M360.078 45.9295C359.999 47.2337 360.888 48.4514 362.067 48.64C363.247 48.8285 364.245 47.9093 364.3 46.6051C364.355 45.3009 363.459 44.0989 362.303 43.9182C361.147 43.7375 360.156 44.6331 360.078 45.9295Z" fill="#19B8F7" />
      <path d="M353.402 44.8845C353.292 46.1809 354.141 47.3908 355.313 47.5715C356.484 47.7522 357.507 46.833 357.593 45.5366C357.68 44.2325 356.815 43.054 355.667 42.8733C354.519 42.6926 353.512 43.5882 353.402 44.8845Z" fill="#19B8F7" />
      <path d="M340.184 42.9126C340.011 44.1932 340.798 45.3874 341.946 45.5524C343.101 45.7252 344.163 44.7982 344.312 43.5175C344.462 42.2291 343.668 41.0663 342.535 40.9013C341.403 40.7363 340.357 41.632 340.176 42.9126H340.184Z" fill="#19B8F7" />
      <path d="M565.084 86.4773C565.784 88.0172 567.38 89.5885 568.654 89.9892C569.928 90.3899 570.384 89.4707 569.676 87.9308C568.968 86.3909 567.372 84.8196 566.106 84.4189C564.84 84.0182 564.384 84.9453 565.084 86.4773Z" fill="#19B8F7" />
      <path d="M557.763 84.1989C558.448 85.7309 560.036 87.2865 561.31 87.6872C562.584 88.08 563.055 87.1608 562.363 85.6288C561.671 84.0968 560.083 82.5333 558.817 82.1483C557.551 81.7555 557.079 82.6826 557.763 84.2068V84.1989Z" fill="#19B8F7" />
      <path d="M550.443 81.9676C551.111 83.4918 552.684 85.0395 553.958 85.4245C555.231 85.8173 555.719 84.8902 555.043 83.366C554.366 81.8419 552.786 80.2941 551.52 79.9092C550.254 79.5242 549.774 80.4434 550.443 81.9676Z" fill="#19B8F7" />
      <path d="M543.114 79.7756C543.767 81.2841 545.324 82.824 546.606 83.209C547.879 83.5939 548.383 82.6669 547.722 81.1505C547.062 79.6342 545.497 78.1022 544.231 77.7251C542.965 77.348 542.47 78.2672 543.122 79.7835L543.114 79.7756Z" fill="#19B8F7" />
      <path d="M535.786 77.6229C536.415 79.1235 537.964 80.6555 539.246 81.0248C540.528 81.4019 541.039 80.4749 540.394 78.9664C539.749 77.4579 538.2 75.9338 536.934 75.5645C535.668 75.1952 535.157 76.1145 535.794 77.6151L535.786 77.6229Z" fill="#19B8F7" />
      <path d="M528.466 75.5174C529.079 77.0101 530.612 78.5265 531.886 78.8879C533.16 79.2571 533.695 78.3301 533.066 76.8294C532.436 75.3288 530.903 73.8204 529.645 73.459C528.379 73.0976 527.86 74.0168 528.473 75.5095L528.466 75.5174Z" fill="#19B8F7" />
      <path d="M521.153 73.459C521.743 74.9439 523.26 76.4445 524.534 76.8059C525.808 77.1673 526.35 76.2402 525.745 74.7475C525.139 73.2547 523.622 71.762 522.364 71.4084C521.106 71.0549 520.563 71.9741 521.153 73.459Z" fill="#19B8F7" />
      <path d="M513.848 71.4477C514.422 72.9247 515.916 74.4096 517.19 74.7632C518.464 75.1167 519.022 74.1897 518.44 72.7126C517.85 71.2277 516.356 69.7507 515.098 69.405C513.84 69.0593 513.282 69.9707 513.856 71.4477H513.848Z" fill="#19B8F7" />
      <path d="M506.551 69.4757C507.102 70.937 508.572 72.414 509.846 72.7597C511.12 73.1054 511.694 72.1862 511.127 70.7092C510.561 69.2321 509.083 67.7708 507.825 67.4408C506.567 67.103 506.001 68.0144 506.543 69.4835L506.551 69.4757Z" fill="#19B8F7" />
      <path d="M499.278 67.5509C499.805 69.0043 501.259 70.4657 502.525 70.7956C503.799 71.1335 504.389 70.2142 503.846 68.7529C503.304 67.2916 501.849 65.846 500.591 65.516C499.341 65.186 498.751 66.0974 499.278 67.5509Z" fill="#19B8F7" />
      <path d="M492.02 65.6731C492.523 67.1187 493.955 68.5564 495.22 68.8864C496.486 69.2164 497.092 68.2972 496.573 66.8437C496.054 65.3902 494.615 63.9603 493.373 63.6382C492.122 63.3161 491.517 64.2275 492.02 65.6731Z" fill="#19B8F7" />
      <path d="M484.786 63.8504C485.258 65.2881 486.673 66.7102 487.931 67.0323C489.197 67.3544 489.819 66.4352 489.323 64.9974C488.828 63.5518 487.42 62.1376 486.17 61.8234C484.928 61.5091 484.307 62.4126 484.778 63.8504H484.786Z" fill="#19B8F7" />
      <path d="M477.576 62.067C478.024 63.489 479.408 64.9032 480.666 65.2175C481.924 65.5317 482.569 64.6125 482.097 63.1826C481.625 61.7527 480.241 60.3542 478.999 60.0478C477.757 59.7414 477.12 60.6449 477.568 62.067H477.576Z" fill="#19B8F7" />
      <path d="M470.397 60.3307C470.821 61.7449 472.182 63.1433 473.432 63.4419C474.69 63.7483 475.35 62.8291 474.902 61.4149C474.462 59.9928 473.094 58.6101 471.859 58.3115C470.625 58.013 469.972 58.9165 470.389 60.3307H470.397Z" fill="#19B8F7" />
      <path d="M463.241 58.6415C463.635 60.04 464.971 61.4306 466.221 61.7213C467.472 62.0198 468.148 61.1085 467.731 59.6943C467.314 58.2801 465.978 56.913 464.751 56.6302C463.524 56.3395 462.848 57.243 463.241 58.6415Z" fill="#19B8F7" />
      <path d="M456.109 56.9994C456.479 58.39 457.784 59.7571 459.027 60.0478C460.269 60.3385 460.969 59.4271 460.584 58.0286C460.198 56.6302 458.885 55.2788 457.658 54.996C456.432 54.7132 455.74 55.6088 456.109 56.9994Z" fill="#19B8F7" />
      <path d="M449.017 55.4046C449.355 56.7874 450.637 58.1387 451.871 58.4137C453.114 58.6965 453.829 57.7851 453.468 56.3945C453.106 55.0039 451.824 53.6683 450.605 53.4012C449.387 53.134 448.679 54.0218 449.017 55.4046Z" fill="#19B8F7" />
      <path d="M441.964 53.8569C442.271 55.2239 443.521 56.5674 444.755 56.8345C445.99 57.1016 446.721 56.1981 446.391 54.8232C446.061 53.4483 444.802 52.1284 443.592 51.8613C442.381 51.602 441.649 52.482 441.964 53.8569Z" fill="#19B8F7" />
      <path d="M434.942 52.3562C435.225 53.7154 436.444 55.0353 437.671 55.3025C438.897 55.5696 439.652 54.6582 439.346 53.299C439.047 51.932 437.82 50.6278 436.609 50.3685C435.406 50.1171 434.659 50.9971 434.934 52.3562H434.942Z" fill="#19B8F7" />
      <path d="M427.96 50.8949C428.211 52.2462 429.399 53.5504 430.617 53.8096C431.836 54.0689 432.615 53.1654 432.339 51.8062C432.064 50.447 430.869 49.1585 429.674 48.915C428.479 48.6714 427.708 49.5514 427.96 50.8949Z" fill="#19B8F7" />
      <path d="M414.113 48.1215C414.302 49.4492 415.426 50.7299 416.637 50.9656C417.848 51.2091 418.658 50.3056 418.445 48.97C418.233 47.6344 417.101 46.3773 415.914 46.1416C414.726 45.9138 413.924 46.7859 414.113 48.1136V48.1215Z" fill="#19B8F7" />
      <path d="M407.248 46.8017C407.406 48.1216 408.499 49.3865 409.702 49.6143C410.905 49.8422 411.73 48.9465 411.549 47.6266C411.369 46.3067 410.26 45.0575 409.088 44.8375C407.909 44.6175 407.091 45.4896 407.248 46.8017Z" fill="#19B8F7" />
      <path d="M400.431 45.521C400.557 46.8252 401.618 48.0822 402.814 48.3022C404.009 48.53 404.858 47.6344 404.709 46.3224C404.559 45.0103 403.49 43.7768 402.318 43.5647C401.147 43.3447 400.305 44.2168 400.431 45.5288V45.521Z" fill="#19B8F7" />
      <path d="M393.661 44.2797C393.755 45.576 394.785 46.8174 395.973 47.0295C397.16 47.2495 398.025 46.3538 397.907 45.0496C397.789 43.7454 396.751 42.5277 395.587 42.3234C394.424 42.1113 393.567 42.9834 393.661 44.2797Z" fill="#19B8F7" />
      <path d="M386.93 43.0854C386.993 44.3739 387.992 45.5995 389.171 45.8038C390.351 46.0159 391.239 45.1203 391.153 43.8318C391.066 42.5354 390.06 41.3334 388.904 41.137C387.748 40.9327 386.867 41.8048 386.93 43.0854Z" fill="#19B8F7" />
      <path d="M380.247 41.9306C380.278 43.2112 381.245 44.4211 382.417 44.6254C383.588 44.8297 384.493 43.9419 384.445 42.6534C384.39 41.3649 383.415 40.1786 382.267 39.9822C381.119 39.7857 380.223 40.65 380.254 41.9306H380.247Z" fill="#19B8F7" />
      <path d="M373.61 40.8149C373.61 42.0798 374.538 43.2819 375.702 43.4783C376.865 43.6747 377.793 42.7869 377.77 41.5141C377.746 40.2414 376.803 39.0629 375.67 38.8743C374.53 38.6858 373.61 39.55 373.61 40.8149Z" fill="#19B8F7" />
      <path d="M575.589 82.4076C576.312 83.9475 577.917 85.5345 579.175 85.9431C580.433 86.3516 580.857 85.4324 580.118 83.8846C579.387 82.3369 577.783 80.7499 576.533 80.3492C575.282 79.9485 574.858 80.8677 575.589 82.4155V82.4076Z" fill="#19B8F7" />
      <path d="M568.363 80.0899C569.078 81.622 570.675 83.1933 571.933 83.6018C573.191 84.0025 573.623 83.0833 572.9 81.5434C572.176 80.0035 570.58 78.4322 569.33 78.0315C568.08 77.6387 567.647 78.5579 568.363 80.0899Z" fill="#19B8F7" />
      <path d="M561.129 77.8193C561.829 79.3435 563.417 80.9069 564.675 81.2998C565.933 81.6926 566.381 80.7734 565.674 79.2413C564.966 77.7093 563.378 76.1537 562.127 75.7609C560.877 75.3681 560.429 76.2951 561.129 77.8193Z" fill="#19B8F7" />
      <path d="M553.895 75.5881C554.579 77.1044 556.159 78.6521 557.417 79.0371C558.676 79.4221 559.139 78.5029 558.44 76.9787C557.748 75.4545 556.167 73.9068 554.917 73.5296C553.667 73.1447 553.211 74.0639 553.895 75.5881Z" fill="#19B8F7" />
      <path d="M546.661 73.404C547.329 74.9124 548.894 76.4445 550.152 76.8216C551.41 77.1987 551.882 76.2795 551.198 74.771C550.514 73.2547 548.949 71.7305 547.699 71.3534C546.448 70.9763 545.984 71.8955 546.653 73.404H546.661Z" fill="#19B8F7" />
      <path d="M539.427 71.267C540.079 72.7676 541.628 74.284 542.886 74.6532C544.145 75.0303 544.632 74.1111 543.964 72.6027C543.295 71.0942 541.746 69.5857 540.496 69.2165C539.246 68.8472 538.766 69.7664 539.419 71.267H539.427Z" fill="#19B8F7" />
      <path d="M532.201 69.1692C532.837 70.6541 534.371 72.1626 535.629 72.524C536.887 72.8932 537.382 71.974 536.738 70.4734C536.085 68.9807 534.552 67.4801 533.309 67.1187C532.059 66.7573 531.572 67.6765 532.201 69.1614V69.1692Z" fill="#19B8F7" />
      <path d="M524.99 67.1187C525.603 68.5957 527.121 70.0806 528.379 70.442C529.637 70.8034 530.148 69.8842 529.519 68.3993C528.89 66.9144 527.373 65.4295 526.122 65.0838C524.88 64.7303 524.369 65.6417 524.982 67.1187H524.99Z" fill="#19B8F7" />
      <path d="M517.788 65.1152C518.385 66.5766 519.879 68.0536 521.137 68.4071C522.395 68.7528 522.914 67.8415 522.309 66.3723C521.695 64.9031 520.194 63.4339 518.959 63.0882C517.717 62.7504 517.198 63.6539 517.788 65.1152Z" fill="#19B8F7" />
      <path d="M510.601 63.159C511.175 64.6125 512.653 66.0738 513.911 66.4116C515.169 66.7573 515.704 65.8381 515.114 64.3768C514.524 62.9155 513.038 61.462 511.804 61.132C510.569 60.8021 510.027 61.7056 510.601 63.159Z" fill="#19B8F7" />
      <path d="M503.437 61.2577C503.988 62.7033 505.45 64.141 506.701 64.4788C507.951 64.8088 508.509 63.9053 507.935 62.4518C507.369 60.9984 505.906 59.5685 504.672 59.2385C503.437 58.9164 502.887 59.812 503.437 61.2577Z" fill="#19B8F7" />
      <path d="M496.298 59.3957C496.825 60.8256 498.263 62.2555 499.514 62.5776C500.764 62.8998 501.33 61.9963 500.788 60.5585C500.245 59.1207 498.798 57.6987 497.571 57.3844C496.345 57.0702 495.771 57.9658 496.306 59.3957H496.298Z" fill="#19B8F7" />
      <path d="M489.174 57.5808C489.677 58.995 491.092 60.4092 492.335 60.7313C493.577 61.0455 494.167 60.142 493.64 58.72C493.121 57.2901 491.698 55.8916 490.471 55.5852C489.245 55.2788 488.663 56.1745 489.166 57.5887L489.174 57.5808Z" fill="#19B8F7" />
      <path d="M482.081 55.813C482.561 57.2194 483.953 58.61 485.187 58.9242C486.43 59.2307 487.027 58.335 486.532 56.9208C486.036 55.5066 484.637 54.1239 483.418 53.8253C482.199 53.5268 481.602 54.4146 482.081 55.8209V55.813Z" fill="#19B8F7" />
      <path d="M460.977 50.8084C461.378 52.1755 462.691 53.5268 463.91 53.8096C465.136 54.0925 465.789 53.1968 465.364 51.8219C464.948 50.447 463.627 49.1114 462.424 48.8364C461.221 48.5614 460.568 49.4414 460.969 50.8084H460.977Z" fill="#19B8F7" />
      <path d="M454.01 49.2293C454.38 50.5806 455.669 51.9162 456.888 52.1912C458.107 52.4662 458.775 51.5784 458.382 50.2113C457.989 48.8443 456.699 47.5322 455.504 47.2651C454.309 46.998 453.641 47.8701 454.01 49.2293Z" fill="#19B8F7" />
      <path d="M447.083 47.7051C447.429 49.0486 448.687 50.3607 449.898 50.6278C451.109 50.8949 451.801 50.0071 451.431 48.6558C451.069 47.3045 449.803 46.0081 448.616 45.7489C447.429 45.4896 446.745 46.3617 447.083 47.7051Z" fill="#19B8F7" />
      <path d="M592.935 80.742C593.69 82.3055 595.302 83.9161 596.544 84.3403C597.786 84.7646 598.172 83.8375 597.417 82.2662C596.662 80.6949 595.042 79.0843 593.808 78.6679C592.573 78.2515 592.18 79.1864 592.935 80.7499V80.742Z" fill="#19B8F7" />
      <path d="M585.787 78.3458C586.534 79.9014 588.146 81.4963 589.389 81.9127C590.631 82.3291 591.024 81.402 590.277 79.8385C589.522 78.2751 587.91 76.6723 586.676 76.2638C585.441 75.8552 585.04 76.7823 585.787 78.3379V78.3458Z" fill="#19B8F7" />
      <path d="M571.484 73.6947C572.208 75.2346 573.812 76.8059 575.054 77.2066C576.297 77.6073 576.713 76.6802 575.982 75.1403C575.251 73.5925 573.647 72.0212 572.412 71.6284C571.178 71.2356 570.761 72.1627 571.484 73.6947Z" fill="#19B8F7" />
      <path d="M542.863 64.9189C543.531 66.4117 545.088 67.928 546.33 68.2972C547.573 68.6665 548.029 67.7473 547.353 66.2467C546.669 64.7461 545.12 63.2298 543.885 62.8683C542.651 62.5069 542.194 63.4183 542.871 64.9189H542.863Z" fill="#19B8F7" />
      <path d="M535.723 62.8369C536.376 64.3218 537.917 65.8146 539.159 66.1838C540.402 66.5452 540.873 65.6339 540.205 64.1411C539.537 62.6484 537.996 61.1556 536.769 60.8021C535.542 60.4485 535.071 61.3599 535.723 62.8448V62.8369Z" fill="#19B8F7" />
      <path d="M528.607 60.8098C529.244 62.279 530.762 63.7639 532.004 64.1174C533.246 64.471 533.726 63.5596 533.073 62.0826C532.421 60.6055 530.903 59.1285 529.677 58.7828C528.45 58.4371 527.97 59.3485 528.607 60.8177V60.8098Z" fill="#19B8F7" />
      <path d="M521.499 58.8301C522.112 60.2835 523.622 61.7527 524.856 62.0984C526.099 62.4441 526.586 61.5327 525.957 60.0714C525.328 58.6022 523.819 57.1488 522.6 56.8109C521.373 56.4731 520.886 57.3766 521.499 58.8301Z" fill="#19B8F7" />
      <path d="M514.414 56.8894C515.012 58.3351 516.498 59.7807 517.733 60.1185C518.967 60.4563 519.478 59.5528 518.865 58.0994C518.252 56.6459 516.765 55.2081 515.547 54.8782C514.328 54.5482 513.825 55.4517 514.414 56.8894Z" fill="#19B8F7" />
      <path d="M507.345 55.0118C507.919 56.4416 509.382 57.8715 510.616 58.2015C511.851 58.5315 512.37 57.628 511.78 56.1902C511.19 54.7525 509.72 53.3304 508.509 53.0083C507.29 52.6862 506.771 53.5819 507.345 55.0118Z" fill="#19B8F7" />
      <path d="M493.294 51.3821C493.821 52.7884 495.244 54.179 496.471 54.4933C497.697 54.8075 498.248 53.9119 497.697 52.4977C497.147 51.0835 495.724 49.7007 494.521 49.3943C493.31 49.0879 492.767 49.9757 493.294 51.3821Z" fill="#19B8F7" />
      <path d="M486.312 49.6457C486.815 51.0363 488.215 52.4112 489.433 52.7176C490.652 53.024 491.218 52.1284 490.691 50.7299C490.165 49.3314 488.765 47.9644 487.57 47.6658C486.367 47.3751 485.808 48.2551 486.312 49.6378V49.6457Z" fill="#19B8F7" />
      <path className="scene9__light-cone" style={{mixBlendMode: "multiply"}} d="M147.287 105.042L135.139 36.1874L184.173 39.6993L152.5 105.553C152.5 105.553 150.943 108.067 147.295 105.042H147.287Z" fill="url(#paint0_linear_949_4949)" />
      <path className="scene9__light-cone" style={{mixBlendMode: "multiply"}} d="M207.951 195.267L195.802 126.412L244.837 129.924L213.164 195.778C213.164 195.778 211.607 198.292 207.959 195.267H207.951Z" fill="url(#paint1_linear_949_4949)" />
      <path className="scene9__light-cone" style={{mixBlendMode: "multiply"}} d="M236.887 120.017L224.739 51.162L273.773 54.6739L242.1 120.528C242.1 120.528 240.543 123.042 236.895 120.017H236.887Z" fill="url(#paint2_linear_949_4949)" />
      <path className="scene9__light-cone" style={{mixBlendMode: "multiply"}} d="M299.43 174.762L287.281 105.907L336.316 109.419L304.643 175.272C304.643 175.272 303.086 177.786 299.438 174.762H299.43Z" fill="url(#paint3_linear_949_4949)" />
      <path className="scene9__light-cone" style={{mixBlendMode: "multiply"}} d="M276.415 228.76L264.266 159.905L313.3 163.417L281.628 229.27C281.628 229.27 280.071 231.785 276.422 228.76H276.415Z" fill="url(#paint4_linear_949_4949)" />
      <path className="scene9__light-cone" style={{mixBlendMode: "multiply"}} d="M342.582 207.351L330.434 138.496L379.468 142.007L347.796 207.861C347.796 207.861 346.239 210.375 342.59 207.351H342.582Z" fill="url(#paint5_linear_949_4949)" />
      <path className="scene9__light-cone" style={{mixBlendMode: "multiply"}} d="M441.94 166.936L429.792 98.0814L478.826 101.593L447.153 167.447C447.153 167.447 445.597 169.961 441.948 166.936H441.94Z" fill="url(#paint6_linear_949_4949)" />
      <path className="scene9__light-cone" style={{mixBlendMode: "multiply"}} d="M462.817 256.266L450.668 187.411L499.702 190.923L468.03 256.776C468.03 256.776 466.473 259.29 462.825 256.266H462.817Z" fill="url(#paint7_linear_949_4949)" />
      <path className="scene9__light-cone" style={{mixBlendMode: "multiply"}} d="M491.407 68.855L479.251 0L528.285 3.51189L496.612 69.3657C496.612 69.3657 495.055 71.8798 491.407 68.855Z" fill="url(#paint8_linear_949_4949)" />
      <path className="scene9__data-line" d="M240.834 122.075C207.786 84.356 149.418 105.797 149.418 105.797" stroke="url(#paint9_linear_949_4949)" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" />
      <path className="scene9__data-line" d="M302.599 171.776C302.599 171.776 278.42 122.17 148.372 109.631" stroke="url(#paint12_linear_949_4949)" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" />
      <path className="scene9__data-line" d="M148.718 100.926C148.718 100.926 228.882 137.113 210.766 193.044" stroke="url(#paint10_linear_949_4949)" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" />
      <path className="scene9__data-line" d="M446.469 170.92C446.469 170.92 344.58 1.78349 213.793 193.484" stroke="url(#paint17_linear_949_4949)" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" />
      <path className="scene9__data-line" d="M282.91 231.824C282.91 231.824 278.687 171.21 208.651 189.713" stroke="url(#paint13_linear_949_4949)" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" />
      <path className="scene9__data-line" d="M278.255 224.737C278.255 224.737 313.615 186.248 347.788 207.853" stroke="url(#paint11_linear_949_4949)" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" />
      <path className="scene9__data-line" d="M235.361 120.606C235.361 120.606 297.944 101.798 307.183 168.508C307.183 168.508 345.185 157.186 348.668 203.862" stroke="url(#paint14_linear_949_4949)" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" />
      <path className="scene9__data-line" d="M495.165 64.9974C495.165 64.9974 368.397 42.5826 342.582 207.343" stroke="url(#paint15_linear_949_4949)" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" />
      <path className="scene9__data-line" d="M467.377 256.116C557.85 129.665 496.612 69.3657 496.612 69.3657C496.612 69.3657 438.056 81.1663 444.346 167.596" stroke="url(#paint16_linear_949_4949)" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" />
    </svg>
    <AdvancedImage
      className="scene-9__data-stream-1"
      cldImg={cld.image("components/we-are-animation/data-stream")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a line depicting a stream of data"
    />
    <AdvancedImage
      className="scene-9__data-stream-2"
      cldImg={cld.image("components/we-are-animation/data-stream")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a line depicting a stream of data"
    />
    <AdvancedImage
      className="scene-9__data-stream-3"
      cldImg={cld.image("components/we-are-animation/data-stream")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a line depicting a stream of data"
    />
    <AdvancedImage
      className="scene-9__data-stream-4"
      cldImg={cld.image("components/we-are-animation/data-stream")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a line depicting a stream of data"
    />
    <AdvancedImage
      className="scene-9__server-stack-1"
      cldImg={cld.image("components/we-are-animation/server-stack")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a stack of server blades"
    />
    <AdvancedImage
      className="scene-9__server-stack-2"
      cldImg={cld.image("components/we-are-animation/server-stack")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a stack of server blades"
    />
    <AdvancedImage
      className="scene-9__server-rack-1"
      cldImg={cld.image("components/we-are-animation/server-rack")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a server rack"
    />
    <AdvancedImage
      className="scene-9__server-rack-2"
      cldImg={cld.image("components/we-are-animation/server-rack")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a server rack"
    />
    <AdvancedImage
      className="scene-9__user"
      cldImg={cld.image("components/we-are-animation/user-accessing-user-network")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="A vigilant network of user monitoring the users of a network"
    />
    <AdvancedImage
      className="scene-9__mobile-phone"
      cldImg={cld.image("components/we-are-animation/mobile-phone-standing-side")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Side profile of a mobile phone"
    />
    <AdvancedImage
      className="scene-9__laptop"
      cldImg={cld.image("components/we-are-animation/laptop")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="A laptop device"
    />
    <AdvancedImage
      className="scene-9__cloud-data-transfer"
      cldImg={cld.image("components/we-are-animation/cloud-data-transfer").resize(scale().width(400)).format("webp")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="cloud with upload and download arrows"
    />
  </div>

}

export default Scene9;