import React from 'react'
import { Link } from 'react-router-dom';

type Props = {
  to: string;
  newWindow: boolean;
  children: React.ReactNode;
  className?: string;
  style?: object;
}

const Hyperlink: React.FC<Props> = props => props.newWindow ?
  <a
    href={props.to}
    target="_blank"
    rel="noopener noreferrer"
    className={props.className ? props.className : ''}
    style={props.style}
  >
    {props.children && props.children}
  </a>
    :
  <Link
    to={props.to}
    className={props.className ? props.className : ''}
    style={props.style}
  >
    {props.children && props.children}
  </Link>
;

export default Hyperlink;
