import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import cld from '../../../../constants/cloudinary';
import { AdvancedImage, lazyload } from '@cloudinary/react';

import "./index.scss";

/**
 * Scene 14
 * Partner You Trust
 */

export const scene14Timeline = gsap.timeline();

const Scene14 = () => {

  useGSAP(() => scene14Timeline
    .fromTo(".scene-14__handshake", { 
      autoAlpha: 0 
    }, { 
      autoAlpha: 1,
      duration: 2,
      ease: "power2.out"
    })
    // cleanup
    .to([
      ".scene-14__handshake",
      ".scenes__gradient-bg"
    ], {
      autoAlpha: 0,
      duration: 1,
      ease: "power2.out"
    })
  );

  return <div className="scenes scenes__scene-14">
    <AdvancedImage 
      className="scene-14__handshake floating"
      cldImg={cld.image("components/we-are-animation/handshake")}
      plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 })]}
      alt="Two wireframe grid like hands doing a handshake"
    />
  </div>


}

export default Scene14;