import { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import * as COOKIE from '../utils/cookies';
import SearchParams from '../utils/SearchParams';

const CookieOptin = () => {
 
  const cookieBanner = useRef<HTMLDivElement>(null);
  const [showBanner, setShowBanner] = useState<Boolean>(false);
  const gdpr = COOKIE.get('gdpr');
  let disableBanner = SearchParams("popup");
  
  useEffect(() => {
    if (gdpr === null || disableBanner === "0") {
      setShowBanner(true);
    }
  }, [setShowBanner, gdpr, disableBanner])

  const acceptCookies = ():void => {
    COOKIE.set('gdpr', 1, 365);
    if (cookieBanner.current) {
      cookieBanner.current.classList.remove("show");
      setTimeout(() => {
        setShowBanner(false);
      }, 1000);
    }
  }

  if (!showBanner) return <Fragment></Fragment>;

  setTimeout(() => {
    if (cookieBanner.current) {
      cookieBanner.current.classList.add("show");
    }
  }, 700);

  return <div
    id="cookieBanner"
    ref={cookieBanner}
  >
    <p><small>
      This site uses cookies to deliver our services. By using our site, you acknowledge that you have read and
            understand our <a href='/cookie-policy' target='_blank'>Cookie Policy</a>, <a href='/privacy-policy'
        target='_blank'>Privacy Policy</a>, and our <a href='/terms-of-service' target='_blank'>Terms of Service</a>.
            Your use of Infinite Peripheral’s Products and Services is subject to these policies and terms.
          </small></p>
    <button
      className="btn btn-primary me-4"
      onClick={acceptCookies}
    >
      Dismiss
      </button>
    <Link to='/cookie-policy' className="btn btn-link-primary">Learn More</Link>
  </div>;

}

export default CookieOptin;
