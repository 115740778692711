import React from 'react';
import { useLocation } from 'react-router-dom'
import currentOffsetTop from '../../utils/currentOffsetTop';
import { IAction } from "../../types";

declare global {
  interface Window {
    attachEvent(event: string, listener: EventListener): boolean;
    detachEvent(event: string, listener: EventListener): void;
  }
}

interface ISetErrors {
  message: string; 
  list: string[];
}

interface IState {
  breakpoint: {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
  };
  errors: {
    message: string;
    list: string[];
    setErrors: (errors: ISetErrors) => void;
    clearErrors: () => void;
    report: (props: any) => void;
  }
  featuredEvent: object | any;
  history: any[];
  h: number;
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  jumpTo: (id: string | number) => void
  modalForm: {
    name: string;
    height: number;
    visible: boolean;
    iframeSrc: string | null;
    setHeight: (height: number) => void;
    formName: (src: string) => void;
    toggle: () => void;
  };
  nav: {
    expanded: boolean;
    toggle: Function;
  };
  siteBanner: object | any;
  scroll: {
    direction: string;
  };
  theme: {
    mode: boolean;
    setTheme: Function;
  }
  titleSuffix: (title: string) => string;
  w: number;
}

type Props = {
  children?: React.ReactNode;
}

const initialState: IState = {
  breakpoint: {
    xs: 575.98,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  errors: {
    message: '',
    list: [],
    setErrors: (errors: ISetErrors) => {},
    clearErrors: () => {},
    report: (props: any) => {
      if (process.env.NODE_ENV === 'development') {
        console.log(props);
      }
      switch (true) {
        case props.code !== undefined && props.code === 'permission-denied':
          props.history.push('/404');
          break;
        default:
          break;
      }
    }
  },
  featuredEvent: {},
  h: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
  history: [],
  isDesktop: ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) >= 992) ? true : false,
  isMobile: ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 768) ? true : false,
  isTablet: ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) >= 768 && (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 992) ? true : false,
  jumpTo: (location: string | number) => {
    if (typeof location === "string") {
      window.scrollTo(0, document.getElementById(location)!.offsetTop || 0);
    } else if (typeof location === "number") {
      window.scrollTo(0, location || 0);
    } else {
      window.scrollTo(0, 0);
    }
  },
  modalForm: {
    name: "DEFAULT",
    height: 300,
    visible: false,
    iframeSrc: null,
    setHeight: (height: number) => {},
    formName: (src: string) => {},
    toggle: () => {}
  },
  nav: {
    expanded: false,
    toggle: () => {}
  },
  siteBanner: {},
  scroll: {
    direction: "down"
  },
  theme: {
    mode: window.localStorage.getItem("theme-dark") !== null ? window.localStorage.getItem("theme-dark") === "true" ? true : false : true, // check if local storage has a theme value if it does set it else default to dark mode
    setTheme: () => {}
  },
  titleSuffix: (title: string) => `${title} - IPCMobile`,
  w: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
}

export const PageContext = React.createContext(initialState);

const PageState: React.FC<Props> = (props) => {
  
  const [state, dispatch] = React.useReducer(Reducer, initialState);
  let location = useLocation();

  React.useEffect(() => {

    let isFirstRender = true;
    let prevLocation = window.location.pathname + window.location.hash;
    
    const applyTheme = (mode: boolean) => {
      document.documentElement.dataset.bsTheme = mode ? "dark" : "light";
      window.localStorage.setItem("theme-dark", mode.toString());
      dispatch({
        type: "SET_THEME",
        payload: {
          mode
        }
      });
    }

    const setPageSizing = ():void =>  {
      dispatch({
        type: "SET_PAGE_SIZING",
        payload: {
          width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
          height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
          isMobile: ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 768) ? true : false,
          isTablet: ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) >= 768 && (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 992) ? true : false,
          isDesktop: ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) >= 992) ? true : false,
        }
      })
    }

    // scrollDirection has event listerner below
    let lastScroll: number = 0;
    let scrollDir: string;
    const scrollDirection = ():void => {
      if (!scrollDir) scrollDir = "down";
      const direction = (currentOffsetTop() < lastScroll) ? "up" : "down";
      if (direction !== scrollDir)  {
        dispatch({
          type: "SET_SCROLL_DIRECTION",
          payload: {
            scroll: {
              direction
            }
          }
        })
        scrollDir = direction;
      }
      lastScroll = currentOffsetTop();
    }

    // check if new page has loaded and scroll to top
    let currentPath = window.location.pathname + window.location.hash;
    if (prevLocation !== currentPath) {

      prevLocation = currentPath;
    }

    if (isFirstRender) {

      // scroll to downlods section of page if url has hash #downloads
      if (window.location.hash.length) {
        const hashInterval = setInterval(() => {
          const hashId = document.getElementById(window.location.hash.substring(1));
          if (hashId) {
            clearInterval(hashInterval);
            window.scrollTo(0, hashId.offsetTop);
          }
        }, 1000);
        setTimeout(() => clearInterval(hashInterval), 5000);
      }

      dispatch({
        type: "SET_PAGE_STATE",
        payload: {
          errors: {
            setErrors: (errors: ISetErrors) => {
              dispatch({
                type: "SET_ERRORS",
                payload: {
                  message: errors.message,
                  list: errors.list
                }
              })
            },
            clearErrors: () => {
              dispatch({
                type: "SET_ERRORS",
                payload: {
                  message: '',
                  list: []
                }
              })
            },
            report: (props: any) => {}
          },
          modalForm: {
            setHeight: (height: number) => {
              dispatch({
                type: "SET_MODAL_HEIGHT",
                payload: { height }
              })
            },
            formName: (formName: string | undefined) => {
              dispatch({
                type: "SET_MODALFORM_NAME",
                payload: {
                  name: formName !== undefined ? formName : "DEFAULT"
                }
              })
            },
            toggle: () => dispatch({ type: "TOGGLE_MODALFORM" })
          },
          nav: {
            toggle: (action: string | boolean) => {
                dispatch({
                  type: "TOGGLE_NAV",
                  payload: {
                    action: (action === 'open' || action === true)
                  }
                })
            }
          },
          theme: {
            setTheme: (mode: boolean) => applyTheme(mode)
          },
          siteBanner: {}
        }
      });
      dispatch({
        type: "SET_HISTORY",
        payload: {
          history: prevLocation
        }
      })

      // Apply initial theme
      applyTheme(
        window.localStorage.getItem("theme-dark") !== null ? 
        window.localStorage.getItem("theme-dark") === "true" : 
        true
      );
      
      isFirstRender = false;
    }

    if(window.attachEvent) {
      window.attachEvent('onresize', setPageSizing);
      window.attachEvent('scroll', scrollDirection);
    } else if(window.addEventListener) {
      window.addEventListener('resize', setPageSizing, true);
      window.addEventListener('scroll', scrollDirection, true);
      window.addEventListener('storage', () => applyTheme(window.localStorage.getItem("theme-dark") === "true"), true);
    } else {
        //The browser does not support Javascript event binding
    }

    return () => {

      dispatch({
        type: "TOGGLE_NAV",
        payload: {
          action: false
        }
      })

      if(window.detachEvent) {
        window.detachEvent('onresize', setPageSizing);
        window.detachEvent('scroll', scrollDirection);
      } else if(window.removeEventListener) {
          window.removeEventListener('resize', setPageSizing, true);
          window.removeEventListener('scroll', scrollDirection, true);
      } else {
          //The browser does not support Javascript event binding
      }
    }

  }, [location, state.theme.mode]);

  
  return <PageContext.Provider value={{ ...state }}>
    {props.children}
  </PageContext.Provider>;
  
}

const Reducer = (state: IState, action: IAction): IState => {
  const {type, payload} = action;
  switch (type) {
    case "SET_PAGE_STATE":
      return {
        ...state,
        featuredEvent: payload.featuredEvent,
        nav: {
          ...state.nav,
          toggle: payload.nav.toggle
        },
        siteBanner: payload.siteBanner,
        errors: {
          ...state.errors,
          setErrors: payload.errors.setErrors,
          clearErrors: payload.errors.clearErrors,
          report: payload.errors.report
        },
        modalForm: {
          ...state.modalForm,
          setHeight: payload.modalForm.setHeight,
          formName: payload.modalForm.formName,
          toggle: payload.modalForm.toggle,
        },
        theme: {
          ...state.theme,
          setTheme: payload.theme.setTheme
        }
      }
    case "SET_THEME": {
      return {
        ...state,
        theme: {
          ...state.theme,
          mode: payload.mode
        }
      }
    }
    case "SET_MODALFORM_HEIGHT":
      return {
        ...state,
        modalForm: {
          ...state.modalForm,
          height: payload.height
        }
      }
    case "SET_MODALFORM_NAME":
      return {
        ...state,
        modalForm: {
          ...state.modalForm,
          name: payload.name
        }
      }
    case "TOGGLE_MODALFORM":
      return {
        ...state,
        modalForm: {
          ...state.modalForm,
          visible: !state.modalForm.visible
        }
      }
    case "TOGGLE_NAV":
      return {
        ...state,
        nav: {
          ...state.nav,
          expanded: payload.action
        }
      }
    case "SET_ERRORS":
      return {
        ...state,
        errors: {
          ...state.errors,
          message: payload.message,
          list: payload.list
        }
      }
    case 'SET_HISTORY':
      return {
        ...state,
        history: [...state.history, payload.history]
      }
    case "SET_PAGE_SIZING":
      return {
        ...state,
        w: payload.width,
        h: payload.height,
        isMobile: payload.isMobile,
        isTablet: payload.isTablet,
        isDesktop: payload.isDesktop
      }
    case "SET_SCROLL_DIRECTION":
      return {
        ...state,
        scroll: {
          ...state.scroll,
          direction: payload.scroll.direction
        }
      }
    default:
      return state;
  }
};

export default PageState;