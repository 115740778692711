import { useEffect, useState } from 'react';
import { db } from '../../context/AuthState';
import { query, getDocs, collection, where, orderBy, limit, DocumentData } from "firebase/firestore";
import cld from '../../constants/cloudinary';
import { AdvancedImage, lazyload, responsive } from '@cloudinary/react';
import Slider from 'react-slick';

import "./index.scss";

const CardSlider = () => {

  const [state, setState] = useState<{ [key: string]: any }[]>([]);

  useEffect(() => {

    const getData = async () => {
      let data: DocumentData[] = [];

      [
        "blogs",
        "news"
      ].map(async(entry) => {

        const q = query(
          collection(db, entry),
          where('isPublic', '==', true),
          orderBy('datePublished', 'desc'),
          limit(4)
        );

        const snapShot = await getDocs(q);

        snapShot.forEach((doc) => {
          const docData = doc.data();
          const seconds: string = docData.datePublished.seconds.toString();
          const dateStr: string = docData.datePublished.toDate().toString();
          const dateArr: any = dateStr.split(" ");
          data.push({
            ...docData,
            className: entry,
            datePublished: {
              day: dateArr[0],
              month: dateArr[1],
              date: dateArr[2],
              year: dateArr[3],
              seconds
            }
          });
        });

        setState(data.sort((a, b) => b.datePublished.seconds - a.datePublished.seconds));
      });


    }

    getData();

  }, []);

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    laxyLoad: 'progressive',
    cssEase: 'ease-in-out',
    dots: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  if (!state.length) return <></>

  return <div className="container card-slider">
    <div className="row">
      <div className="col article-cards">
        <Slider {...settings}>
          {state.map((entry, index) => <a
            key={index}
            href={`${entry.className === "blogs" ? "blog/" : ''}${(entry.slug_origin !== undefined) ? entry.slug_origin : entry.slug}`}
            className={entry.className === "blogs" ? "blog" : "news"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="card">
              <div className="card-img-top">
                {entry.cover !== undefined && <AdvancedImage
                  cldImg={cld.image(entry.cover).format("auto").quality("auto")}
                  plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }), responsive({ steps: [300, 500] })]}
                  alt={entry.title}
                />}
              </div>
              <div className="card-body">
                <div className="card-date float-start">
                  {entry.datePublished !== undefined && <div className="card-date-wrap">
                    <div className="card-date-month">{entry.datePublished.month}</div>
                    <div className="card-date-day">{entry.datePublished.date}</div>
                  </div>}
                </div>
                <div className="card-content">
                  <h3 className="card-title mb-1 h4" dangerouslySetInnerHTML={{ __html: entry.title }} />
                  {/* <p className="mb-0" dangerouslySetInnerHTML={{ __html: entry.blurb }} /> */}
                </div>
              </div>
              {entry.title && <div
                className="btn btn-link-primary">
                Read More
                <img
                  className="btn-icon right-arrow"
                  src="https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/chevron-right-green.svg"
                  alt="Chevron point to the right"
                />
              </div>}
            </div>
          </a>)}
        </Slider>
      </div>
    </div>
  </div>
}

export default CardSlider