import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import cld from '../../../../constants/cloudinary';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { scale } from "@cloudinary/url-gen/actions/resize";

import "./index.scss";

/**
 * Scene 8
 * Kioks
 */

export const scene8Timeline = gsap.timeline();

const Scene8 = () => {

  useGSAP(() => scene8Timeline
    .fromTo(".scene-8__rapid-dropoff-station", {
      transform: "translateX(-120%)",
      autoAlpha: 0
    }, {
      transform: "translateX(0%)",
      autoAlpha: 1,
      duration: .5,
      ease: "power2.out"
    })
    // Fade Apple Kiosk In
    .fromTo(".scene-8__apple-kiosk", {
      autoAlpha: 0,
      transform: "translateX(110%)"
    }, {
      transform: "translateX(0)",
      autoAlpha: 1,
      ease: "power2.out",
      duration: .5
    })
    .fromTo(".scene-8__apple-logo", {
      autoAlpha: 0,
      transform: "translateX(300%)"
    }, {
      autoAlpha: 1,
      transform: "translateX(0)",
      ease: "power2.out",
      duration: .5
    }, "<")
    // Fade Apple Kiosk Out
    .to(".scene-8__apple-kiosk", {
      autoAlpha: 0,
      transform: "translateX(110%)",
      ease: "power2.out",
      duration: .5,
      delay: .5
    })
    .to(".scene-8__apple-logo", {
      autoAlpha: 0,
      transform: "translateX(300%)",
      ease: "power2.out",
      duration: .5
    }, "<")
    .fromTo(".scene-8__android-kiosk", {
      autoAlpha: 0,
      transform: "translateX(115%)"
    }, {
      autoAlpha: 1,
      transform: "translateX(0)",
      ease: "power2.out",
      duration: .5
    })
    .fromTo(".scene-8__android-logo", {
      autoAlpha: 0,
      transform: "translateX(300%)"
    }, {
      autoAlpha: 1,
      transform: "translateX(0)",
      ease: "power2.out",
      duration: .5
    }, "<")
    // Fade out RDS & Android Kiosk
    .to(".scene-8__rapid-dropoff-station", {
      autoAlpha: 0,
      transform: "translateX(-110%)",
      ease: "power2.out",
      duration: .5
    }, ">1")
    .to(".scene-8__android-kiosk", {
      autoAlpha: 0,
      transform: "translateX(120%)",
      ease: "power2.out",
      duration: .5
    }, "<")
    .to(".scene-8__android-logo", {
      autoAlpha: 0,
      transform: "translateX(120%)",
      ease: "power2.out",
      duration: .5
    }, "<")
  );

  return <div className="scenes scenes__scene-8">
    <AdvancedImage
      className="scene-8__rapid-dropoff-station"
      cldImg={cld.image("components/we-are-animation/rapid-dropoff-station").resize(scale().width(400)).format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Parcel package rapid drop off station"
    />
    <AdvancedImage
      className="scene-8__apple-kiosk"
      cldImg={cld.image("components/we-are-animation/apple-kiosk").resize(scale().width(400)).format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Kiosk with Apple iOS operating system"
    />
    <AdvancedImage
      className="scene-8__apple-logo"
      cldImg={cld.image("customer-logos/apple-logo-white")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Apple logo"
    />
    <AdvancedImage
      className="scene-8__android-kiosk"
      cldImg={cld.image("components/we-are-animation/android-kiosk-payment").resize(scale().width(400)).format("auto")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Kiosk with Android operating system"
    />
    <AdvancedImage
      className="scene-8__android-logo"
      cldImg={cld.image("components/we-are-animation/android-wearables")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Android logo"
    />
  </div>;


}

export default Scene8;

