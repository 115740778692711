import React from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom';

const CurrentRefinements = ({ items }: any) => <React.Fragment>
  &nbsp;
  {items.length > 0 && <span>
    ({items.length})
  </span>}
</React.Fragment>

const CustomCurrentRefinements = connectCurrentRefinements(CurrentRefinements);

export default CustomCurrentRefinements;
