import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import cld from '../../../../constants/cloudinary';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { scale } from "@cloudinary/url-gen/actions/resize";

import "./index.scss";

/**
 * Scene 7
 * Mobile Printing
 */

export const scene7Timeline = gsap.timeline();

const Scene7 = () => {

  useGSAP(() => scene7Timeline
    .fromTo(".scene-7__mp43", {
      transform: "translateY(50%)",
      autoAlpha: 0
    }, {
      transform: "translateY(0%)",
      autoAlpha: 1
    }, "<.2")
    .fromTo(".scene-7__lkp400", {
      transform: "translateY(50%)",
      autoAlpha: 0
    }, {
      transform: "translateY(0%)",
      autoAlpha: 1
    }, "<.2")
    .fromTo(".scene-7__lkp300", {
      transform: "translateY(50%)",
      autoAlpha: 0
    }, {
      transform: "translateY(0%)",
      autoAlpha: 1
    }, "<.2")
    .fromTo(".scene-7__mp22", {
      transform: "translateY(50%)",
      autoAlpha: 0
    }, {
      transform: "translateY(0%)",
      autoAlpha: 1
    }, "<.2")
    .fromTo(".scene-7__wpp250", {
      transform: "translateY(50%)",
      autoAlpha: 0
    }, {
      transform: "translateY(0%)",
      autoAlpha: 1
    }, "<.2")
    .to([
      ".scene-7__mp43",
      ".scene-7__lkp400",
      ".scene-7__lkp300",
      ".scene-7__mp22",
      ".scene-7__wpp250"
    ], {
      autoAlpha: 0,
      duration: .5,
      delay: 1
    })
  );

  return <div className="scenes scenes__scene-7">
    <AdvancedImage
      className="scene-7__mp43"
      cldImg={cld.image("devices/mp43").resize(scale().width(400)).format("auto")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Mobile Printer MP43"
    />
    <AdvancedImage
      className="scene-7__lkp400"
      cldImg={cld.image("components/we-are-animation/lk-p400").resize(scale().width(400)).format("auto")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Mobile Printer LK-P400"
    />
    <AdvancedImage
      className="scene-7__lkp300"
      cldImg={cld.image("components/we-are-animation/lk-p300").resize(scale().width(400)).format("auto")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Mobile Printer LK-P300"
    />
    <AdvancedImage
      className="scene-7__mp22"
      cldImg={cld.image("devices/mp22").resize(scale().width(400)).format("auto")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Mobile Printer MP22"
    />
    <AdvancedImage
      className="scene-7__wpp250"
      cldImg={cld.image("components/we-are-animation/wpp-250").resize(scale().width(400)).format("auto")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Mobile Printer WPP-250"
    />
  </div>

  
}

export default Scene7;