import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import cld from '../../../../constants/cloudinary';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { scale } from "@cloudinary/url-gen/actions/resize";

import "./index.scss";

/**
 * Scene 
 * Cloud As A Service
 */

export const cloudAASTimeline = gsap.timeline();

const CloudAAS = () => {

  useGSAP(() => cloudAASTimeline
    .fromTo([
      ".aws-logo",
      ".azure-logo",
      ".gcloud-logo",
    ], {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      duration: 1,
      stagger: 0.5
    })
    .to([
      ".aws-logo",
      ".azure-logo",
      ".gcloud-logo",
    ], {
      autoAlpha: 0,
      duration: 1,
      delay: 2.3
    }),
  );

  return <div className="cloud-aas__logos">
    <AdvancedImage
      className="aws-logo"
      cldImg={cld.image("components/we-are-animation/aws-logo").resize(scale().width(300)).format("webp")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="AWS"
    />
    <AdvancedImage
      className="azure-logo"
      cldImg={cld.image("components/we-are-animation/azure-logo").resize(scale().width(300)).format("webp")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Azure"
    />
    <AdvancedImage
      className="gcloud-logo"
      cldImg={cld.image("components/we-are-animation/gcloud-logo").resize(scale().width(300)).format("webp")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Google Cloud"
    />
  </div>
}

export default CloudAAS