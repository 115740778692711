import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import cld from '../../../../constants/cloudinary';
import { AdvancedImage, lazyload } from '@cloudinary/react';

import "./index.scss";

/**
 * Scene 12
 * Software Integration
 */

export const scene12Timeline = gsap.timeline();

const Scene12 = () => {

  useGSAP(() => scene12Timeline
    .fromTo(".scenes__gradient-bg", {
      autoAlpha: 0,
    }, {
      autoAlpha: 1,
      duration: 1,
      ease: "power1.out"
    })
    .fromTo(".scene-12__office-building", {
      autoAlpha: 0,
      transform: "translateY(30%)"
    }, {
      transform: "translateY(0)",
      autoAlpha: 1,
      duration: 1,
      ease: "power1.out"
    })
    .fromTo([
      ".scene-12__cloud",
      ".scene-12__security",
      ".scene-12__payment-acceptance",
      ".scene-12__legacy",
      ".scene-12__mobile",
      ".scene-12__portal"
    ], {
      autoAlpha: 0,
    }, {
      autoAlpha: 1,
      duration: 1,
      stagger: .5,
      ease: "power1.out"
    })
    .fromTo([
      ".scene-12__title.title-1",
      ".scene-12__title.title-2",
      ".scene-12__title.title-3",
      ".scene-12__title.title-4",
      ".scene-12__title.title-5",
      ".scene-12__title.title-6"
    ], {
      autoAlpha: 0,
    }, {
      autoAlpha: .5,
      duration: 1,
      stagger: .5,
      ease: "power1.out"
    }, "<")
    .fromTo(".scene-12__data-lines", {
      autoAlpha: 0,
    }, {
      autoAlpha: 1,
      duration: 1,
      ease: "power1.out"
    }, ">-.5")
    // Cleanup
    .to([
      ".scene-12__office-building",
      ".scene-12__cloud",
      ".scene-12__security",
      ".scene-12__payment-acceptance",
      ".scene-12__legacy",
      ".scene-12__mobile",
      ".scene-12__portal",
      ".scene-12__title.title-1",
      ".scene-12__title.title-2",
      ".scene-12__title.title-3",
      ".scene-12__title.title-4",
      ".scene-12__title.title-5",
      ".scene-12__title.title-6",
      ".scene-12__data-lines"
    ], {
      autoAlpha: 0,
      duration: 1,
      ease: "power1.out"
    })
  );

  return <div className="scenes scenes__scene-12">
    <AdvancedImage
      className="scene-12__data-lines"
      cldImg={cld.image("components/we-are-animation/data-lines").format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Lines drawn from office building image to other images"
    />
    <AdvancedImage
      className="scene-12__office-building"
      cldImg={cld.image("components/we-are-animation/office-building").format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="office building"
    />
    <AdvancedImage
      className="scene-12__cloud"
      cldImg={cld.image("components/we-are-animation/cloud-data-transfer").format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="cloud with upload and download arrows"
    />
    <AdvancedImage
      className="scene-12__security"
      cldImg={cld.image("components/we-are-animation/secure").format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="shield with a lock to depict security"
    />
    <AdvancedImage
      className="scene-12__payment-acceptance"
      cldImg={cld.image("components/we-are-animation/payment-acceptance").format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="table with a kiosk"
    />
    <AdvancedImage
      className="scene-12__legacy"
      cldImg={cld.image("components/we-are-animation/server-stack").format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a stack of server blades"
    />
    <AdvancedImage
      className="scene-12__mobile"
      cldImg={cld.image("components/we-are-animation/phone-blueprint").format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a mobile phone with sqaure depicting apps"
    />
    <AdvancedImage
      className="scene-12__portal"
      cldImg={cld.image("components/we-are-animation/portal").format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a web browser"
    />
    <div className="scene-12__title title-1">Cloud</div>
    <div className="scene-12__title title-2">Security</div>
    <div className="scene-12__title title-3">Payment<br/>Acceptance</div>
    <div className="scene-12__title title-4">Legacy</div>
    <div className="scene-12__title title-5">Mobile</div>
    <div className="scene-12__title title-6">Portal</div>

  </div>

}



export default Scene12;

// "software-integration-env"