import React from 'react'
import { connectHierarchicalMenu } from 'react-instantsearch-dom';

const sort = (order: string[], items: any[]) => {
  let orderedItems: {}[] = [];
  for (let i: number = 0; i < order.length; i++) {
    for (let j = 0; j < items.length; j++) {
      if (order[i] === items[j].label) {
        orderedItems.push(items[j]);
      }
    }
  }

  return orderedItems;
}

const HierarchicalMenu = ({ items, refine, createURL }: any) => {

  const iPhoneOrder = [
    "iPhone 14",
    "iPhone 13",
    "iPhone 12",
    "iPhone 11",
    "iPhone XR",
    "iPhone SE 2nd gen",
    "iPhone 8",
    "iPhone 7",
    "iPhone 6s",
    "iPhone 6",
  ]


  if (items && items[0] && items[0].label !== 'undefined') {

    if (/iPhone/gm.test(items[0].label) && items.length > 2) {
      items = sort(iPhoneOrder, items);
    }
  }

  return <ul className="ais-HierarchicalMenu">
    {items.map((item: any) => (
      <li key={item.label} className={`ais-HierarchicalMenu-item ${item.isRefined ? 'ais-HierarchicalMenu-item--selected' : ''}`} >
        <a
          href={createURL(item.value)}
          className="ais-HierarchicalMenu-link"
          onClick={(event: any) => {
            event.preventDefault();
            refine(item.value);
          }}
        >
          <span className="ais-HierarchicalMenu-label">
            {item.label}
          </span>
          <span className="ais-HierarchicalMenu-count">
            ({item.count})
          </span>
        </a>
        {item.items && (
          <HierarchicalMenu
            items={item.items}
            refine={refine}
            createURL={createURL}
          />
        )}
      </li>
    ))}
  </ul>;
};

const CustomHierarchicalMenu = connectHierarchicalMenu(HierarchicalMenu);

export default CustomHierarchicalMenu;