import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Menu from './Menu';
import { PageContext } from '../../context/PageState';
import ThemeToggleSwitch2023 from '../../components/ThemeToggleSwitch2023';

import "./index.scss";

const Header: React.FC<any> = (props) => {
	const { nav, isDesktop, theme } = useContext(PageContext);
	const [hamburgerMenuWedge, setHamburgerMenuWedge] = React.useState<boolean>(true);
	const [hamburgerMenu, setHamburgerMenu] = React.useState<boolean>(false);
	const [isThemeDark, setIsThemeDark] = useState<boolean>(theme.mode);

	let heading1: string;

	React.useEffect(() => {
		hamburgerMenu ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden');
		if (!nav.expanded) { 
			setHamburgerMenu(false);
		}

		setIsThemeDark(theme.mode);

	}, [hamburgerMenu, nav, props.themeToggleSwitch, theme])

	const toggleHamburgerMenu = () => {
		setHamburgerMenuWedge(false)
		setHamburgerMenu(!nav.expanded);
		nav.toggle(!nav.expanded)
	}

	if (props.dbLoaded) {	
		// heading1
		if (
			props.metadata.heading1 !== undefined && 
			props.metadata.heading1.length > 0
		) {
			heading1 = props.metadata.heading1;
		} else if ( 
			props.metadata.title !== undefined && 
			props.metadata.title.length > 0
		) {
			heading1 = props.metadata.title
		} else {
			heading1 = 'IPCMobile';
		}
	} else {
		heading1 = 'IPCMobile';
	}	

	return <header>
		<div className={`header-container ${hamburgerMenu ? 'active overflow-auto' : !hamburgerMenuWedge ? 'inactive' : ''}`}>
			<div className="header-wrap container-fluid">
				<div className="row">
					<div className="col">
						<div className="site-logo">
							<Link to="/">
								<img
									src={`https://res.cloudinary.com/ipcmobile/image/upload/v1648060934/logos/ipcmobile-logo${isThemeDark ? "-inverted" : ""}.svg`}
									alt="IPCMobile logo"
								/>
								
								<h1 className="d-none">
									{heading1}
								</h1>
							</Link>
						</div>
						<div
							className="menu-icon icon-animation menu d-lg-none"
							aria-expanded="false"
							aria-label="Toggle navigation"
							onClick={toggleHamburgerMenu}
						>
							<div></div>
							<div></div>
							<div></div>
						</div>
						{(isDesktop) && <ThemeToggleSwitch2023 />}
						<div className="search-icon">
							<Link
								to="/devices"
								className="search-field"
							>
								<img
									src="https://res.cloudinary.com/ipcmobile/image/upload/v1545164139/icons/magnifying-glass.svg"
									alt="search devices and printers icon"
								/>
							</Link>
						</div>
						{(!isDesktop) && <ThemeToggleSwitch2023 />}
					</div>
				</div>
			</div>
			<Menu />
		</div>
	</header>;
};

export default Header;
