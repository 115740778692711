import { useGSAP } from '@gsap/react';
import { mainTimeline } from '../..';

import "./index.scss";

/**
 * Navigation
 * Animated Slider Navigation
*/

export let closeNav: Function;

const Nav = () => {
  
  useGSAP(() => {
    
    const menu = document.querySelector(".we-are-animation__menu");
    const nav = document.querySelector(".we-are-animation__nav nav");
    const LI: HTMLLIElement[] = [].slice.call(document.querySelectorAll(".we-are-animation__nav ul li"));
    
    const seek = (label:string) => mainTimeline.play(mainTimeline.labels[label]);

    const openNav = () => {
      if (nav) nav.classList.add("active");
      mainTimeline.pause();
    }

    closeNav = () => {
      if (nav) nav.classList.remove("active");
    }

    if (menu) menu.addEventListener("click", () => openNav());

    if (LI) LI.forEach((item) => item.addEventListener("click", () => {
      seek(item.innerHTML);
      closeNav();
    }));


    return (() => {

      if (menu) menu.removeEventListener("click", () => openNav());

      LI.forEach((item) => item.removeEventListener("click", () => {
        seek(item.innerHTML);
        closeNav();
      }));
    })

  })

  return <div className="we-are-animation__nav">
    <div className="we-are-animation__menu">Menu</div>
    <nav>
      <ul>
        <div>
          <li>Intro</li>
          <li>Enterprise Mobility Hardware</li>
          <li>Enterprise Payments</li>
          <li>Beacons and Trackers</li>
          <li>Wearables</li>
          <li>Express Experiences</li>
          <li>Mobile Printing</li>
        </div>
        <div>
          <li>Kiosks</li>
          <li>Enterprise Software Experts</li>
          <li>Mobile App Development</li>
          <li>Cloud Development</li>
          <li>Software Integration</li>
          <li>Legacy Migration Specialists</li>
          <li>A Partner You Can Trust</li>
        </div>
      </ul>
    </nav>
  </div>
}

export default Nav;