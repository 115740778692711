import React from 'react'
import cld from '../../constants/cloudinary';
import { AdvancedImage } from '@cloudinary/react';

import "./index.scss";

export interface ITestimonialSliderTestimonial {
    backgroundImage: string;
    className?: string;
    entityName: string;
    entityLogoSrc: string;
    representative: {
        fullName: string;
        title: string;
        quote: string;
    }
}

interface ITestimonialSlider {
    id: string;
    testimonials: ITestimonialSliderTestimonial[];
}

const TestimonialSlider: React.FC<ITestimonialSlider> = ({ id, testimonials }) => {
    const TestimonialSlider = <div id={id} className="cp_testimonial-slider carousel slide">
        <div className="carousel-inner">
            {testimonials.map((testimonial, i) => <div
                key={i}
                className={[
                    "carousel-item",
                    testimonial.className? testimonial.className : undefined,
                    !i ? "active" : undefined
                ].join(" ").trim()}
            >
                <div className="background-image-wrapper">
                    <AdvancedImage
                        cldImg={cld.image(testimonial.backgroundImage).format('webp').quality('auto')}
                        className="img-fluid"
                        alt={testimonial.entityName + " background image"}
                    />
                </div>
                <div className="carousel-caption">
                    <AdvancedImage
                        cldImg={cld.image(testimonial.entityLogoSrc).format('webp').quality('auto')}
                        className="logo"
                        alt={testimonial.entityName + " logo"}
                    />
                    <p className="quote">{testimonial.representative.quote}</p>
                    <p className='h5 text-uppercase text-primary'>{testimonial.representative.fullName}</p>
                    <p className='h6'>{testimonial.representative.title}</p>
                    <p className='h6'>{testimonial.entityName}</p>
                </div>
            </div>)}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target={"#" + id} data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target={"#" + id} data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </div>

    return <div className="container">
        <div className="row">
            <div className="col">
                {TestimonialSlider}
            </div>
        </div>
    </div>

}

export default TestimonialSlider