import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import cld from '../../../../constants/cloudinary';
import { AdvancedImage, lazyload } from '@cloudinary/react';

import "./index.scss";

/**
 * Scene 13
 * Legacy Migration Specialists
 */

export const scene13Timeline = gsap.timeline();

const Scene13 = () => {

  useGSAP(() => scene13Timeline
    .fromTo([
      ".scene-13__servers.server-1",
      ".scene-13__servers.server-2",
      ".scene-13__servers.server-3",
      ".scene-13__phone",
      ".scene-13__cloud-database",
    ], {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      duration: 1,
      ease: "power1.out",
      stagger: .5
    })
    .to(".cloud-aas__logos", { left: "82%" })
    .fromTo([
      ".aws-logo",
      ".azure-logo",
      ".gcloud-logo",
    ], {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      duration: 1,
      stagger: 0.5
    })
    .fromTo([
      ".scene-13__bolts.bolt-red-13",
      ".scene-13__bolts.bolt-red-12",
      ".scene-13__bolts.bolt-red-11",
      ".scene-13__bolts.bolt-red-10",
      ".scene-13__bolts.bolt-red-9",
      ".scene-13__bolts.bolt-red-8",
      ".scene-13__bolts.bolt-red-7",
      ".scene-13__bolts.bolt-red-6",
      ".scene-13__bolts.bolt-red-5",
      ".scene-13__bolts.bolt-red-4",
      ".scene-13__bolts.bolt-red-3",
      ".scene-13__bolts.bolt-red-2",
      ".scene-13__bolts.bolt-red-1",
    ], {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      duration: 1,
      ease: "power1.out",
      stagger: .1
    }, "<-.5")
    .fromTo([
      ".scene-13__bolts.bolt-blue-6",
      ".scene-13__bolts.bolt-blue-5",
      ".scene-13__bolts.bolt-blue-4",
      ".scene-13__bolts.bolt-blue-3",
      ".scene-13__bolts.bolt-blue-2",
      ".scene-13__bolts.bolt-blue-1",
      ".scene-13__bolts.bolt-green-1",
      ".scene-13__bolts.bolt-green-2",
      ".scene-13__bolts.bolt-green-3",
      ".scene-13__bolts.bolt-green-4",
      ".scene-13__bolts.bolt-green-5",
      ".scene-13__bolts.bolt-green-6"
    ], {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      duration: 1,
      ease: "power1.out",
      stagger: .1,
      repeat: 1
    }, "<+2")
    // cleanup
    .to([
      ".scene-13__bolts.bolt-red-13",
      ".scene-13__bolts.bolt-red-12",
      ".scene-13__bolts.bolt-red-11",
      ".scene-13__bolts.bolt-red-10",
      ".scene-13__bolts.bolt-red-9",
      ".scene-13__bolts.bolt-red-8",
      ".scene-13__bolts.bolt-red-7",
      ".scene-13__bolts.bolt-red-6",
      ".scene-13__bolts.bolt-red-5",
      ".scene-13__bolts.bolt-red-4",
      ".scene-13__bolts.bolt-red-3",
      ".scene-13__bolts.bolt-red-2",
      ".scene-13__bolts.bolt-red-1",
      ".scene-13__bolts.bolt-blue-6",
      ".scene-13__bolts.bolt-blue-5",
      ".scene-13__bolts.bolt-blue-4",
      ".scene-13__bolts.bolt-blue-3",
      ".scene-13__bolts.bolt-blue-2",
      ".scene-13__bolts.bolt-blue-1",
      ".scene-13__bolts.bolt-green-1",
      ".scene-13__bolts.bolt-green-2",
      ".scene-13__bolts.bolt-green-3",
      ".scene-13__bolts.bolt-green-4",
      ".scene-13__bolts.bolt-green-5",
      ".scene-13__bolts.bolt-green-6",
      ".scene-13__servers.server-1",
      ".scene-13__servers.server-2",
      ".scene-13__servers.server-3",
      ".scene-13__phone",
      ".scene-13__cloud-database",
      ".cloud-aas__logos"
    ], {
      autoAlpha: 0,
      stagger: .1,
    })
  );

  return <div className="scenes scenes__scene-13">
    <AdvancedImage
      className="scene-13__servers server-1"
      cldImg={cld.image("components/we-are-animation/server-rack").format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Server Rack"
    />
    <AdvancedImage
      className="scene-13__servers server-2"
      cldImg={cld.image("components/we-are-animation/server-rack").format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Server Rack"
    />
    <AdvancedImage
      className="scene-13__servers server-3"
      cldImg={cld.image("components/we-are-animation/server-rack").format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Server Rack"
    />
    <AdvancedImage
      className="scene-13__phone"
      cldImg={cld.image("components/we-are-animation/mobile-phone-standing-side").format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Mobile phone side profile"
    />
    <AdvancedImage
      className="scene-13__cloud-database"
      cldImg={cld.image("components/we-are-animation/cloud-database").format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="Cloud with database icons on the front side"
    />

    <img loading="lazy" className="scene-13__bolts bolt-blue-1" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-blue" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-blue-2" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-blue" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-blue-3" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-blue" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-blue-4" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-blue" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-blue-5" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-blue" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-blue-6" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-blue" alt="bolt-red" />

    <img loading="lazy" className="scene-13__bolts bolt-green-1" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-green" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-green-2" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-green" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-green-3" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-green" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-green-4" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-green" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-green-5" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-green" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-green-6" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-green" alt="bolt-red" />

    <img loading="lazy" className="scene-13__bolts bolt-red-1" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-red" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-red-2" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-red" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-red-3" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-red" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-red-4" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-red" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-red-5" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-red" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-red-6" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-red" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-red-7" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-red" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-red-8" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-red" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-red-9" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-red" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-red-10" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-red" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-red-11" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-red" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-red-12" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-red" alt="bolt-red" />
    <img loading="lazy" className="scene-13__bolts bolt-red-13" src="https://res.cloudinary.com/ipcmobile/image/upload/v1/components/we-are-animation/bolts.svg#bolt-red" alt="bolt-red" />

  </div>


}

export default Scene13;