import React from 'react';
import { connectHits } from 'react-instantsearch-dom';
import Cards from '../../../components/Cards';


const Hits = ({ hits }: any) => <div className="row">
  {hits.map((hit: any, i: number) => <Cards key={i} {...hit} />)}
</div>;

const CustomHits = connectHits(Hits);

export default CustomHits;
